import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class PresentValue extends Expression {
    calculate() {
        const percentage = parseFloat(this.items[0].calculate()); // 7
        const periods = parseFloat(this.items[1].calculate()); //2.34
        const amountPerPeriod = parseFloat(this.items[2].calculate()); // 100000

        // 1/(1+percentage)
        const r = 1 / (1 + percentage / 100);

        // 1-r^(periods+1)
        const formula = 1 - r ** (periods + 1);

        // formula/(1-r)
        const divided = formula / (1 - r);

        // amountPerPeriod * divided
        const totalAmount = amountPerPeriod * divided;

        // presentValue = totalAmount - amountPerPeriod
        return totalAmount - amountPerPeriod;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default PresentValue;

import produce from "immer";
import { LOAD_PAGE_SECTIONS, REQUEST, SUCCESS, FAILURE } from "../constants";

const initialState = {
    data: {},
    loading: false,
    loaded: false,
    error: null,
};

export default produce((draft = initialState, action) => {
    const { type, data, error } = action;

    switch (type) {
        case LOAD_PAGE_SECTIONS + REQUEST: {
            draft.error = null;
            draft.loading = true;
            draft.loaded = false;
            break;
        }
        case LOAD_PAGE_SECTIONS + SUCCESS: {
            draft.loading = false;
            draft.loaded = true;
            draft.data = { ...draft.data, ...data };
            break;
        }
        case LOAD_PAGE_SECTIONS + FAILURE: {
            draft.loading = false;
            draft.loaded = false;
            draft.error = error;
            break;
        }
        default:
            return draft;
    }
});

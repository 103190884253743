import { fromJS } from "immutable";
import {
    DELETE_BATCH_ACTION_PROPERTY,
    FETCH_BATCH_ACTION_RESOURCES_RESULT,
    SET_BATCH_ACTION_SELECTION,
    UPDATE_BATCH_ACTION_PROPERTY,
    RESET_BATCH_ACTION,
} from "../actions/action-batch-action";

const emptyState = fromJS({
    selection: null,
    batchActionResources: null,
    properties: {},
});

export default function (state = emptyState, action) {
    let newProperty = {};
    switch (action.type) {
        case SET_BATCH_ACTION_SELECTION().type:
            return state.set("selection", fromJS(action.payload.selection));

        case FETCH_BATCH_ACTION_RESOURCES_RESULT().type:
            return state.set("batchActionResources", fromJS(action.payload.data));

        case UPDATE_BATCH_ACTION_PROPERTY().type:
            newProperty = {
                id: action.payload.propertyId,
                value: action.payload.newValue,
            };

            return state.setIn(["properties", action.payload.propertyId], newProperty);

        case DELETE_BATCH_ACTION_PROPERTY().type:
            return state.set(
                "properties",
                state.get("properties").filter((property) => property.id !== action.payload.propertyId)
            );
        case RESET_BATCH_ACTION().type:
            return emptyState;

        default:
            return state;
    }
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys, ucFirst } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationPortfoliosApi = createAsyncThunk(
    "valuationEdit/valuationPortfoliosApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        if (valuationEdit.portfolios.loading === false || requestId !== valuationEdit.portfolios.currentRequestId)
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const url = defaultConfig.api.valuation.getPortfolios;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });

            const result = responseData.map(({ id, name, number, active }) => {
                const value = id;
                const label = number ? `${ucFirst(name)} (${number})` : ucFirst(name);

                const valueObject = {
                    value,
                    label,
                    active,
                };

                return valueObject;
            });

            return result;
        }

        return rejectWithValue({ error });
    }
);

export default valuationPortfoliosApi;

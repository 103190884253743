import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";
import { ucFirst } from "@kateinnovations/javascript-helpers/convertString";

const valuationApi = createAsyncThunk(
    "valuationEdit/valuationApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        if (valuationEdit.loading === false || requestId !== valuationEdit.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.getData.replace("{{valuationId}}", valuationId || data.valuationId);

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });
            const { id: value, active, lead, dateCreated, number, name } = responseData?.portfolio || {};

            const portfolio = {
                value,
                active,
                lead,
                dateCreated,
                number,
                label: number ? `${ucFirst(name)} (${number})` : ucFirst(name),
                updating: false,
            };

            const client = {
                ...responseData.client,
                updating: false,
            };

            const mainImage = {
                available: responseData.mainPhotoAvailable,
                lastUpdated: new Date().getTime(),
            };

            delete responseData.mainPhotoAvailable;

            const result = {
                ...responseData,
                portfolio,
                client,
                mainImage,
            };

            return result;
        }

        return rejectWithValue({ error });
    }
);

export default valuationApi;

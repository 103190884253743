import { createSlice } from "@reduxjs/toolkit";
import configSettingsApi from "./configSettingsApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
};

const configSettings = createSlice({
    name: "configSettings",
    initialState,
    reducers: {
        resetConfigSettings() {
            return initialState;
        },
    },
    extraReducers: {
        [configSettingsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [configSettingsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [configSettingsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
    },
});

const { resetConfigSettings } = configSettings.actions;
const configSettingsReducer = configSettings.reducer;

export { configSettingsReducer, resetConfigSettings, configSettingsApi };

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const v2TokenApi = createAsyncThunk(
    "valuationEdit/v2TokenApi",
    async (data, { dispatch, getState, rejectWithValue }) => {
        const { auth, userProfile } = getState();
        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const legacyAdapterUrl = defaultConfig.api.xbrl.getToken;
        const getResponse = await apiCall({
            url: legacyAdapterUrl,
            method: "POST",
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            body: {},
        });

        const responseData = await getResponse.json();
        if (getResponse.ok) {
            const { result } = responseData;
            const date = new Date();
            const tokenExpiresIn = result.expires_in; // in seconds
            const tokenExpiresAt = tokenExpiresIn * 0.5 + date.getTime() / 1000;
            return {
                token: result.access_token,
                customerPartition: result.customerPartition,
                expiresAt: tokenExpiresAt,
            };
        }
        return rejectWithValue({ error });
    }
);

export default v2TokenApi;

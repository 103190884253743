import React from "react";
import PropTypes from "prop-types";

import styles from "./resources/styles/progress.module.scss";

const Progress = ({ value, maxValue, content }) => {
    return (
        <div className={styles.container} variant={content.show ? `content-${content.position}` : undefined}>
            <progress className={styles.progress} value={value} max={maxValue}></progress>
            {content.show && (
                <div className={styles.contentContainer} variant={content.position}>
                    {!!content.before && (
                        <span className={styles.contentUnit} id={"valuationStatus"} variant="before">
                            {content.before}
                        </span>
                    )}
                    <output className={styles.contentUnit}>
                        {value}
                        {content.outputUnit}
                    </output>
                    {!!content.after && <span className={styles.contentUnit}>{content.after}</span>}
                </div>
            )}
        </div>
    );
};

Progress.defaultProps = {
    value: 0,
    maxValue: 100,
    content: {
        before: undefined,
        after: undefined,
        outputUnit: "%",
        position: "bottom",
        show: true,
    },
};

Progress.propTypes = {
    value: PropTypes.number,
    maxValue: PropTypes.number,
    content: PropTypes.shape({
        before: PropTypes.string,
        after: PropTypes.string,
        outputUnit: PropTypes.oneOf(["%"]),
        position: PropTypes.oneOf(["top", "left", "right", "bottom"]),
        show: PropTypes.bool,
    }),
};

export default Progress;

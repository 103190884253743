import { fromJS } from "immutable";
import moment from "moment";
import localForage from "localforage";
import { toastr } from "react-redux-toastr";
import { getIntFromEnvironment } from "valueFromEnvironment";

class LocalStorageCache {
    constructor(namespace, key) {
        this.storageKey = key;
        this.namespaceKey = namespace;
        this.defaultExpiration = getIntFromEnvironment("CACHE_EXPIRATION", 720);

        this.store = localForage.createInstance({
            driver: localForage.INDEXEDDB,
            name: this.namespaceKey,
            version: 10,
        });
    }

    create(value, expires) {
        return this.store
            .setItem(
                this.storageKey,
                JSON.stringify({
                    date: new Date(),
                    expires: expires === undefined ? this.defaultExpiration : expires,
                    data: value,
                })
            )
            .then((value) => {
                return true;
            })
            .catch((err) => {
                toastr.error(this.storageKey + ":: Could not be saved.");
                return false;
            });
    }

    readOffline(minutes = 0) {
        return this.has(minutes)
            .then((value) => {
                return this.store.getItem(this.storageKey).then((value) => {
                    return fromJS(JSON.parse(value));
                });
            })
            .catch((error) => {
                return Promise.reject("Not offline");
            });
    }

    read({ immutable = true, getMeta = true } = {}) {
        return this.has().then((value) => {
            return this.store.getItem(this.storageKey).then((value) => {
                const jsonResult = JSON.parse(value);
                const result = getMeta ? jsonResult : jsonResult.data;
                return immutable ? fromJS(result) : result;
            });
        });
    }

    remove() {
        return this.store.removeItem(this.storageKey);
    }

    static deleteDatabase(database, callBack) {
        let store = localForage.createInstance({
            driver: localForage.INDEXEDDB,
            name: database,
            version: 10,
        });

        store.clear().then(() => callBack);
    }

    static deleteDatabaseForLogout(database) {
        let store = localForage.createInstance({
            driver: localForage.INDEXEDDB,
            name: database,
            version: 10,
        });
        store.clear().then();
    }

    getExpireByHours() {
        return this.store
            .getItem(this.storageKey)
            .then(function (value) {
                if (value !== null) {
                    let jsonData = fromJS(JSON.parse(value));
                    let expires = jsonData.get("expires");

                    let date = moment(new Date(jsonData.get("date")));
                    let today = moment(new Date());
                    return Math.round(expires - moment.duration(today.diff(date)).asHours());
                } else {
                    return 0;
                }
            })
            .catch(function (err) {
                toastr.error(err);
                return 0;
            });
    }

    has(minutes) {
        return this.store
            .getItem(this.storageKey)
            .then((value) => {
                if (value !== null) {
                    let jsonData = fromJS(JSON.parse(value));
                    let expires = minutes === undefined ? jsonData.get("expires") : minutes;

                    let date = moment(new Date(jsonData.get("date")));
                    let today = moment(new Date());

                    let difference = moment.duration(today.diff(date));

                    return difference.asMinutes() < expires ? Promise.resolve(true) : Promise.reject(false);
                } else {
                    return Promise.reject(false);
                }
            })
            .catch((err) => {
                return Promise.reject(false);
            });
    }
}

export default LocalStorageCache;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationApprovalsApi = createAsyncThunk(
    "valuationEdit/valuationApprovalsApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile, valuation } = getState();

        if (
            valuationEdit.status.approvals.loading === false ||
            requestId !== valuationEdit.status.approvals.currentRequestId
        )
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const oldValuationId = valuation.get("currentValuationId");
        const url = defaultConfig.api.valuation.approvals.get.replace("{{valuationId}}", valuationId || oldValuationId);

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });

            return responseData;
        }

        return rejectWithValue({ error });
    }
);

export default valuationApprovalsApi;

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Divide extends Expression {
    calculate() {
        let result = null;
        if (this.items.length > 1) {
            result = this.items[0].calculate();
        }

        for (let i = 1; i < this.items.length; i++) {
            let postCalculation = this.items[i].calculate();
            if (postCalculation === 0) {
                postCalculation = 1;
            }

            if (Array.isArray(result) && Array.isArray(postCalculation)) {
                result = result.map((value, index) => value / postCalculation[index]);
            } else if (Array.isArray(result) && !Array.isArray(postCalculation)) {
                result = result.map((value) => value / postCalculation);
            } else if (!Array.isArray(result) && Array.isArray(postCalculation)) {
                // eslint-disable-next-line no-loop-func
                result = postCalculation.map((value) => result / value);
            } else {
                result = result / postCalculation;
            }
        }

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default Divide;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    entities: undefined,
    isLoaded: false,
};

const translations = createSlice({
    name: "translations",
    initialState,
    reducers: {
        setTranslations(state, action) {
            const newState = state;

            newState.entities = action.payload.translations;
            newState.isLoaded = true;
        },
        resetTranslations() {
            return initialState;
        },
    },
});

const { setTranslations, resetTranslations } = translations.actions;
const translationsReducer = translations.reducer;

export { translationsReducer, setTranslations, resetTranslations };

import sendErrorMail from "helpers/sendErrorMail";
import { toastr } from "react-redux-toastr";

const applicationErrorMessage = ({ jwt, getResponse, url, mailBody, toastrMessage, timeOutInMs }) => {
    // constants
    const DEFAULT_MAILBODY_HAS_MISSING_VARIABLES = !mailBody && (!getResponse || !url);
    const toastrMessageDefault =
        "Something went wrong. Our development team has been informed.";
    const mailBodyDefault = `<div>
        <h1 style="color: #fd5800; font-weight: 900">Some error occurred on Compview please read details :( (v1.4)</h1>
        <p style="background-color: #eeeeee; padding: 10px">
            <strong>Error message:</strong><br />
            ${JSON.stringify(getResponse, undefined, 4)}
        </p>
        <p>
                <strong>Where it occured:</strong><br />This is the error message from making a request to ${url}
        </p>
        </div>`;

    // argument validation
    if (!jwt || DEFAULT_MAILBODY_HAS_MISSING_VARIABLES) {
        console.error("Missing required arguments for applicationErrorMessage");
        return false;
    }

    // show notification with error message
    toastr.error(toastrMessage ?? toastrMessageDefault, { timeOut: timeOutInMs ?? 10e3 });

    // notify developers
    sendErrorMail(jwt, mailBody ?? mailBodyDefault);
};

export default applicationErrorMessage;

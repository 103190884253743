export const CHANGE_VALUE = "CHANGE_VALUE";
export const CALCULATE = "CALCULATE";
export const ADD_ROW = "ADD_ROW";
export const REMOVE_ROW = "REMOVE_ROW";
export const ADD_TABLE = "ADD_TABLE";
export const REMOVE_TABLE = "REMOVE_TABLE";

export const LOAD_PAGE_DATA = "LOAD_PAGE_DATA";
export const LOAD_PAGE_SECTIONS = "LOAD_PAGE_SECTIONS";

export const REQUEST = "_REQUEST";
export const SUCCESS = "_SUCCESS";
export const FAILURE = "_FAILURE";

export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const SET_STATUS_TRANSITION_CONFIRMATION = "SET_STATUS_TRANSITION_CONFIRMATION";

export const RESET_OVERVIEW_DATA = "RESET_OVERVIEW_DATA";
export const LOAD_OVERVIEW_DATA = "LOAD_OVERVIEW_DATA";
export const LOAD_CALCULATION_DATA = "LOAD_CALCULATION_DATA";
export const RESET_CALCULATION_DATA = "RESET_CALCULATION_DATA";

export const SET_VALUE_PATH = "SET_VALUE_PATH";
export const SET_VALUE_TITLE = "SET_VALUE_TITLE";

export const SET_REDIRECT_DATA = "SET_REDIRECT_DATA";

export const CREATE_CELLS = "CREATE_CELLS";
export const SET_AUTO_FOCUSED_CELL = "SET_AUTO_FOCUSED_CELL";
export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";

export const SET_MANAGEMENT_DATA = "SET_MANAGEMENT_DATA";
export const SET_CALCULATION_STATUS = "SET_CALCULATION_STATUS";
export const SET_IMPORT_DATA = "SET_IMPORT_DATA";
export const UPDATE_CALCULATION_NAME = "UPDATE_MODEL_NAME";
import { fromJS } from "immutable";

import * as ReduxActions from "../actions/action-import";

const emptyState = fromJS({
    files: null,
    headings: null,
    mappingId: null,
    mappingOptions: null,
    mappings: null,
    mandatoryMapping: null,
    importData: {},
    currentImport: null,
    connectionQueue: null,
    connectionQueueProcess: null,
    paginationData: null,
    changedEntries: null,
    portfolios: null,
    clients: null,
    selectedPortfolio: null,
    selectedFilter: "all",
    filteredKeys: null,
    type: null,
    transactionsType: null,
});

export default (state = emptyState, action) => {
    let mappings = null;
    let newQueue = null;
    let newQueueProcess = null;
    let newState;
    let newPaginationData;
    let requestKeys;
    let filteredKeys;
    let newRequestKeys;
    let filterEnum;

    switch (action.type) {
        case ReduxActions.IMPORTER_START_RESULT().type:
            return state.set("files", action.payload.files);
        case ReduxActions.IMPORTER_SET_TYPE().type:
            return state.set("type", action.payload.type).set("transactionsType", action.payload.transactionsType);
        case ReduxActions.IMPORTER_SET_HEADINGS().type:
            return state.set("headings", fromJS(action.payload.headings));
        case ReduxActions.IMPORTER_SET_MAPPING_RESULT().type:
            return state.set("mappingId", action.payload.mappingId);
        case "IMPORTER_SET_MAPPING_TRANSACTION_MODE":
            return state.set("transactionsType", action.payload.transactionMappingType);
        case ReduxActions.IMPORTER_GET_MAPPING_OPTIONS_RESULT().type:
            return state.set("mappingOptions", action.payload.mappingOptions);
        case ReduxActions.IMPORTER_GET_MAPPINGS_RESULT().type:
            return state.set("mappings", action.payload.mappings);
        case ReduxActions.IMPORTER_GET_MANDATORY_MAPPING_RESULT().type:
            return state.set("mandatoryMappings", action.payload.mandatoryMappings);
        case ReduxActions.IMPORTER_UPDATE_MAPPING_RESULT().type:
            mappings = [...state.get("mappings")];
            mappings.map((map) => {
                if (map.internalValue === action.payload.mapping[0].internalValue) {
                    return action.payload.mapping[0];
                }
                return map;
            });
            return state.set("mapping", action.payload.mapping[0]).set("mappings", mappings);
        case ReduxActions.IMPORTER_CREATE_MAPPING_RESULT().type:
            mappings = [...state.get("mappings")];
            return state
                .set("mapping", action.payload.mapping.get(0))
                .set("mappings", mappings.concat(action.payload.mapping.get(0)));
        case ReduxActions.IMPORTER_CLEAR_MAPPINGS().type:
            return state.delete("mappings").delete("mapping");
        case ReduxActions.IMPORTER_CLEAR_MAPPING().type:
            return state.delete("mapping");

        // _OLD_TODO: only use for init for now, use only the current import in the future
        case ReduxActions.IMPORTER_INITIALIZE().type:
            return state.set("importData", fromJS(action.payload.importData));
        case ReduxActions.IMPORTER_CHANGE().type:
            const payloadProps = action.payload.newProperties;
            return state.setIn(
                ["currentImport", "request", action.payload.importEntryId, "properties"],
                payloadProps
            );
        case ReduxActions.IMPORTER_UPDATE().type:
            const currentObjectProperties = state.get("currentImport").toJS().request?.[action.payload.importEntryId]?.properties;
            const payloadProperties = action.payload.newProperties;
            payloadProperties.toJS().map((payloadProperty) => {
                let found = false;
                for(const currentObjectPropertiesKey in currentObjectProperties){
                    if(payloadProperty.internalValue === currentObjectProperties[currentObjectPropertiesKey].internalValue){
                        currentObjectProperties[currentObjectPropertiesKey].value = payloadProperty.value;
                        currentObjectProperties[currentObjectPropertiesKey].valid = true;
                        found = true;
                    }
                }
                if(!found){
                    currentObjectProperties.push({
                        externalValue: payloadProperty.externalValue,
                        index: `${currentObjectProperties.length +1}`,
                        internalValue: payloadProperty.internalValue,
                        messages: [],
                        valid: true,
                        value: payloadProperty.value,
                    })
                }
                return payloadProperty;
            })
            return state.setIn(
                ["currentImport", "request", action.payload.importEntryId, "properties"],
                fromJS(currentObjectProperties)
            );

        // _OLD_TODO: this needs to be completer, so we can clear the entire import on unmount
        case ReduxActions.IMPORTER_CLEAR().type:
            return emptyState;
        case ReduxActions.IMPORTER_FIND_VALUATION_CONFIG_RESULT().type:
            return state.set("valuationFindConfig", action.payload.config);
        case ReduxActions.IMPORTER_CHECK_ADDRESS_CONFIG_RESULT().type:
            return state.set("addressCheckConfig", action.payload.config);

        // queue actions
        case ReduxActions.IMPORTER_ADD_TO_QUEUE().type:
            // fixme: this sometimes get called with 0 entries
            if (!action.payload.entries || action.payload.entries.size < 1) {
                return state;
            }
            if (!state.get("connectionQueue") || state.get("connectionQueue").size === 0) {
                return state.set("connectionQueue", fromJS(action.payload.entries));
            }
            newState = [...state.get("connectionQueue")];
            newState.concat(action.payload.entries);
            return state.set("connectionQueue", fromJS(newState));

        case ReduxActions.IMPORTER_REMOVE_FROM_QUEUE().type:
            newQueue =
                state &&
                [...state.get("connectionQueue")].filter((entryId) => entryId !== action.payload.importEntryId);
            return state.set("connectionQueue", fromJS(newQueue));

        case ReduxActions.IMPORTER_ADD_TO_QUEUE_PROCESS().type:
            newQueue =
                state &&
                [...state.get("connectionQueue")].filter((entryId) => entryId !== action.payload.importEntryId);
            if (!state.get("connectionQueueProcess")) {
                return state
                    .set("connectionQueueProcess", fromJS([action.payload.importEntryId]))
                    .set("connectionQueue", fromJS(newQueue));
            }
            newQueueProcess = [...state.get("connectionQueueProcess")].concat(action.payload.importEntryId);
            return state
                .set("connectionQueueProcess", fromJS(newQueueProcess))
                .set("connectionQueue", fromJS(newQueue));

        case ReduxActions.IMPORTER_REMOVE_FROM_QUEUE_PROCESS().type:
            newQueueProcess =
                state &&
                state.get("connectionQueueProcess") &&
                [...state.get("connectionQueueProcess")].filter((entryId) => entryId !== action.payload.importEntryId);
            return newQueueProcess ? state.set("connectionQueueProcess", fromJS(newQueueProcess)) : state;
        //    end of queue actions

        case ReduxActions.IMPORTER_UPDATE_PAGINATION().type:
            if (state.get("paginationData")) {
                newPaginationData = state.get("paginationData").merge(fromJS(action.payload.paginationData));
                return state.set("paginationData", newPaginationData);
            }
            return state.set("paginationData", fromJS(action.payload.paginationData));

        case ReduxActions.IMPORTER_CONNECTION_SET_VALUATION().type:
            return state.setIn(
                ["currentImport", "request", action.payload.importEntryId, "validation", "resultState"],
                "success"
            );

        case ReduxActions.IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION().type:
            if (state.get("currentImport")) {
                return state.setIn(
                    ["currentImport", "request", action.payload.id, "validation"],
                    fromJS(action.payload.importData)
                );
            }
            return state;

        case ReduxActions.IMPORTER_SET_ID_PROPERTIES().type:
            return state
                .setIn(
                    ["currentImport", "request", action.payload.id, "ID_PROPERTIES"],
                    fromJS(action.payload.idProperties)
                )
                .setIn(["currentImport", "request", action.payload.id, "validation", "resultState"], "success");
        case ReduxActions.IMPORTER_GET_SAVED_IMPORT_RESULT().type:
            requestKeys = action.payload.savedImport.request && Object.keys(action.payload.savedImport.request);

            requestKeys.sort((a, b) => {
                return Number(a.substr(2)) - Number(b.substr(2));
            });

            return state
                .set("currentImport", fromJS(action.payload.savedImport))
                .set("requestKeys", fromJS(requestKeys))
                .set("mappingId", action.payload.savedImport.extraOptions.mappingId);

        case ReduxActions.IMPORTER_GET_SAVED_IMPORTS_RESULT().type:
            return state.set("savedImports", action.payload.savedImports);
        case ReduxActions.IMPORTER_GET_PORTFOLIOS_RESULT().type:
            return state.set("portfolios", fromJS(action.payload.portfolios));

        case ReduxActions.IMPORTER_GET_CLIENTS_RESULT().type:
            return state.set("clients", fromJS(action.payload.clients));
        case ReduxActions.IMPORTER_SELECT_FILTER().type:
            newRequestKeys = [...state.get("requestKeys")];
            filterEnum = {
                warnings: "failed",
                alerts: "partial-success",
            };

            filteredKeys =
                action.payload.filter !== "all"
                    ? newRequestKeys.filter((requestKey) => {
                          // _OLD_TODO: see resultstate from key

                          const request = action.payload.request.get(requestKey);
                          const validation = request && request.get("validation");
                          const resultState = validation && validation.get("resultState");
                          return resultState && resultState === filterEnum[action.payload.filter];
                      })
                    : newRequestKeys;

            return state.set("selectedFilter", action.payload.filter).set("filteredKeys", fromJS(filteredKeys));
        case ReduxActions.IMPORTER_SET_PORTFOLIO().type:
            return state.set("selectedPortfolio", fromJS(action.payload.portfolio));
        case ReduxActions.IMPORTER_CANCEL_IMPORT_RESULT().type:
            return state.setIn(["currentImport", "status"], action.payload.result);
        case ReduxActions.IMPORTER_UNDECLINE_IMPORT_RESULT().type:
            return state.setIn(["currentImport", "status"], action.payload.result);
        case ReduxActions.IMPORTER_VALIDATE_RESULT().type:
            return state.setIn(
                ["currentImport", "request", action.payload.importEntryId, "properties"],
                fromJS(action.payload.result)
            );
        case ReduxActions.IMPORTER_UPDATE_CHANGES().type:
            if (action.payload.importEntryId) {
                if (state.get("changedEntries")) {
                    if (state.get("changedEntries").find((id) => id === action.payload.importEntryId)) {
                        return state;
                    }
                    const [...changedEntries] = state.get("changedEntries");
                    return state.set("changedEntries", fromJS(changedEntries.concat(action.payload.importEntryId)));
                }
                return state.set("changedEntries", fromJS([action.payload.importEntryId]));
            }
            return state.set("changedEntries", null);
        default:
            return state;
    }
};

import { apiCall, convertObjectKeys, getType, validations, getStorageItem } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";
import { updatePropertyDefinition } from "features/valuation/valuationSlice";
import store from "Store";

export const fetchAndUpdateValuationProperties = async ({ propertiesToUpdate, valuationId }) => {
    const jwt = getStorageItem("kateToken")?.jwt;

    if (getType(propertiesToUpdate) === "array" && validations.isNotEmpty(propertiesToUpdate, true)) {
        for (const propertyIdToUpdate of propertiesToUpdate) {
            const propertyUrl = defaultConfig.api.valuation.getProperty
                .replace("{{valuationId}}", valuationId)
                .replace("{{propertyDefinitionId}}", propertyIdToUpdate);

            const getPropertiesToUpdateResponse = await apiCall({
                url: propertyUrl,
                method: "GET",
                headers: {
                    authorization: `Bearer ${jwt}`,
                },
                credentials: "omit",
                mode: "cors",
            });

            const propertiesToUpdateContentType = getPropertiesToUpdateResponse?.headers
                ?.get("content-type")
                ?.split(";")
                ?.shift();

            const propertiesToUpdateIsJsonResponse = propertiesToUpdateContentType === "application/json";

            if (propertiesToUpdateIsJsonResponse) {
                const propertiesToUpdateResponse = await getPropertiesToUpdateResponse.json();
                const propertiesToUpdateResponseData = await convertObjectKeys({
                    dataObject: propertiesToUpdateResponse.data,
                });

                store.dispatch(
                    updatePropertyDefinition({ ...propertiesToUpdateResponseData, updateValue: true })
                );
            }
        }
    }
}

import getEnvValue from "helpers/getEnvValue";
import LocalStorageCache from "react/scripts/localstoragecache";
import {
    apiCall,
    setStorageItem,
    getStorageItem,
    clearStorage,
    removeStorageItem,
    getType,
    camelCase,
} from "@kateinnovations/javascript-helpers";
import store from "Store";
import { setAuthJwt, resetAuth } from "features/authentication/authenticationSlice";
import defaultConfig from "config/constants/defaultConfig";
import logoutExternalSource from "./logoutExternalSource";

const login = async ({ username, password, oneTimePassword, twoFactor }) => {
    const loginType = twoFactor ? "twoFactor" : "login";
    const url = defaultConfig.api.auth[loginType];
    const body = {
        username,
        password,
        // encryptedPassword,
        oneTimePassword,
        coreDomain: getEnvValue("KATE_CORE_URL"),
    };

    const response = await apiCall({
        url,
        body,
        headers: {
            accept: "application/json",
            "content-type": "application/json",
        },
        cache: "no-cache",
        mode: "cors",
        credentials: "omit",
    });

    if (getType(response) === "error") {
        const message = "systemError";
        return {
            status: undefined,
            statusText: response,
            ok: false,
            json: () => {
                return { message };
            },
            error: () => {
                return { message };
            },
        };
    }

    const contentType = response?.headers?.get("content-type")?.split(";")?.shift();
    const isJsonResponse = contentType === "application/json";
    const responseData = isJsonResponse ? await response.json() : { error: { message: "systemError" } };

    if (!response.ok && !responseData.message && !responseData.error?.message) {
        responseData.error = {
            message: "systemError",
        };
    }

    const errorResponse = !response.ok && (responseData.error?.message || responseData?.message)?.split(".").pop();

    const responseReturn = {
        status: response.status,
        statusText: response.statusText,
        ok: response.ok,
        json: () => {
            return Promise.resolve(responseData);
        },
        error: () => {
            return Promise.resolve({
                message: camelCase(errorResponse),
            });
        },
    };

    if (response.ok && responseData.twoFactor && !responseData.jwt) return responseReturn;

    if (response.ok) {
        const { jwt, publicJwt } = responseData || {};
        if (!jwt || !publicJwt) {
            responseReturn.ok = false;
            responseReturn.error = () => {
                return Promise.resolve({
                    message: "systemError",
                });
            };

            return responseReturn;
        }

        removeStorageItem("kateIsLoggedOut");
        setStorageItem("kateToken", { jwt, publicJwt });
        store.dispatch(setAuthJwt({ jwt, publicJwt, login: true }));
    }

    return responseReturn;
};

const logout = async () => {
    const jwtToken = getStorageItem("kateToken")?.jwt;
    const isLoggedOut = getStorageItem("kateIsLoggedOut");
    const brainbayMapsCoordinates = getStorageItem("brainbayMapsCoordinates");

    if (isLoggedOut)
        return {
            ok: true,
        };

    const customBackground = localStorage.getItem("custom-background");
    clearStorage();
    setStorageItem("custom-background", customBackground);
    setStorageItem("kateIsLoggedOut", true);
    setStorageItem("kateReloaded", false);
    setStorageItem("brainbayMapsCoordinates", brainbayMapsCoordinates);

    const headers = {
        accept: "application/json",
        "content-type": "application/json",
    };

    if (jwtToken) headers.authorization = `Bearer ${jwtToken}`;

    const url = defaultConfig.api.auth.logout;

    // log out of agrimeter
    await apiCall({
        url: `${url}agrimeter`,
        method: "GET",
        headers,
        cache: "no-cache",
        mode: "cors",
        credentials: "omit",
    });

    // improvement, only call if brainbay is enabled
    await logoutExternalSource(getEnvValue("BRAINBAY_BRIDGE_API_LOGOUT"), "brainbay");

    LocalStorageCache.deleteDatabase("app");
    LocalStorageCache.deleteDatabase("data");

    store.dispatch(resetAuth());
    const response = await apiCall({
        url,
        method: "GET",
        headers,
        cache: "no-cache",
        mode: "cors",
        credentials: "omit",
    });
    const responseData = await response.json();

    return {
        ok: true,
        responseData,
    };
};

export { login, logout };

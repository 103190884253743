import { fromJS } from "immutable";
import getEnvValue from "helpers/getEnvValue";
import defaultConfig from "config/constants/defaultConfig";

const configFileName = getEnvValue("CONFIG_FILE");
const configFile = `./constants/${configFileName}.js`;

const getConfig = () =>
    import(`${configFile}`)
        .then((response) => {
            const responseJSON = response.default;
            const data = {
                ...defaultConfig,
            };

            data.settings.currency = responseJSON.settings.currency;

            return {
                ok: true,
                data,
            };
        })
        .catch((error) => {
            return {
                ok: false,
                error: () => {
                    return {
                        message: error,
                    };
                },
            };
        });

const configInit = async () => {
    const configResponse = await getConfig();

    if (configResponse.ok) {
        const { data } = configResponse;
        window.config = {
            ...data,
            getState: () => {
                return data;
            },
            getImmutableState: () => {
                return fromJS(data);
            },
        };
    }

    return configResponse;
};

export default configInit;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import addressFormat from "helpers/addressFormat";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const compViewValuationApi = createAsyncThunk(
    "compView/compViewValuationApi",
    async (value, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, compView, userProfile } = getState();

        if (compView.valuations.loading === false || requestId !== compView.valuations.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];
        const language = userProfile?.entities?.languageCode;

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const { jwt } = auth;
        const url = defaultConfig.api.valuation.compView;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = response.data
                .map((item) => {
                    const address = addressFormat({ address: item.address, cityIsFirstItem: true, language });
                    const addressForSort = addressFormat({
                        address: item.address,
                        cityIsFirstItem: true,
                        language,
                        forSort: true,
                    });

                    return {
                        value: item.id,
                        label: address,
                        addressForSort,
                    };
                })
                .sort((previous, current) => (previous.addressForSort < current.addressForSort ? -1 : 1));

            return responseData;
        }

        return rejectWithValue({ error });
    }
);

export default compViewValuationApi;

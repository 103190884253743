import { createSlice } from "@reduxjs/toolkit";
import { validations } from "@kateinnovations/javascript-helpers";
// eslint-disable-next-line import/no-cycle
import compViewApi from "./compViewApi";
import compViewValuationApi from "./compViewValuationApi";
import compViewFormFieldsApi from "./compViewFormFieldsApi";
import compViewDynamicCategoriesApi from "./compViewDynamicCategoriesApi";
import compViewDynamicFormFieldsApi from "./compViewDynamicFormFieldsApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
    currentCategory: undefined,
    categories: {
        valuation: {
            id: 11,
            categoryName: "valuation",
            title: "valuation",
            type: "valuation",
            subType: undefined,
            active: true,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: false,
        },
        brainbayAgriculture: {
            id: 12,
            categoryName: "brainbayAgriculture",
            title: "brainbayAgriculture",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: true,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
        },
        brainbayAgricultureV2: {
            id: 13,
            categoryName: "brainbayAgricultureV2",
            title: "brainbayAgricultureV2",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: false,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
            isLoggedIn: false,
            isV2Brainbay: true,
        },
        brainbayBusiness: {
            id: 14,
            categoryName: "brainbayBusiness",
            title: "brainbayBusiness",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: true,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
        },
        brainbayBusinessV2: {
            id: 15,
            categoryName: "brainbayBusinessV2",
            title: "brainbayBusinessV2",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: false,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
            isLoggedIn: false,
            isV2Brainbay: true,
        },
        brainbayResidential: {
            id: 16,
            categoryName: "brainbayResidential",
            title: "brainbayResidential",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: true,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
        },
        brainbayResidentialV2: {
            id: 17,
            categoryName: "brainbayResidentialV2",
            title: "brainbayResidentialV2",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: false,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "brainbay.png",
            isLoggedIn: false,
            isV2Brainbay: true,
        },
        lonres: {
            id: 18,
            categoryName: "lonres",
            title: "lonres",
            active: false,
            hidden: false,
            loading: false,
            entities: undefined,
            currentRequestId: undefined,
            error: undefined,
            disabled: true,
            isExternalSource: true,
            externalLoginPage: true,
            logo: "lonres.svg",
        },
    },
    form: {
        valuation: {},
        brainbay: {},
        lonres: {},
    },
    filter: undefined,
    showMoreOffset: undefined,
    valuations: {
        loading: false,
        loaded: false,
        entities: undefined,
        selected: undefined,
        currentRequestId: undefined,
        error: undefined,
    },
    mapSuggestion: undefined,
    isTableRendered: false,
    tableColumns: [],
};

const compView = createSlice({
    name: "compView",
    initialState,
    reducers: {
        setSelectedValuationId: (state, action) => {
            const newState = state;

            newState.valuations.selected = action.payload;

            return newState;
        },
        setMapSuggestion: (state, action) => {
            const newState = state;

            newState.mapSuggestion = action.payload;

            return newState;
        },
        setCategoryActive: (state, action) => {
            const newState = state;
            const { category, active } = action.payload;

            newState.categories[category].active = active;

            return newState;
        },
        setCategoryHidden: (state, action) => {
            const newState = state;
            const category = action.payload;
            const value = state.categories[category].hidden;
            newState.categories[category].hidden = !value;

            return newState;
        },
        setCategoryDisabled: (state, action) => {
            const newState = state;
            const category = action.payload;
            const value = state.categories[category].disabled;

            if (value === true) newState.categories[category].entities = undefined;

            newState.categories[category].disabled = !value;

            return newState;
        },
        setFilter: (state, action) => {
            const newState = state;
            newState.filter = action.payload;

            return newState;
        },
        setFormCategoryFilterValue: (state, action) => {
            const newState = state;
            const { category, name, value } = action.payload || {};
            newState.form[category].map((item) => {
                const newItemState = item;
                if (newItemState.name === name && value !== undefined) {
                    newItemState.value = value || undefined;
                }

                if (newItemState.condition.field === name) {
                    const valueAsArray = value?.split("|") || "";

                    newItemState.condition.value.forEach((conditionValue) => {
                        const itemHidden = !valueAsArray.includes(conditionValue);
                        newItemState.hidden = itemHidden;
                        newItemState.conditionRules = !itemHidden;
                    });
                }
                return newItemState;
            });
            return newState;
        },
        setFormCategoryFilterDisabled: (state, action) => {
            const newState = state;
            const { category, name, disabled } = action.payload;
            newState.form[category].map((item) => {
                const newItemState = item;
                if (newItemState.name === name) {
                    newItemState.disabled = disabled || false;
                }

                if (newItemState.condition.field === name) {
                    newItemState.disabled = disabled ? true : newItemState.disabled;
                    newItemState.hidden = !disabled ? !newItemState.conditionRules : disabled || false;
                }

                return newItemState;
            });
            return newState;
        },
        setShowMoreOffset: (state, action) => {
            const newState = state;
            newState.showMoreOffset = action.payload;

            return newState;
        },
        setIsTableRendered: (state, action) => {
            const newState = state;
            newState.isTableRendered = action.payload;

            return newState;
        },
        setTableColumns: (state, action) => {
            const newState = state;
            newState.tableColumns = action.payload;
        },
        setCurrentCategory: (state, action) => {
            const newState = state;
            newState.currentCategory = action.payload;

            return newState;
        },
        setIsBrainbayV2LoggedIn: (state, action) => {
            const newState = state;
            newState.categories.brainbayAgricultureV2.isLoggedIn = action.payload;
            newState.categories.brainbayBusinessV2.isLoggedIn = action.payload;
            newState.categories.brainbayResidentialV2.isLoggedIn = action.payload;

            return newState;
        },
        resetFormCategoryFiltersValue: (state, action) => {
            const newState = state;
            const { category } = action.payload;
            newState.form[category].map((item) => {
                const newItemState = item;

                newItemState.value = undefined;

                if (
                    validations.isNotEmpty(newItemState.start, true) &&
                    validations.isNotEmpty(newItemState.end, true)
                ) {
                    newItemState.value = `${newItemState.start.split("T")[0]};${newItemState.end.split("T")[0]}`;
                }

                if (validations.isNotEmpty(newItemState.min, true) && validations.isNotEmpty(newItemState.max, true)) {
                    newItemState.value = `${newItemState.min};${newItemState.max}`;
                }

                if (newItemState.condition.field) {
                    newItemState.hidden = true;
                    newItemState.conditionRules = false;
                }

                newItemState.disabled = true;

                return newItemState;
            });
            return newState;
        },
        resetValuations: (state) => {
            const newState = state;

            newState.valuations.entities = undefined;
            newState.valuations.selected = undefined;

            return newState;
        },
        resetCompView: () => {
            return initialState;
        },
        resetTableData: (state) => {
            const newState = state;
            newState.valuations.tableDate = [];
        },
    },
    extraReducers: {
        [compViewApi.pending]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;
            const { category } = arg || {};

            if (state.categories[category]?.loading === false && !state.categories[category]?.currentRequestId) {
                newState.categories[category].loading = true;
                newState.categories[category].currentRequestId = requestId;
            }
        },
        [compViewApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;
            const { category } = arg || {};
            if (
                state.categories[category]?.loading === true &&
                state.categories[category]?.currentRequestId === requestId
            ) {
                newState.categories[category].loading = false;
                newState.categories[category].entities = action.payload;
                newState.categories[category].currentRequestId = undefined;
            }
            if (newState.categories[category]?.isV2Brainbay) {
                newState.categories[category].isLoggedIn = true;
            }
        },
        [compViewApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;
            const { category } = arg || {};

            if (
                state.categories[category]?.loading === true &&
                state.categories[category]?.currentRequestId === requestId
            ) {
                const { error } = action?.payload || {};
                newState.categories[category].loading = false;
                newState.categories[category].error = error;
                newState.categories[category].currentRequestId = undefined;

                if (error?.includes("AUTHENTICATION_FAILED")) {
                    newState.categories[category].disabled = true;
                    if (newState.categories[category]?.isV2Brainbay) {
                        newState.categories[category].isLoggedIn = false;
                    }
                }
            }
        },
        [compViewValuationApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.valuations.loading === false && !state.valuations.currentRequestId) {
                newState.valuations.loading = true;
                newState.valuations.currentRequestId = requestId;
            }
        },
        [compViewValuationApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.valuations.loading === true && state.valuations.currentRequestId === requestId) {
                newState.valuations.loading = false;
                newState.valuations.loaded = true;
                newState.valuations.entities = action.payload;
                newState.valuations.currentRequestId = undefined;
            }
        },
        [compViewValuationApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.valuations.loading === true && state.valuations.currentRequestId === requestId) {
                newState.valuations.loading = false;
                newState.valuations.error = action.payload?.error;
                newState.valuations.currentRequestId = undefined;
            }
        },
        [compViewFormFieldsApi.pending]: () => {},
        [compViewFormFieldsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { arg } = action.meta;
            const { category } = arg || {};
            newState.form[category] = action.payload;
            if (newState.categories[category]?.isV2Brainbay) {
                newState.categories[category].isLoggedIn = true;
            }
        },
        [compViewFormFieldsApi.rejected]: (state, action) => {
            const newState = state;
            const { arg } = action.meta;
            const { category } = arg || {};

            const { error } = action?.payload || {};
            if (error?.includes("AUTHENTICATION_FAILED")) {
                newState.categories[category].disabled = true;
                if (newState.categories[category]?.isV2Brainbay) {
                    newState.categories[category].isLoggedIn = false;
                }
            }
        },
        [compViewDynamicCategoriesApi.pending]: () => {},
        [compViewDynamicCategoriesApi.fulfilled]: (state, action) => {
            const newState = state;
            Object.assign(newState.categories, action.payload);
        },
        [compViewDynamicCategoriesApi.rejected]: () => {},
        [compViewDynamicFormFieldsApi.pending]: () => {},
        [compViewDynamicFormFieldsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { arg } = action.meta;
            const category = arg || null;
            newState.form[category.toLowerCase()] = action.payload;
        },
        [compViewDynamicFormFieldsApi.rejected]: () => {},
    },
});

const {
    setSelectedValuationId,
    setMapSuggestion,
    setCategoryActive,
    setCategoryHidden,
    setCategoryDisabled,
    setFilter,
    setFormCategoryFilterValue,
    setFormCategoryFilterDisabled,
    setShowMoreOffset,
    setIsTableRendered,
    setTableColumns,
    setCurrentCategory,
    setIsBrainbayV2LoggedIn,
    resetFormCategoryFiltersValue,
    resetValuations,
    resetCompView,
} = compView.actions;
const compViewReducer = compView.reducer;

export {
    compViewReducer,
    setSelectedValuationId,
    compViewApi,
    compViewValuationApi,
    compViewFormFieldsApi,
    compViewDynamicFormFieldsApi,
    compViewDynamicCategoriesApi,
    setMapSuggestion,
    setCategoryActive,
    setCategoryHidden,
    setCategoryDisabled,
    setFilter,
    setFormCategoryFilterValue,
    setFormCategoryFilterDisabled,
    setShowMoreOffset,
    setIsTableRendered,
    setTableColumns,
    setCurrentCategory,
    setIsBrainbayV2LoggedIn,
    resetFormCategoryFiltersValue,
    resetValuations,
    resetCompView,
};

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class MRound extends Expression {
    calculate() {
        if (this.items.length === 2) {
            let roundTo = this.items[1].calculate();
            return roundTo * Math.round(this.items[0].calculate() / roundTo);
        }

        return 0;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument));
        });
    }
}

export default MRound;

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class SumProduct extends Expression {
    calculate() {
        if (!this.items.length) {
            return 0;
        }

        let itemResults = this.items.map((item) => item.calculate());
        if (!itemResults.every((itemResult) => itemResult.length === itemResults[0].length)) {
            return 0;
        }

        let resultProduct = Array(itemResults[0].length).fill(1);

        itemResults.forEach((itemResult) => {
            resultProduct = resultProduct.map((value, index) => value * itemResult[index]);
        });

        if (!resultProduct.length) {
            return 0;
        }

        return resultProduct.reduce((a, b) => a + b);
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default SumProduct;

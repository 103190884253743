import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class SubtractDates extends Expression {
    calculate() {
        const result = this.items[0].calculate();
        const result2 = this.items[1].calculate();

        const startTime = new Date(result2);
        const endTime = new Date(result);

        const diff = endTime.getTime() - startTime.getTime();

        return diff / 1000 / 60 / 60 / 24 / 365;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default SubtractDates;

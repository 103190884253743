import { PORTFOLIO_QUERY_RESULT } from "../actions/action-portfolio";
import { fromJS, List as ImmutableList } from "immutable";

export default function (state = new ImmutableList(), action) {
    switch (action.type) {
        case PORTFOLIO_QUERY_RESULT().type:
            return fromJS(action.payload.data.data);
        default:
            return state;
    }
}

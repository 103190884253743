import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { IntlProvider } from "react-intl";

import useAuth from "features/authentication/hooks/useAuth";
import { getUserLanguage, isUserProfileLoaded } from "features/userProfile/userProfileSelector";
import { getJwt } from "features/authentication/authenticationSelector";
import { setApplicationModuleIsLoaded } from "features/ApplicationLoader/applicationLoaderSlice";
import getEnvValue from "helpers/getEnvValue";
import { validations } from "@kateinnovations/javascript-helpers";
import { setTranslations } from "./translationsSlice";
import { getTranslations, getTranslationsIsLoaded } from "./translationsSelector";
import fetchTranslations from "./fetchTranslations";

const defaultLanguage = getEnvValue("LOCAL_LANGUAGE")?.toLowerCase() || "en";
const translationSource = getEnvValue("TRANSLATION_SOURCE")?.toLowerCase() || "distributed";

const TranslationProvider = ({ children }) => {
    const dispatch = useDispatch();
    const isAuth = useAuth();
    const userProfileLoaded = useSelector(isUserProfileLoaded);
    const languageCode = useSelector(getUserLanguage) || defaultLanguage;
    const translations = useSelector(getTranslations);
    const translationsIsLoaded = useSelector(getTranslationsIsLoaded);
    const [localTranslation, setLocalTranslations] = useState(undefined);
    const [hasLocalTranslation, setHasLocalTranslation] = useState(false);
    const jwt = useSelector(getJwt);

    useEffect(() => {
        if (!hasLocalTranslation && languageCode) {
            fetchTranslations.local(languageCode).then((response) => {
                setLocalTranslations(response);
                setHasLocalTranslation(true);
            });
        }
    }, [hasLocalTranslation, languageCode]);

    useEffect(() => {
        const getAndSetTranslations = async () => {
            const tempTranslations = await fetchTranslations[translationSource](languageCode);
            dispatch(setTranslations({ translations: tempTranslations }));
        };

        if (!translationsIsLoaded && userProfileLoaded && isAuth && languageCode && !validations.isEmpty(jwt))
            getAndSetTranslations();

        if (!isAuth) setHasLocalTranslation(false);
    }, [translationsIsLoaded, jwt, languageCode, isAuth, userProfileLoaded, dispatch]);

    useEffect(() => {
        if (translationsIsLoaded) dispatch(setApplicationModuleIsLoaded({ title: "translations" }));
    }, [translationsIsLoaded, dispatch]);

    return (
        <IntlProvider locale="en" messages={translations || localTranslation}>
            {children}
        </IntlProvider>
    );
};

TranslationProvider.propTypes = {
    children: PropTypes.element.isRequired,
};

export default TranslationProvider;

export const IMPORTER_GET_PROPERTIES = ({ type, propertiesURL, propertiesName } = {}) => ({
    type: "IMPORTER_GET_PROPERTIES",
    payload: {
        type,
        propertiesURL,
        propertiesName,
    },
});

export const IMPORTER_GET_PROPERTIES_RESULT = ({ propertiesName, properties } = {}) => ({
    type: "IMPORTER_GET_PROPERTIES_RESULT",
    payload: {
        propertiesName,
        properties,
    },
});

export const IMPORTER_IMPORT = ({ type, transactionType = null, importItems, postURL, onSuccess, onError } = {}) => ({
    type: "IMPORTER_IMPORT",
    payload: {
        type,
        transactionType,
        importItems,
        postURL,
        onSuccess,
        onError,
    },
});

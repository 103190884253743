const countryListDE = [
    { countryCode: 4, country: "Afghanistan", alpha2: "af", alpha3: "afg" },
    { countryCode: 818, country: "Ägypten", alpha2: "eg", alpha3: "egy" },
    { countryCode: 248, country: "Åland", alpha2: "ax", alpha3: "ala" },
    { countryCode: 8, country: "Albanien", alpha2: "al", alpha3: "alb" },
    { countryCode: 12, country: "Algerien", alpha2: "dz", alpha3: "dza" },
    { countryCode: 16, country: "Amerikanisch-Samoa", alpha2: "as", alpha3: "asm" },
    { countryCode: 850, country: "Amerikanische Jungferninseln", alpha2: "vi", alpha3: "vir" },
    { countryCode: 20, country: "Andorra", alpha2: "ad", alpha3: "and" },
    { countryCode: 24, country: "Angola", alpha2: "ao", alpha3: "ago" },
    { countryCode: 660, country: "Anguilla", alpha2: "ai", alpha3: "aia" },
    { countryCode: 10, country: "Antarktis (Sonderstatus durch Antarktisvertrag)", alpha2: "aq", alpha3: "ata" },
    { countryCode: 28, country: "Antigua und Barbuda", alpha2: "ag", alpha3: "atg" },
    { countryCode: 226, country: "Äquatorialguinea", alpha2: "gq", alpha3: "gnq" },
    { countryCode: 32, country: "Argentinien", alpha2: "ar", alpha3: "arg" },
    { countryCode: 51, country: "Armenien", alpha2: "am", alpha3: "arm" },
    { countryCode: 533, country: "Aruba", alpha2: "aw", alpha3: "abw" },
    { countryCode: 31, country: "Aserbaidschan", alpha2: "az", alpha3: "aze" },
    { countryCode: 231, country: "Äthiopien", alpha2: "et", alpha3: "eth" },
    { countryCode: 36, country: "Australien", alpha2: "au", alpha3: "aus" },
    { countryCode: 44, country: "Bahamas", alpha2: "bs", alpha3: "bhs" },
    { countryCode: 48, country: "Bahrain", alpha2: "bh", alpha3: "bhr" },
    { countryCode: 50, country: "Bangladesch", alpha2: "bd", alpha3: "bgd" },
    { countryCode: 52, country: "Barbados", alpha2: "bb", alpha3: "brb" },
    { countryCode: 112, country: "Belarus", alpha2: "by", alpha3: "blr" },
    { countryCode: 56, country: "Belgien", alpha2: "be", alpha3: "bel" },
    { countryCode: 84, country: "Belize", alpha2: "bz", alpha3: "blz" },
    { countryCode: 204, country: "Benin", alpha2: "bj", alpha3: "ben" },
    { countryCode: 60, country: "Bermuda", alpha2: "bm", alpha3: "bmu" },
    { countryCode: 64, country: "Bhutan", alpha2: "bt", alpha3: "btn" },
    { countryCode: 68, country: "Bolivien", alpha2: "bo", alpha3: "bol" },
    { countryCode: 535, country: "Bonaire, Saba, Sint Eustatius", alpha2: "bq", alpha3: "bes" },
    { countryCode: 70, country: "Bosnien und Herzegowina", alpha2: "ba", alpha3: "bih" },
    { countryCode: 72, country: "Botswana", alpha2: "bw", alpha3: "bwa" },
    { countryCode: 74, country: "Bouvetinsel", alpha2: "bv", alpha3: "bvt" },
    { countryCode: 76, country: "Brasilien", alpha2: "br", alpha3: "bra" },
    { countryCode: 92, country: "Britische Jungferninseln", alpha2: "vg", alpha3: "vgb" },
    { countryCode: 86, country: "Britisches Territorium im Indischen Ozean", alpha2: "io", alpha3: "iot" },
    { countryCode: 96, country: "Brunei", alpha2: "bn", alpha3: "brn" },
    { countryCode: 100, country: "Bulgarien", alpha2: "bg", alpha3: "bgr" },
    { countryCode: 854, country: "Burkina Faso", alpha2: "bf", alpha3: "bfa" },
    { countryCode: 108, country: "Burundi", alpha2: "bi", alpha3: "bdi" },
    { countryCode: 152, country: "Chile", alpha2: "cl", alpha3: "chl" },
    { countryCode: 156, country: "Volksrepublik China", alpha2: "cn", alpha3: "chn" },
    { countryCode: 184, country: "Cookinseln", alpha2: "ck", alpha3: "cok" },
    { countryCode: 188, country: "Costa Rica", alpha2: "cr", alpha3: "cri" },
    { countryCode: 531, country: "Curaçao", alpha2: "cw", alpha3: "cuw" },
    { countryCode: 208, country: "Dänemark", alpha2: "dk", alpha3: "dnk" },
    { countryCode: 276, country: "Deutschland", alpha2: "de", alpha3: "deu" },
    { countryCode: 212, country: "Dominica", alpha2: "dm", alpha3: "dma" },
    { countryCode: 214, country: "Dominikanische Republik", alpha2: "do", alpha3: "dom" },
    { countryCode: 262, country: "Dschibuti", alpha2: "dj", alpha3: "dji" },
    { countryCode: 218, country: "Ecuador", alpha2: "ec", alpha3: "ecu" },
    { countryCode: 384, country: "Elfenbeinküste", alpha2: "ci", alpha3: "civ" },
    { countryCode: 222, country: "El Salvador", alpha2: "sv", alpha3: "slv" },
    { countryCode: 232, country: "Eritrea", alpha2: "er", alpha3: "eri" },
    { countryCode: 233, country: "Estland", alpha2: "ee", alpha3: "est" },
    { countryCode: 748, country: "Eswatini", alpha2: "sz", alpha3: "swz" },
    { countryCode: 238, country: "Falklandinseln", alpha2: "fk", alpha3: "flk" },
    { countryCode: 234, country: "Färöer", alpha2: "fo", alpha3: "fro" },
    { countryCode: 242, country: "Fidschi", alpha2: "fj", alpha3: "fji" },
    { countryCode: 246, country: "Finnland", alpha2: "fi", alpha3: "fin" },
    { countryCode: 250, country: "Frankreich", alpha2: "fr", alpha3: "fra" },
    { countryCode: 254, country: "Französisch-Guayana", alpha2: "gf", alpha3: "guf" },
    { countryCode: 258, country: "Französisch-Polynesien", alpha2: "pf", alpha3: "pyf" },
    { countryCode: 260, country: "Französische Süd- und Antarktisgebiete", alpha2: "tf", alpha3: "atf" },
    { countryCode: 266, country: "Gabun", alpha2: "ga", alpha3: "gab" },
    { countryCode: 270, country: "Gambia", alpha2: "gm", alpha3: "gmb" },
    { countryCode: 268, country: "Georgien", alpha2: "ge", alpha3: "geo" },
    { countryCode: 288, country: "Ghana", alpha2: "gh", alpha3: "gha" },
    { countryCode: 292, country: "Gibraltar", alpha2: "gi", alpha3: "gib" },
    { countryCode: 308, country: "Grenada", alpha2: "gd", alpha3: "grd" },
    { countryCode: 300, country: "Griechenland", alpha2: "gr", alpha3: "grc" },
    { countryCode: 304, country: "Grönland", alpha2: "gl", alpha3: "grl" },
    { countryCode: 312, country: "Guadeloupe", alpha2: "gp", alpha3: "glp" },
    { countryCode: 316, country: "Guam", alpha2: "gu", alpha3: "gum" },
    { countryCode: 320, country: "Guatemala", alpha2: "gt", alpha3: "gtm" },
    { countryCode: 831, country: "Guernsey (Kanalinsel)", alpha2: "gg", alpha3: "ggy" },
    { countryCode: 324, country: "Guinea", alpha2: "gn", alpha3: "gin" },
    { countryCode: 624, country: "Guinea-Bissau", alpha2: "gw", alpha3: "gnb" },
    { countryCode: 328, country: "Guyana", alpha2: "gy", alpha3: "guy" },
    { countryCode: 332, country: "Haiti", alpha2: "ht", alpha3: "hti" },
    { countryCode: 334, country: "Heard und McDonaldinseln", alpha2: "hm", alpha3: "hmd" },
    { countryCode: 340, country: "Honduras", alpha2: "hn", alpha3: "hnd" },
    { countryCode: 344, country: "Hongkong", alpha2: "hk", alpha3: "hkg" },
    { countryCode: 356, country: "Indien", alpha2: "in", alpha3: "ind" },
    { countryCode: 360, country: "Indonesien", alpha2: "id", alpha3: "id" },
    { countryCode: 833, country: "Insel Man", alpha2: "im", alpha3: "imn" },
    { countryCode: 368, country: "Irak", alpha2: "iq", alpha3: "irq" },
    { countryCode: 364, country: "Iran", alpha2: "ir", alpha3: "irn" },
    { countryCode: 372, country: "Irland", alpha2: "ie", alpha3: "irl" },
    { countryCode: 352, country: "Island", alpha2: "is", alpha3: "isl" },
    { countryCode: 376, country: "Israel", alpha2: "il", alpha3: "isr" },
    { countryCode: 380, country: "Italien", alpha2: "it", alpha3: "ita" },
    { countryCode: 388, country: "Jamaika", alpha2: "jm", alpha3: "jam" },
    { countryCode: 392, country: "Japan", alpha2: "jp", alpha3: "jpn" },
    { countryCode: 887, country: "Jemen", alpha2: "ye", alpha3: "yem" },
    { countryCode: 832, country: "Jersey (Kanalinsel)", alpha2: "je", alpha3: "jey" },
    { countryCode: 400, country: "Jordanien", alpha2: "jo", alpha3: "jor" },
    { countryCode: 136, country: "Kaimaninseln", alpha2: "ky", alpha3: "cym" },
    { countryCode: 116, country: "Kambodscha", alpha2: "kh", alpha3: "khm" },
    { countryCode: 120, country: "Kamerun", alpha2: "cm", alpha3: "cmr" },
    { countryCode: 124, country: "Kanada", alpha2: "ca", alpha3: "can" },
    { countryCode: 132, country: "Kap Verde", alpha2: "cv", alpha3: "cpv" },
    { countryCode: 398, country: "Kasachstan", alpha2: "kz", alpha3: "kaz" },
    { countryCode: 634, country: "Katar", alpha2: "qa", alpha3: "qat" },
    { countryCode: 404, country: "Kenia", alpha2: "ke", alpha3: "ken" },
    { countryCode: 417, country: "Kirgisistan", alpha2: "kg", alpha3: "kgz" },
    { countryCode: 296, country: "Kiribati", alpha2: "ki", alpha3: "kir" },
    { countryCode: 166, country: "Kokosinseln", alpha2: "cc", alpha3: "cck" },
    { countryCode: 170, country: "Kolumbien", alpha2: "co", alpha3: "col" },
    { countryCode: 174, country: "Komoren", alpha2: "km", alpha3: "com" },
    { countryCode: 180, country: "Kongo, Demokratische Republik", alpha2: "cd", alpha3: "cod" },
    { countryCode: 178, country: "Kongo, Republik", alpha2: "cg", alpha3: "cog" },
    { countryCode: 408, country: "Korea, Nord", alpha2: "kp", alpha3: "prk" },
    { countryCode: 410, country: "Korea, Süd", alpha2: "kr", alpha3: "kor" },
    { countryCode: 191, country: "Kroatien", alpha2: "hr", alpha3: "hrv" },
    { countryCode: 192, country: "Kuba", alpha2: "cu", alpha3: "cub" },
    { countryCode: 414, country: "Kuwait", alpha2: "kw", alpha3: "kwt" },
    { countryCode: 418, country: "Laos", alpha2: "la", alpha3: "lao" },
    { countryCode: 426, country: "Lesotho", alpha2: "ls", alpha3: "lso" },
    { countryCode: 428, country: "Lettland", alpha2: "lv", alpha3: "lva" },
    { countryCode: 422, country: "Libanon", alpha2: "lb", alpha3: "lbn" },
    { countryCode: 430, country: "Liberia", alpha2: "lr", alpha3: "lbr" },
    { countryCode: 434, country: "Libyen", alpha2: "ly", alpha3: "lby" },
    { countryCode: 438, country: "Liechtenstein", alpha2: "li", alpha3: "lie" },
    { countryCode: 440, country: "Litauen", alpha2: "lt", alpha3: "ltu" },
    { countryCode: 442, country: "Luxemburg", alpha2: "lu", alpha3: "lux" },
    { countryCode: 446, country: "Macau", alpha2: "mo", alpha3: "mac" },
    { countryCode: 450, country: "Madagaskar", alpha2: "mg", alpha3: "mdg" },
    { countryCode: 454, country: "Malawi", alpha2: "mw", alpha3: "mwi" },
    { countryCode: 458, country: "Malaysia", alpha2: "my", alpha3: "mys" },
    { countryCode: 462, country: "Malediven", alpha2: "mv", alpha3: "mdv" },
    { countryCode: 466, country: "Mali", alpha2: "ml", alpha3: "mli" },
    { countryCode: 470, country: "Malta", alpha2: "mt", alpha3: "mlt" },
    { countryCode: 504, country: "Marokko", alpha2: "ma", alpha3: "mar" },
    { countryCode: 584, country: "Marshallinseln", alpha2: "mh", alpha3: "mhl" },
    { countryCode: 474, country: "Martinique", alpha2: "mq", alpha3: "mtq" },
    { countryCode: 478, country: "Mauretanien", alpha2: "mr", alpha3: "mrt" },
    { countryCode: 480, country: "Mauritius", alpha2: "mu", alpha3: "mus" },
    { countryCode: 175, country: "Mayotte", alpha2: "yt", alpha3: "myt" },
    { countryCode: 484, country: "Mexiko", alpha2: "mx", alpha3: "mex" },
    { countryCode: 583, country: "Mikronesien", alpha2: "fm", alpha3: "fsm" },
    { countryCode: 498, country: "Moldau", alpha2: "md", alpha3: "mda" },
    { countryCode: 492, country: "Monaco", alpha2: "mc", alpha3: "mco" },
    { countryCode: 496, country: "Mongolei", alpha2: "mn", alpha3: "mng" },
    { countryCode: 499, country: "Montenegro", alpha2: "me", alpha3: "mne" },
    { countryCode: 500, country: "Montserrat", alpha2: "ms", alpha3: "msr" },
    { countryCode: 508, country: "Mosambik", alpha2: "mz", alpha3: "moz" },
    { countryCode: 104, country: "Myanmar", alpha2: "mm", alpha3: "mmr" },
    { countryCode: 516, country: "Namibia", alpha2: "na", alpha3: "nam" },
    { countryCode: 520, country: "Nauru", alpha2: "nr", alpha3: "nru" },
    { countryCode: 524, country: "Nepal", alpha2: "np", alpha3: "npl" },
    { countryCode: 540, country: "Neukaledonien", alpha2: "nc", alpha3: "ncl" },
    { countryCode: 554, country: "Neuseeland", alpha2: "nz", alpha3: "nzl" },
    { countryCode: 558, country: "Nicaragua", alpha2: "ni", alpha3: "nic" },
    { countryCode: 528, country: "Niederlande", alpha2: "nl", alpha3: "nld" },
    { countryCode: 562, country: "Niger", alpha2: "ne", alpha3: "ner" },
    { countryCode: 566, country: "Nigeria", alpha2: "ng", alpha3: "nga" },
    { countryCode: 570, country: "Niue", alpha2: "nu", alpha3: "niu" },
    { countryCode: 580, country: "Nördliche Marianen", alpha2: "mp", alpha3: "mnp" },
    { countryCode: 807, country: "Nordmazedonien", alpha2: "mk", alpha3: "mkd" },
    { countryCode: 574, country: "Norfolkinsel", alpha2: "nf", alpha3: "nfk" },
    { countryCode: 578, country: "Norwegen", alpha2: "no", alpha3: "nor" },
    { countryCode: 512, country: "Oman", alpha2: "om", alpha3: "omn" },
    { countryCode: 40, country: "Österreich", alpha2: "at", alpha3: "aut" },
    { countryCode: 626, country: "Osttimor", alpha2: "tl", alpha3: "tls" },
    { countryCode: 586, country: "Pakistan", alpha2: "pk", alpha3: "pak" },
    { countryCode: 275, country: "Palästina", alpha2: "ps", alpha3: "pse" },
    { countryCode: 585, country: "Palau", alpha2: "pw", alpha3: "plw" },
    { countryCode: 591, country: "Panama", alpha2: "pa", alpha3: "pan" },
    { countryCode: 598, country: "Papua-Neuguinea", alpha2: "pg", alpha3: "png" },
    { countryCode: 600, country: "Paraguay", alpha2: "py", alpha3: "pry" },
    { countryCode: 604, country: "Peru", alpha2: "pe", alpha3: "per" },
    { countryCode: 608, country: "Philippinen", alpha2: "ph", alpha3: "phl" },
    { countryCode: 612, country: "Pitcairninseln", alpha2: "pn", alpha3: "pcn" },
    { countryCode: 616, country: "Polen", alpha2: "pl", alpha3: "pol" },
    { countryCode: 620, country: "Portugal", alpha2: "pt", alpha3: "prt" },
    { countryCode: 630, country: "Puerto Rico", alpha2: "pr", alpha3: "pri" },
    { countryCode: 638, country: "Réunion", alpha2: "re", alpha3: "reu" },
    { countryCode: 646, country: "Ruanda", alpha2: "rw", alpha3: "rwa" },
    { countryCode: 642, country: "Rumänien", alpha2: "ro", alpha3: "rou" },
    { countryCode: 643, country: "Russland", alpha2: "ru", alpha3: "rus" },
    { countryCode: 90, country: "Salomonen", alpha2: "sb", alpha3: "slb" },
    { countryCode: 652, country: "Saint-Barthélemy", alpha2: "bl", alpha3: "blm" },
    { countryCode: 663, country: "Saint-Martin (französischer Teil)", alpha2: "mf", alpha3: "maf" },
    { countryCode: 894, country: "Sambia", alpha2: "zm", alpha3: "zmb" },
    { countryCode: 882, country: "Samoa", alpha2: "ws", alpha3: "wsm" },
    { countryCode: 674, country: "San Marino", alpha2: "sm", alpha3: "smr" },
    { countryCode: 678, country: "São Tomé und Príncipe", alpha2: "st", alpha3: "stp" },
    { countryCode: 682, country: "Saudi-Arabien", alpha2: "sa", alpha3: "sau" },
    { countryCode: 752, country: "Schweden", alpha2: "se", alpha3: "swe" },
    { countryCode: 756, country: "Schweiz", alpha2: "ch", alpha3: "che" },
    { countryCode: 686, country: "Senegal", alpha2: "sn", alpha3: "sen" },
    { countryCode: 688, country: "Serbien", alpha2: "rs", alpha3: "srb" },
    { countryCode: 690, country: "Seychellen", alpha2: "sc", alpha3: "syc" },
    { countryCode: 694, country: "Sierra Leone", alpha2: "sl", alpha3: "sle" },
    { countryCode: 716, country: "Simbabwe", alpha2: "zw", alpha3: "zwe" },
    { countryCode: 702, country: "Singapur", alpha2: "sg", alpha3: "sgp" },
    { countryCode: 534, country: "Sint Maarten", alpha2: "sx", alpha3: "sxm" },
    { countryCode: 703, country: "Slowakei", alpha2: "sk", alpha3: "svk" },
    { countryCode: 705, country: "Slowenien", alpha2: "si", alpha3: "svn" },
    { countryCode: 706, country: "Somalia", alpha2: "so", alpha3: "som" },
    { countryCode: 724, country: "Spanien", alpha2: "es", alpha3: "esp" },
    { countryCode: 144, country: "Sri Lanka", alpha2: "lk", alpha3: "lka" },
    { countryCode: 654, country: "St. Helena, Ascension und Tristan da Cunha", alpha2: "sh", alpha3: "shn" },
    { countryCode: 659, country: "St. Kitts und Nevis", alpha2: "kn", alpha3: "kna" },
    { countryCode: 662, country: "St. Lucia", alpha2: "lc", alpha3: "lca" },
    { countryCode: 666, country: "Saint-Pierre und Miquelon", alpha2: "pm", alpha3: "spm" },
    { countryCode: 670, country: "St. Vincent und die Grenadinen", alpha2: "vc", alpha3: "vct" },
    { countryCode: 710, country: "Südafrika", alpha2: "za", alpha3: "zaf" },
    { countryCode: 729, country: "Sudan", alpha2: "sd", alpha3: "sdn" },
    { countryCode: 239, country: "Südgeorgien und die Südlichen Sandwichinseln", alpha2: "gs", alpha3: "sgs" },
    { countryCode: 728, country: "Südsudan", alpha2: "ss", alpha3: "ssd" },
    { countryCode: 740, country: "Suricountry", alpha2: "sr", alpha3: "sur" },
    { countryCode: 744, country: "Spitzbergen und Jan Mayen", alpha2: "sj", alpha3: "sjm" },
    { countryCode: 760, country: "Syrien", alpha2: "sy", alpha3: "syr" },
    { countryCode: 762, country: "Tadschikistan", alpha2: "tj", alpha3: "tjk" },
    { countryCode: 158, country: "Republik China", alpha2: "tw", alpha3: "twn" },
    { countryCode: 834, country: "Tansania", alpha2: "tz", alpha3: "tza" },
    { countryCode: 764, country: "Thailand", alpha2: "th", alpha3: "tha" },
    { countryCode: 768, country: "Togo", alpha2: "tg", alpha3: "tgo" },
    { countryCode: 772, country: "Tokelau", alpha2: "tk", alpha3: "tkl" },
    { countryCode: 776, country: "Tonga", alpha2: "to", alpha3: "ton" },
    { countryCode: 780, country: "Trinidad und Tobago", alpha2: "tt", alpha3: "tto" },
    { countryCode: 148, country: "Tschad", alpha2: "td", alpha3: "tcd" },
    { countryCode: 203, country: "Tschechien", alpha2: "cz", alpha3: "cze" },
    { countryCode: 788, country: "Tunesien", alpha2: "tn", alpha3: "tun" },
    { countryCode: 792, country: "Türkei", alpha2: "tr", alpha3: "tur" },
    { countryCode: 795, country: "Turkmenistan", alpha2: "tm", alpha3: "tkm" },
    { countryCode: 796, country: "Turks- und Caicosinseln", alpha2: "tc", alpha3: "tca" },
    { countryCode: 798, country: "Tuvalu", alpha2: "tv", alpha3: "tuv" },
    { countryCode: 800, country: "Uganda", alpha2: "ug", alpha3: "uga" },
    { countryCode: 804, country: "Ukraine", alpha2: "ua", alpha3: "ukr" },
    { countryCode: 348, country: "Ungarn", alpha2: "hu", alpha3: "hun" },
    { countryCode: 581, country: "United States Minor Outlying Islands", alpha2: "um", alpha3: "umi" },
    { countryCode: 858, country: "Uruguay", alpha2: "uy", alpha3: "ury" },
    { countryCode: 860, country: "Usbekistan", alpha2: "uz", alpha3: "uzb" },
    { countryCode: 548, country: "Vanuatu", alpha2: "vu", alpha3: "vut" },
    { countryCode: 336, country: "Vatikanstadt", alpha2: "va", alpha3: "vat" },
    { countryCode: 862, country: "Venezuela", alpha2: "ve", alpha3: "ven" },
    { countryCode: 784, country: "Vereinigte Arabische Emirate", alpha2: "ae", alpha3: "are" },
    { countryCode: 840, country: "Vereinigte Staaten", alpha2: "us", alpha3: "usa" },
    { countryCode: 826, country: "Vereinigtes Königreich", alpha2: "gb", alpha3: "gbr" },
    { countryCode: 704, country: "Vietnam", alpha2: "vn", alpha3: "vnm" },
    { countryCode: 876, country: "Wallis und Futuna", alpha2: "wf", alpha3: "wlf" },
    { countryCode: 162, country: "Weihnachtsinsel", alpha2: "cx", alpha3: "cxr" },
    { countryCode: 732, country: "Westsahara", alpha2: "eh", alpha3: "esh" },
    { countryCode: 140, country: "Zentral­afrikanische Republik", alpha2: "cf", alpha3: "caf" },
    { countryCode: 196, country: "Zypern", alpha2: "cy", alpha3: "cyp" },
];

export default countryListDE;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const functionalityApi = createAsyncThunk(
    "functionality/functionalityApi",
    async (value, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, functionality, userProfile } = getState();

        if (functionality.loading === false || requestId !== functionality.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const { jwt } = auth;
        const url = defaultConfig.api.functionality;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            return response.data;
        }

        dispatch(setAuthError("authenticationError"));
        return rejectWithValue({ error });
    }
);

export default functionalityApi;

import { fromJS, Map as ImmutableMap } from "immutable";

import * as ReduxActions from "../actions/action-valuation-layout";
import pathToPropertyValue from "../Valuation";

const emptyState = fromJS({
    config: null,
    configAnalysis: null,
    propertyDefinitions: {},
    currentValuationId: null,
    queue: {
        queueEntries: null,
        queueProcessing: null,
        updating: false,
    },
    valuationsExported: false,
    generatedReportsDownloaded: false,
});

let pathToPropValue = "";

// _OLD_TODO: 'valuations' should be the root level item in the store, not the ID of the valuation.
export default function (state = emptyState, action) {
    let pathToProperty = "";
    let predefinedTexts = null;

    const objectFindCompare = (objectId, object) => {
        return objectId === object.get("id");
    };

    switch (action.type) {
        case ReduxActions.VALUATION_RETRIEVE_RESULT().type:
            predefinedTexts = state.getIn([action.payload.valuationId, "predefinedTexts"]);

            const valuation = { ...action.payload.valuation };

            if (predefinedTexts) {
                valuation["predefinedTexts"] = predefinedTexts;
            }

            const oldObjects =
                state.get(action.payload.valuationId) && state.get(action.payload.valuationId).get("objects");

            if (oldObjects) {
                valuation["objects"] = oldObjects;
            }

            return state
                .set("currentValuationId", action.payload.valuationId)
                .update(action.payload.valuationId, () => fromJS(valuation));

        case ReduxActions.VALUATION_CLEAR_DATA().type:
            return emptyState;

        case ReduxActions.VALUATION_RETRIEVE_PROPERTIES_RESULT().type:
            const valuationProperties =
                action.payload.valuationProperties instanceof ImmutableMap
                    ? action.payload.valuationProperties
                    : fromJS(action.payload.valuationProperties);
            return state
                .setIn([action.payload.valuationId, "properties"], valuationProperties)
                .set("propertyDefinitions", fromJS(action.payload.propertyDefinitions));

        case ReduxActions.VALUATION_RETRIEVE_PROPERTY_DEFINITION_CONFIG_ANALYSIS().type:
            return state.merge(
                fromJS({
                    configReady: true,
                })
            );

        case ReduxActions.VALUATION_RETRIEVE_OBJECTS_RESULT().type:
            return state.setIn([action.payload.valuationId, "objects"], fromJS(action.payload.objects));

        case ReduxActions.VALUATION_RETRIEVE_OBJECT_PROPERTIES_RESULT().type:
            const objectProperties = action.payload.objectProperties;
            const allObjects = state.getIn([action.payload.valuationId, "objects"]);

            const indexOfObject =
                allObjects && allObjects.findIndex((object) => objectFindCompare(action.payload.objectId, object));
            if (indexOfObject === -1) {
                return state;
            }

            const propertyDefinitions = Object.values(action.payload.objectProperties)
                .map((item) => item.properties.map((property) => property.propertyDefinition))
                .flat();
            const propertyDefinitionsObject = {};

            propertyDefinitions.forEach((propertyDefinition) => {
                propertyDefinitionsObject[propertyDefinition.id] = propertyDefinition;
            });

            const objectPropertyDefinitions = fromJS({
                propertyDefinitions: propertyDefinitionsObject,
            });

            const ObjectInState = state.setIn(
                [action.payload.valuationId, "objects", indexOfObject, "properties"],
                fromJS(objectProperties)
            );
            return ObjectInState.mergeDeep(objectPropertyDefinitions);

        case ReduxActions.VALUATION_DELETE_OBJECT_RESULT().type:
            const objects = state.getIn([action.payload.valuationId, "objects"]);
            const deletedIndex =
                objects && objects.findIndex((object) => objectFindCompare(action.payload.objectId, object));

            return state.deleteIn([action.payload.valuationId, "objects", deletedIndex]);

        case ReduxActions.VALUATION_RETRIEVE_HISTORY_RESULT().type:
            // history is of type array, that is why you need setIn
            return state.setIn([action.payload.valuationId, "history"], fromJS(action.payload.history));

        case ReduxActions.VALUATION_RETRIEVE_APPROVALS_RESULT().type:
            return state.mergeIn([action.payload.valuationId, "approvals"], fromJS(action.payload.approvals));

        case ReduxActions.VALUATION_RETRIEVE_COI_ENTRIES_RESULT().type:
            return state.setIn([action.payload.valuationId, "coiEntries"], fromJS(action.payload.coiEntries));

        case ReduxActions.VALUATION_EDIT_CHANGE_TAB().type:
            return state.setIn([action.payload.valuationId, "activeTab"], action.payload.activeTab);

        case ReduxActions.VALUATION_RETRIEVE_IMAGES_RESULT().type:
            return state.setIn(
                [action.payload.valuationId, "images", action.payload.propertyId],
                fromJS(action.payload.images)
            );

        case ReduxActions.VALUATION_REORDER_IMAGES_RESULT().type:
            return state.setIn(
                [action.payload.valuationId, "images", action.payload.propertyId],
                action.payload.images
            );

        case ReduxActions.VALUATIONS_SET_GALLERY_ENTRY().type:
            return state
                .set("selectedProperty", action.payload.propertyId)
                .set("selectedImage", action.payload.image)
                .set("allowGalleryEdit", !action.payload.disabled ? true : false);

        case ReduxActions.VALUATION_DELETE_IMAGE_RESULT().type:
            const filterDeleted = (i) => {
                if (typeof action.payload.fileNames === "string") {
                    return i.get("name") !== action.payload.fileNames;
                }
                return action.payload.fileNames.indexOf(i.get("name")) === -1;
            };
            return state.setIn(
                [action.payload.valuationId, "images", action.payload.propertyId],
                state
                    .getIn([action.payload.valuationId, "images", action.payload.propertyId])
                    .filter((i) => filterDeleted(i))
            );

        case ReduxActions.VALUATION_PROPERTY_VALUE_CHANGED().type:
            const path = pathToPropertyValue({
                ids: action.payload,
                valuationState: state,
            });
            return state.setIn([...path, "client"], action.payload.value);

        case ReduxActions.VALUATION_UPLOAD_FILE_RESULT().type:
            pathToPropValue = pathToPropertyValue({
                ids: action.payload,
                valuationState: state,
            });
            if (!state.hasIn(pathToPropValue)) {
                return state.setIn(
                    pathToPropValue,
                    fromJS({
                        client: action.payload.fileLocations,
                        error: null,
                        server: action.payload.fileLocations,
                        updating: false,
                    })
                );
            }
            return state.setIn([...pathToPropValue, "server"], action.payload.fileLocations);

        case ReduxActions.VALUATION_REMOVE_FILE_RESULT().type:
            pathToPropValue = pathToPropertyValue({
                ids: action.payload,
                valuationState: state,
            });

            // Update server and client value
            const filteredFiles = state
                .getIn([...pathToPropValue, "server"])
                .filter((item) => item.name !== action.payload.fileName);
            return state
                .setIn([...pathToPropValue, "server"], filteredFiles)
                .setIn([...pathToPropValue, "client"], filteredFiles);

        case ReduxActions.VALUATION_GET_FILES_RESULT().type:
            const files = action.payload.files;
            pathToPropValue = pathToPropertyValue({
                ids: action.payload,
                valuationState: state,
            });
            return state.setIn([...pathToPropValue, "server"], files).setIn([...pathToPropValue, "client"], files);

        case ReduxActions.VALUATION_PROPERTY_VALUE_PUT().type:
            return state.setIn(
                [
                    ...pathToPropertyValue({
                        ids: action.payload,
                        valuationState: state,
                    }),
                    "updating",
                ],
                true
            );

        case ReduxActions.VALUATION_PROPERTY_VALUES_RECEIVED().type:
            return action.payload.value.reduce((nextState, receivedValue) => {
                const pathToProperty = pathToPropertyValue({
                    ids: {
                        ...action.payload,
                        propertyId: receivedValue.propertyDefinition.id,
                    },
                    valuationState: state,
                });

                return nextState.updateIn(pathToProperty, (valueObj) => {
                    const newValueObject = {
                        client: valueObj.get("server") === null ? receivedValue.value : valueObj.get("client"),
                        error: null,
                        server: receivedValue.value,
                        updating: false,
                    };
                    return ImmutableMap(newValueObject);
                });
            }, state);

        case ReduxActions.VALUATION_PROPERTY_VALUES_RECEIVED_ERROR().type:
            return state.updateIn(
                pathToPropertyValue({
                    ids: action.payload,
                    valuationState: state,
                }),
                (valueObj) => valueObj.set("error", action.payload.error).set("updating", false)
            );

        case ReduxActions.VALUATION_END_IMPORT().type:
            // _OLD_TODO set import field to updating: false
            return state.updateIn(
                pathToPropertyValue({
                    ids: action.payload,
                    valuationState: state,
                }),
                (valueObj) => valueObj.set("updating", false)
            );

        case ReduxActions.VALUATION_RETRIEVE_GENERATED_REPORTS_RESULT().type:
            return state.setIn(
                [action.payload.valuationId, "generatedReports"],
                fromJS(action.payload.generatedReports)
            );

        case ReduxActions.VALUATION_RETRIEVE_REPORT_PREVIEWS_RESULT().type:
            return state.setIn([action.payload.valuationId, "reportPreviews"], fromJS(action.payload.reportPreviews));

        case ReduxActions.VALUATION_RETRIEVE_TRANSACTIONS_RESULT().type:
            return state.setIn([action.payload.valuationId, "transactions"], fromJS(action.payload.transactions));

        case ReduxActions.VALUATION_DELETE_TRANSACTION_RESULT().type:
            const filteredTransactions = state
                .getIn([action.payload.valuationId, "transactions"])
                .filter((transaction) => transaction.get("id") !== action.payload.transactionId);
            return state.setIn([action.payload.valuationId, "transactions"], filteredTransactions);

        case ReduxActions.VALUATION_RETRIEVE_CLIENTS_RESULT().type:
            return state.setIn([action.payload.valuationId, "clients"], fromJS(action.payload.clients));

        case ReduxActions.VALUATION_RETRIEVE_SINGLE_CLIENT_RESULT().type:
            return state.setIn([action.payload.valuationId, "client"], fromJS(action.payload.client));

        case ReduxActions.VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA_RESULT().type:
            return state.setIn(
                [action.payload.valuationId, "clientsStatusLegenda"],
                fromJS(action.payload.clientsStatusLegenda)
            );

        case ReduxActions.VALUATION_RETRIEVE_PORTFOLIOS_RESULT().type:
            return state.setIn([action.payload.valuationId, "portfolios"], fromJS(action.payload.portfolios));

        case ReduxActions.VALUATION_RETRIEVE_TRANSITIONS_RESULT().type:
            return state.setIn([action.payload.valuationId, "transitions"], fromJS(action.payload.transitions));

        case ReduxActions.VALUATION_CLEAR_TRANSITIONS().type:
            return state.setIn([action.payload.valuationId, "transitions"], null);

        case ReduxActions.VALUATION_RETRIEVE_PREDEFINED_TEXTS_RESULT().type:
            return state.setIn([action.payload.valuationId, "predefinedTexts"], fromJS(action.payload.predefinedTexts));

        case ReduxActions.VALUATION_UPDATE_PREDEFINED_TEXTS_RESULT().type:
            predefinedTexts = action.payload.predefinedTexts;
            const propertyId = action.payload.propertyId;

            const clearedTriggered = state
                .getIn([action.payload.valuationId, "predefinedTexts", "triggered"])
                .filter((item) => item.get("triggerProperty") !== propertyId); // Remove all with triggerProperty equal to the propertyId

            if (predefinedTexts && predefinedTexts.triggered) {
                // Add all triggered
                const newTriggered = clearedTriggered.mergeDeep(predefinedTexts.triggered);
                return state.setIn([action.payload.valuationId, "predefinedTexts", "triggered"], newTriggered);
            } else {
                // Only remove the ones
                return state.setIn([action.payload.valuationId, "predefinedTexts", "triggered"], clearedTriggered);
            }

        case ReduxActions.VALUATION_OBJECT_VALUE_UPDATE_RESULT().type:
            const pathToObjectProperty = [
                action.payload.valuationId,
                "objects",
                action.payload.objectId,
                "properties",
                action.payload.objectCategory,
                "properties",
            ];
            const indexOfUpdatedProperty = state
                .getIn(pathToObjectProperty)
                .findIndex((item) => item.getIn(["propertyDefinition", "id"]) === action.payload.propertyId);

            const updatedValue = state
                .getIn(pathToObjectProperty)
                .get(indexOfUpdatedProperty)
                .set("value", action.payload.value);

            const updatedObjectProperty = state.setIn(
                pathToObjectProperty.concat(indexOfUpdatedProperty),
                updatedValue
            );

            return updatedObjectProperty;

        case ReduxActions.VALUATION_QUEUE_UPDATE_RESULT().type:
            if (action.payload.isObject) {
                pathToProperty = [
                    action.payload.valuationId,
                    "objects",
                    action.payload.objectId,
                    "properties",
                    action.payload.cat,
                    "properties",
                ];

                let objectCategoryProperties = state.getIn([action.payload.valuationId, "objects"]).toJS();
                objectCategoryProperties = objectCategoryProperties.find(
                    (object) => object.id === action.payload.objectId
                );
                objectCategoryProperties = objectCategoryProperties.properties[action.payload.cat].properties;
                objectCategoryProperties = fromJS(objectCategoryProperties);

                if (objectCategoryProperties) {
                    const indexOfUpdatedProperty = objectCategoryProperties.findIndex(
                        (item) => item.getIn(["propertyDefinition", "id"]) === action.payload.id
                    );

                    return state.setIn(
                        pathToProperty.concat(indexOfUpdatedProperty).concat("value"),
                        action.payload.value
                    );
                }
            } else {
                pathToProperty = [
                    action.payload.valuationId,
                    "properties",
                    action.payload.cat,
                    "sub_category",
                    action.payload.subCat,
                    "properties",
                    action.payload.id,
                    "value",
                ];
                return state.setIn(pathToProperty, action.payload.value);
            }
            break;
        case ReduxActions.VALUATION_QUEUE_UPDATE_PENDING().type:
            if (action.payload.isObject) {
                const allObjects = state.getIn([action.payload.valuationId, "objects"]);
                const indexOfObject =
                    allObjects && allObjects.findIndex((object) => objectFindCompare(action.payload.objectId, object));

                pathToProperty = [
                    action.payload.valuationId,
                    "objects",
                    indexOfObject,
                    "properties",
                    action.payload.cat,
                    "properties",
                ];

                if (state.getIn(pathToProperty)) {
                    const indexOfUpdatedProperty = state
                        .getIn(pathToProperty)
                        .findIndex((item) => item.getIn(["propertyDefinition", "id"]) === action.payload.id);
                    return state.setIn(
                        pathToProperty.concat(indexOfUpdatedProperty).concat("value"),
                        action.payload.value
                    );
                }
            } else {
                pathToProperty = [
                    action.payload.valuationId,
                    "properties",
                    action.payload.cat,
                    "sub_category",
                    action.payload.subCat,
                    "properties",
                    action.payload.id,
                    "value",
                ];
                return state.setIn(pathToProperty, action.payload.value);
            }
            break;
        case ReduxActions.VALUATION_QUEUE_SET_UPDATING().type:
            return state.setIn(["queue", "updating"], action.payload.newUpdateState);

        case ReduxActions.VALUATION_QUEUE_UPDATE_APPEND().type:
            let queueEntries = state && state.getIn(["queue", "queueEntries"]);
            if (queueEntries) {
                const newQueue = [...queueEntries];
                const filteredQueue = newQueue.filter((entry) => {
                    return entry.get("propertyId") !== action.payload.queueObject.propertyId;
                });
                filteredQueue.push(action.payload.queueObject);
                return state.setIn(["queue", "queueEntries"], fromJS(filteredQueue));
            }
            return state.setIn(["queue", "queueEntries"], fromJS([action.payload.queueObject]));

        case ReduxActions.VALUATION_QUEUE_PROCESS_UPDATE_APPEND().type:
            const queueEntriesRef = state && state.getIn(["queue", "queueEntries"]);
            if (queueEntriesRef) {
                //remove queue entry from the queue and set the entry to the queue Processing
                const newQueue = [...queueEntriesRef];
                const filteredQueue = newQueue.filter((entry) => {
                    return entry.get("propertyId") !== action.payload.queueObject.get("propertyId");
                });
                return state
                    .setIn(["queue", "queueEntries"], fromJS(filteredQueue))
                    .setIn(["queue", "queueProcessing"], fromJS(action.payload.queueObject));
            }
            //if no queueEntries can be found (should not happen, set it to the processing anyway.
            return state.setIn(["queue", "queueProcessing"], fromJS(action.payload.queueObject));

        case ReduxActions.VALUATION_QUEUE_UPDATE_CLEAR().type:
            return state.setIn(["queue", "updating"], false).setIn(["queue", "error"], action.payload.error);

        case ReduxActions.VALUATION_QUEUE_PROCESS_UPDATE_CLEAR().type:
            return state.setIn(["queue", "queueProcessing"], null);

        case ReduxActions.VALUATION_SET_SELECTED().type:
            return state.set("currentSelected", action.payload.selectedValuations);

        case ReduxActions.VALUATION_EXPORTED_FLAG().type:
            return state.set("valuationsExported", action.payload.valuationsExported);

        case ReduxActions.VALUATION_END_GENERATED_REPORTS().type:
            return state.set("generatedReportsDownloaded", action.payload.flag);

        default:
            return state;
    }
}

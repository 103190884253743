import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Add extends Expression {
    calculate() {
        let result = 0;

        this.items.forEach((item) => {
            result += parseFloat(item.calculate());
        });

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default Add;

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Sum extends Expression {
    calculate() {
        let result = 0;

        this.items.forEach((item) => {
            let itemResult = item.calculate();

            if (Array.isArray(itemResult)) {
                result += itemResult.reduce((a, b) => parseFloat(a) + (b ? parseFloat(b) : 0), 0);
            } else {
                result += itemResult;
            }
        });

        return result;
    }

    _setItems() {
        if (this.expression?.argument) {
            this.add(BuildExpression.getClass(this.data, this.expression?.argument, this.index, this.tableIndex));
        }
    }
}

export default Sum;

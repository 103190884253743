import { fromJS, Map as ImmutableMap } from "immutable";
import * as ReduxActions from "../actions/action-valuation-layout";
import transformObjects from "./transformObjects";
import { ERROR_SET } from "../actions/action-errors";
import FileDownload from "js-file-download";
import { getType, ucFirst } from "@kateinnovations/javascript-helpers";
import getEnvValue from "helpers/getEnvValue";
import { toastr } from "react-redux-toastr";
import { setAuthError } from "features/authentication/authenticationSlice";

const createValueObject = (serverValue) =>
    ImmutableMap({
        client: serverValue,
        server: serverValue,
        updating: false,
        error: null,
    });

const formatAndSplitNestedCategories = ({ categories = {}, propertyDefinitions } = {}) => {
    if (getType(propertyDefinitions) === "undefined") {
        propertyDefinitions = {};
    }

    const result = Object.entries(categories).reduce((categories, [categoryName, category]) => {
        category.sub_category = Object.entries(category.sub_category).reduce(
            (subCategories, [subCategoryName, subCategory]) => {
                subCategory.properties = subCategory.properties.reduce((formattedProperties, property) => {
                    if ("propertyDefinition" in property) {
                        if ("id" in property === false) {
                            property.id = property.propertyDefinition.id;
                        }
                        propertyDefinitions[property.id] = {
                            ...property.propertyDefinition,
                            category: categoryName,
                            subCategory: subCategoryName,
                        };
                        delete property.propertyDefinition;
                    }
                    if ("value" in property) {
                        property.value = createValueObject(property.value);
                    }
                    formattedProperties[property.id] = property;
                    return formattedProperties;
                }, {});
                subCategories[subCategoryName] = subCategory;
                return subCategories;
            },
            {}
        );
        categories[categoryName] = category;
        return categories;
    }, {});
    return result;
};

const middlewareValuation = (store) => (next) => (action) => {
    const base = getEnvValue("KATE_CORE_URL");

    let url, valuationId, fileName, fileNames, objectId, propertyId, dataUri, callback;

    switch (action.type) {
        case ReduxActions.VALUATION_RETRIEVE().type:
            valuationId = action.payload.valuationId;
            const refreshValuation = action.payload.refreshValuation;

            const valuationExists = store.getState().valuation.getIn([valuationId]);

            store.dispatch(ReduxActions.VALUATION_CLEAR_DATA(valuationId));

            if (!valuationExists || refreshValuation) {
                url = `${base}/api/v1/valuations/${valuationId}?withValuationProperties=false&withObjects=false&withTransactions=false`;
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_RESULT(valuationId, result));
                    }, this)
                    .then(() => {
                        const propertiesExists = store.getState().valuation.getIn([valuationId, "properties"]);
                        if (!propertiesExists || refreshValuation) {
                            store.dispatch(ReduxActions.VALUATION_RETRIEVE_PROPERTIES(valuationId));
                        }

                        const predefinedTextsExists = store
                            .getState()
                            .valuation.getIn([valuationId, "predefinedTexts"]);
                        if (!predefinedTextsExists || refreshValuation) {
                            store.dispatch(ReduxActions.VALUATION_RETRIEVE_PREDEFINED_TEXTS(valuationId));
                        }

                        if (refreshValuation) {
                            store.dispatch(ReduxActions.VALUATION_RETRIEVE_OBJECTS(valuationId));
                        }
                    })
                    .catch((error) => {
                        store.dispatch(
                            ERROR_SET(
                                {
                                    data: error.response.data.error[0],
                                    redirect: window.config.path.react.error(error.response.data.http_status_code),
                                },
                                error.response.data.http_status_code
                            )
                        );
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_PROPERTIES().type:
            valuationId = action.payload.valuationId;
            url = `${base}/api/v1/valuations/${valuationId}/properties?withHidden=false&withEnabled=true&withDisabled=true&withNestedCategories=true&withValues=true`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_PROPERTY_DEFINITION_CONFIG_ANALYSIS());
                    const propertyDefinitions = {};
                    const valuationProperties = formatAndSplitNestedCategories({
                        categories: result,
                        propertyDefinitions,
                    });

                    store.dispatch(
                        ReduxActions.VALUATION_RETRIEVE_PROPERTIES_RESULT({
                            valuationId,
                            valuationProperties,
                            propertyDefinitions,
                        })
                    );
                })
                .catch((error) => {
                    if (error?.response?.data?.error) {
                        store.dispatch(ERROR_SET(error.response.data.error[0], "default"));
                        toastr.error(error.response.data.error[0]);
                    }
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_OBJECTS().type:
            valuationId = action.payload.valuationId;
            url = `${base}/api/v1/valuations/${valuationId}/objects?withProperties=false`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    const objects = transformObjects(result);
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_OBJECTS_RESULT(valuationId, objects));
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_OBJECT_PROPERTIES().type:
            valuationId = action.payload.valuationId;
            objectId = action.payload.objectId;
            url = `${base}/api/v1/valuations/${valuationId}/objects/${objectId}/properties?withNestedCategories=true&withValues=true&withPropertyDefinitions=true`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(
                        ReduxActions.VALUATION_RETRIEVE_OBJECT_PROPERTIES_RESULT(valuationId, objectId, result)
                    );
                });

            next(action);
            break;

        case ReduxActions.VALUATION_DELETE_OBJECT().type:
            valuationId = action.payload.valuationId;
            objectId = action.payload.objectId;

            url = `${base}/api/v1/valuations/${valuationId}/objects/${objectId}`;
            window.API.getClient()
                .delete(url)
                .then(({ data: result }) => {
                    action.payload.onSuccess();
                    store.dispatch(ReduxActions.VALUATION_DELETE_OBJECT_RESULT(valuationId, objectId));
                })
                .catch((error) => {
                    action.payload.onError(error.message);
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });
            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_HISTORY().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/history`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: history } }) => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_HISTORY_RESULT(valuationId, history));
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_APPROVALS().type:
            valuationId = action.payload.valuationId;

            const approvalsExists = store.getState().valuation.getIn([valuationId, "approvals"]);
            if (!approvalsExists) {
                url = `${base}/api/v1/valuations/${valuationId}/approvals`;

                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: approvals } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_APPROVALS_RESULT(valuationId, approvals));
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_COI_ENTRIES().type:
            valuationId = action.payload.valuationId;

            const coiEntriesExists = store.getState().valuation.getIn([valuationId, "coiEntries"]);
            if (!coiEntriesExists) {
                url = `${base}/api/v1/valuations/${valuationId}/coientries`;

                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: coiEntries } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_COI_ENTRIES_RESULT(valuationId, coiEntries));
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_UPLOAD_FILE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            const fileDataUri = action.payload.dataUri;

            url = `${base}/api/v1/valuations/${valuationId}/properties/${propertyId}`;

            window.API.getClient()
                .put(url, { value: fileDataUri })
                .then(() => {
                    store.dispatch(
                        ReduxActions.VALUATION_GET_FILES({
                            valuationId,
                            propertyId,
                            onSuccess: action.payload.onSuccess,
                            onError: action.payload.onError,
                        })
                    );
                })
                .catch((error) => {
                    action.payload.onError();
                    if (error.request.status === 400) {
                        store.dispatch(ERROR_SET(error.response, "default"));

                        toastr.error(error.response.data.error[0]);
                    }
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_GET_FILES().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}`;

            window.API.getClient()
                .get(url)
                .then(
                    ({
                        data: {
                            data: { images: files },
                        },
                    }) => {
                        action.payload.onSuccess();

                        store.dispatch(
                            ReduxActions.VALUATION_GET_FILES_RESULT({
                                valuationId,
                                propertyId,
                                files,
                                onError: action.payload.onError,
                            })
                        );
                    }
                )
                .catch((error) => {
                    action.payload.onError();
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            break;

        case ReduxActions.VALUATION_REMOVE_FILE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            fileName = action.payload.fileName;
            url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}?size=1&file_1=${fileName}`;

            window.API.getClient()
                .delete(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_REMOVE_FILE_RESULT(valuationId, propertyId, fileName));
                    action.payload.onSuccess(result);
                })
                .catch((error) => {
                    action.payload.onError(error.message);
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_GENERATED_REPORTS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/generated-reports`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_GENERATED_REPORTS_RESULT(valuationId, result));
                })
                .catch((error) => {
                    action.payload.onError(error.message);
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_REPORT_PREVIEWS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/report-previews`;

            const reportPreviewsExists = store.getState().valuation.getIn([valuationId, "reportPreviews"]);

            if (!reportPreviewsExists) {
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_REPORT_PREVIEWS_RESULT(valuationId, result));
                    })
                    .catch((error) => {
                        store.dispatch(ERROR_SET(error.response, "default"));
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_TRANSACTIONS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/transactions`;

            const transactionsExists = store.getState().valuation.getIn([valuationId, "transactions"]);
            if (!transactionsExists) {
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_TRANSACTIONS_RESULT(valuationId, result));
                    })
                    .catch((error) => {
                        action.payload.onError(error.message);
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_PORTFOLIOS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/portfolios?isRecentUpdated=true`;

            const portfoliosExists = store.getState().valuation.getIn([valuationId, "portfolios"]);

            if (!portfoliosExists) {
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_PORTFOLIOS_RESULT(valuationId, result));
                    })
                    .catch((error) => {
                        action.payload.onError(error.message);
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_DELETE_TRANSACTION().type:
            valuationId = action.payload.valuationId;
            const transactionId = action.payload.transactionId;

            url = `${base}/api/v1/valuations/${valuationId}/transactions/${transactionId}`;

            window.API.getClient()
                .delete(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_DELETE_TRANSACTION_RESULT(valuationId, transactionId));
                })
                .catch((error) => {
                    action.payload.onError(error.message);
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_UPDATE_PORTFOLIO().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/portfolio`;

            window.API.getClient()
                .put(url, {
                    value: action.payload.portfolioId,
                })
                .then(({ data: { data: result } }) => {
                    const newPortfolio = result.portfolio;
                    const currentValuation = store.getState().valuation.get(valuationId).toJS();
                    const updatedValuation = { ...currentValuation, portfolio: { ...newPortfolio } };

                    const leadValuationId =
                        result && "properties" in result && result.properties[0].propertyDefinition.id;
                    const leadValuationValue =
                        result && "properties" in result && result.properties && result.properties[0].value;

                    if (leadValuationValue) {
                        updatedValuation.properties.instruction.sub_category.portfolio.properties[
                            leadValuationId
                        ].value.client = leadValuationValue;
                        updatedValuation.properties.instruction.sub_category.portfolio.properties[
                            leadValuationId
                        ].value.server = leadValuationValue;
                    }

                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_RESULT(valuationId, updatedValuation));
                })
                .catch((error) => {
                    action.payload.onError(error.message);
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_CLIENTS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/clients`;

            const clientsExists = store.getState().valuation.getIn([valuationId, "clients"]);

            if (!clientsExists) {
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_CLIENTS_RESULT(valuationId, result));
                    })
                    .catch((error) => {
                        action.payload.onError(error.message);
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_UPDATE_CLIENT().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/clients`;

            window.API.getClient()
                .put(url, {
                    value: action.payload.clientId,
                })
                .then(({ data: { data: result } }) => {})
                .catch((error) => {
                    if (error.response.data.error) {
                        store.dispatch(ERROR_SET(error.response.data?.error[0], "default"));
                        toastr.error(error.response.data?.error[0]);
                    }
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/clients/status-legenda`;

            const clientsStatusExists = store.getState().valuation.getIn([valuationId, "clientsStatusLegenda"]);

            if (!clientsStatusExists) {
                window.API.getClient()
                    .get(url)
                    .then(({ data: { data: result } }) => {
                        store.dispatch(
                            ReduxActions.VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA_RESULT(valuationId, result)
                        );
                    })
                    .catch((error) => {
                        action.payload.onError(error.message);
                        if (error.request.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                        }
                    });
            }

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_SINGLE_CLIENT().type:
            let clientId = action.payload.clientId;
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/clients/${clientId}`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_SINGLE_CLIENT_RESULT(valuationId, result));
                })
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_TRANSITIONS().type:
            valuationId = action.payload.valuationId;
            url = `${base}/api/v1/valuations/${valuationId}/allowed-transitions`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    action.payload.onSuccess && action.payload.onSuccess();
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_TRANSITIONS_RESULT(valuationId, result));
                    store.dispatch(
                        ReduxActions.VALUATION_RETRIEVE_REPORT_PREVIEWS(valuationId, action.payload.onError)
                    );
                })
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_DO_TRANSITION().type:
            valuationId = action.payload.valuationId;
            url = `${base}/api/v1/valuations/${valuationId}/transitions`;

            const comment = action.payload.comment;
            const transitionId = action.payload.transitionId;

            window.API.getClient()
                .post(url, {
                    comment,
                    transitionId,
                })
                .then(({ data: { data: result } }) => {
                    action.payload.callBack(result);

                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);

                    toastr.success("", "Status change of valuation is successfully. Page will be reloaded", {
                        timeOut: 0,
                    });
                })
                .catch((error) => {
                    action.payload.callBack("error");

                    const errorTitle = error.response?.data?.error?.notice || "";
                    const errorAsArray = error.response?.data?.error?.error || [];
                    const ErrorMessage = () => {
                        return (
                            <>
                                {!!errorTitle && <div className="title">{errorTitle}</div>}
                                {errorAsArray.length && (
                                    <ul className="list-container">
                                        {errorAsArray.map((message, index) => (
                                            <li key={index}>{message}</li>
                                        ))}
                                    </ul>
                                )}
                            </>
                        );
                    };

                    const toastrOptions = {
                        timeOut: 0,
                        showCloseButton: true,
                        closeOnToastrClick: false,
                        removeOnHover: false,
                        icon: <i className="fas fa-exclamation-circle" />,
                        component: <ErrorMessage />,
                    };

                    toastr.error("", "", toastrOptions);

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_PREDEFINED_TEXTS().type:
            valuationId = action.payload.valuationId;

            url = `${base}/api/v1/valuations/${valuationId}/predefined-texts-and-triggers`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_PREDEFINED_TEXTS_RESULT(valuationId, result));
                })
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_UPDATE_PREDEFINED_TEXTS().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;

            url = `${base}/api/v1/valuations/${valuationId}/predefined-texts/${propertyId}`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(
                        ReduxActions.VALUATION_UPDATE_PREDEFINED_TEXTS_RESULT(valuationId, propertyId, result)
                    );
                })
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_OBJECT_VALUE_UPDATE().type:
            valuationId = action.payload.valuationId;
            objectId = action.payload.objectId;
            propertyId = action.payload.propertyId;
            const value = action.payload.value;
            const objectCategory = action.payload.objectCategory;

            url = `${base}/api/v1/valuations/${valuationId}/objects/${objectId}/properties/${propertyId}`;

            window.API.getClient()
                .put(url, { value })
                .then(() => {
                    store.dispatch(
                        ReduxActions.VALUATION_OBJECT_VALUE_UPDATE_RESULT(
                            valuationId,
                            objectId,
                            propertyId,
                            objectCategory,
                            value
                        )
                    );
                })
                .catch((error) => {
                    store.dispatch(ERROR_SET(error.response.data.error[0], "default"));
                    toastr.error(error.response.data.error[0]);

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_SWAP_PHOTO().type:
            valuationId = action.payload.valuationId;
            fileName = action.payload.fileName;
            callback = action.payload.callback;

            url = `${base}/api/v1/valuations/${valuationId}/files-swap/${fileName}/from/${action.payload.propertyIdFrom}/to/${action.payload.propertyIdTo}`;

            window.API.getClient()
                .put(url)
                .then(() => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_IMAGES(valuationId, action.payload.propertyIdFrom));
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_IMAGES(valuationId, action.payload.propertyIdTo));
                    callback();
                })
                .catch((error) => {
                    store.dispatch(ERROR_SET(error.response.data.error[0], "default"));
                    toastr.error(error.response.data.error[0]);

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_UPLOAD_IMAGE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            dataUri = action.payload.dataUri;
            callback = action.payload.callback;

            url = `${base}/api/v1/valuations/${valuationId}/properties/${propertyId}`;

            window.API.getClient()
                .put(url, { value: dataUri })
                .then(() => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_IMAGES(valuationId, propertyId));
                    callback();
                })
                .catch((error) => {
                    callback(false);
                    if (error.request.status === 400) {
                        store.dispatch(ERROR_SET(error.response, "default"));
                        toastr.error(error.response.data.error[0]);
                    }

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_DELETE_IMAGE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            fileNames = action.payload.fileNames;

            const isSingle = typeof fileNames === "string";
            const size = fileNames.length;

            const getFileNames = () => {
                let serializedQueryParams = "";
                for (let i = 0; i < fileNames.length; i += 1) {
                    serializedQueryParams += `&file_${i}=${fileNames[i]}`;
                }
                return serializedQueryParams;
            };

            const queryParams = getFileNames();

            if (isSingle) {
                url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}?size=1&file_0=${fileNames}`;
            } else {
                url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}?size=${size}${queryParams}`;
            }
            window.API.getClient()
                .delete(url)
                .then((data) => {
                    store.dispatch(ReduxActions.VALUATION_DELETE_IMAGE_RESULT(valuationId, propertyId, fileNames));
                })
                .catch((error) => {
                    store.dispatch(ERROR_SET(error.response, "default"));

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });
            next(action);
            break;

        case ReduxActions.VALUATION_RETRIEVE_IMAGES().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;

            url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}`;

            window.API.getClient()
                .get(url)
                .then(
                    ({
                        data: {
                            data: { images },
                        },
                    }) => {
                        store.dispatch(ReduxActions.VALUATION_RETRIEVE_IMAGES_RESULT(valuationId, propertyId, images));
                    }
                );

            next(action);
            break;

        case ReduxActions.VALUATION_TRANSACTION_TABLE_UPDATE_ORDER().type:
            valuationId = action.payload.valuationId;
            url = `${base}/api/v1/valuations/${valuationId}/order-transactions/${action.payload.oldIndex + 1}/${
                action.payload.newIndex + 1
            }`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data } }) => {})
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_ROTATE_IMAGE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            fileName = action.payload.fileName;

            const directions = {
                left: "rotateleft",
                right: "rotateright",
            };

            url = `${base}/api/v1/valuations/${valuationId}/${directions[action.payload.direction]}/files/${fileName}.${
                action.payload.extension
            }`;

            window.API.getClient()
                .post(url)
                .then(() => {
                    store.dispatch(ReduxActions.VALUATION_RETRIEVE_IMAGES(valuationId, propertyId));
                });

            next(action);
            break;

        case ReduxActions.VALUATION_REORDER_IMAGES().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;
            const newSetOfImages = action.payload.images;
            const from = action.payload.from;
            const to = action.payload.to;

            url = `${base}/api/v1/valuations/${valuationId}/files/${propertyId}/order/${from}/${to}`;

            window.API.getClient()
                .post(url)
                .then(() => {
                    store.dispatch(
                        ReduxActions.VALUATION_REORDER_IMAGES_RESULT(valuationId, propertyId, newSetOfImages)
                    );
                })
                .catch((error) => {
                    if (error.request && error.request.status === 500) {
                        store.dispatch(ERROR_SET(error.response, "default"));
                    }

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_QUEUE_UPDATE().type:
            valuationId = action.payload.valuationId;
            propertyId = action.payload.propertyId;

            const queueObject = {
                valuationId: valuationId,
                propertyId: propertyId,
                newValue: action.payload.newValue,
                isObject: action.payload.isObject,
                objectId: action.payload.objectId,
                category: action.payload.category,
                categorySub: action.payload.subCat,
            };

            const pendingValueMap = fromJS({
                client: action.payload.newValue,
                server: action.payload.newValue,
                updating: true,
                error: null,
            });

            store.dispatch(
                ReduxActions.VALUATION_QUEUE_UPDATE_PENDING({
                    isObject: queueObject.isObject,
                    valuationId: valuationId,
                    cat: action.payload.category,
                    subCat: action.payload.subCat,
                    value: pendingValueMap,
                    objectId: queueObject.objectId,
                    id: propertyId,
                })
            );

            store.dispatch(ReduxActions.VALUATION_QUEUE_UPDATE_APPEND({ propertyId, queueObject }));

            next(action);
            break;

        case ReduxActions.VALUATION_QUEUE_HANDLE_ENTRY().type:
            store.dispatch(ReduxActions.VALUATION_QUEUE_SET_UPDATING(true));
            store.dispatch(ReduxActions.VALUATION_QUEUE_PROCESS_UPDATE_CLEAR());

            url = `${base}/api/v1/valuations/${action.payload.queueObject.valuationId}/properties/${action.payload.queueObject.propertyId}`;

            if (action.payload.queueObject.isObject) {
                url = `${base}/api/v1/valuations/${action.payload.queueObject.valuationId}/objects/${action.payload.queueObject.objectId}/properties/${action.payload.queueObject.propertyId}?withPropertyDefinition=true&withCategories=true`;
            }

            window.API.getClient()
                .put(url, { value: action.payload.queueObject.newValue })
                .then(({ data: { data } }) => {
                    const value = action.payload.queueObject.newValue;
                    const newValueMap = fromJS({
                        client: value,
                        server: value,
                        updating: false,
                        error: null,
                    });

                    store.dispatch(
                        ReduxActions.VALUATION_QUEUE_UPDATE_RESULT({
                            isObject: action.payload.queueObject.isObject,
                            valuationId: action.payload.queueObject.valuationId,
                            cat: action.payload.queueObject.category,
                            subCat: action.payload.queueObject.categorySub,
                            value: newValueMap,
                            objectId: action.payload.queueObject.objectId,
                            id: action.payload.queueObject.propertyId,
                        })
                    );
                    const triggerList = store
                        .getState()
                        .valuation.getIn([action.payload.queueObject.valuationId, "predefinedTexts", "triggers"]);

                    if (triggerList?.includes(action.payload.queueObject.propertyId)) {
                        store.dispatch(
                            ReduxActions.VALUATION_UPDATE_PREDEFINED_TEXTS(
                                action.payload.queueObject.valuationId,
                                action.payload.queueObject.propertyId
                            )
                        );
                    }

                    if (getType(data) === "object") {
                        Object.keys(data).forEach((valuationChange) => {
                            let newValuationData;
                            if (["portfolio", "daysRemaining", "address"].includes(valuationChange)) {
                                newValuationData = data[valuationChange];

                                const currentValuation = store
                                    .getState()
                                    .valuation.get(action.payload.queueObject.valuationId)
                                    .toJS();
                                const updatedValuation = {
                                    ...currentValuation,
                                    [valuationChange]: { ...newValuationData },
                                };

                                store.dispatch(
                                    ReduxActions.VALUATION_RETRIEVE_RESULT(
                                        action.payload.queueObject.valuationId,
                                        updatedValuation
                                    )
                                );
                            }
                            if (valuationChange === "properties") {
                                const newValuationData = {};
                                data.properties.forEach((property) => {
                                    newValuationData[property.propertyDefinition.id] = {
                                        ...property.propertyDefinition,
                                    };
                                });

                                const currentPropertyDefinitions = store
                                    .getState()
                                    .valuation.get("propertyDefinitions")
                                    .toJS();

                                const updatedPropertyDefinitions = {
                                    ...currentPropertyDefinitions,
                                    ...newValuationData,
                                };

                                store.dispatch(
                                    ReduxActions.VALUATION_RETRIEVE_PROPERTIES_RESULT({
                                        valuationId: action.payload.queueObject.valuationId,
                                        valuationProperties: store
                                            .getState()
                                            .valuation.getIn([action.payload.queueObject.valuationId, "properties"]),
                                        propertyDefinitions: updatedPropertyDefinitions,
                                    })
                                );
                            }
                        });
                    }

                    store.dispatch(ReduxActions.VALUATION_QUEUE_UPDATE_CLEAR());

                    const reload =
                        store
                            .getState()
                            .valuation.getIn([
                                "propertyDefinitions",
                                action.payload.queueObject.propertyId,
                                "propertyKey",
                            ]) === "valuation_valuation_import_value";

                    if (reload) window.location.reload();
                })
                .catch((error) => {
                    const newValueMapForError = fromJS({
                        client: action.payload.queueObject.newValue,
                        server: action.payload.queueObject.newValue,
                        updating: false,
                        error: error.response?.data?.error[0] || error.message,
                    });

                    store.dispatch(
                        ReduxActions.VALUATION_QUEUE_UPDATE_CLEAR({
                            error: getType(error) === "error",
                        })
                    );

                    if (getType(error) === "error" && !error.response) {
                        const messageError =
                            store.getState().translations?.entities["valuation.error.failedToFetch"] ||
                            "internet is disconnected";

                        store.dispatch(
                            ReduxActions.VALUATION_QUEUE_UPDATE_RESULT({
                                isObject: action.payload.queueObject.isObject,
                                valuationId: action.payload.queueObject.valuationId,
                                cat: action.payload.queueObject.isObject
                                    ? error.response.data[0]?.propertyDefinition.objectCategory
                                    : action.payload.queueObject.category,
                                subCat: action.payload.queueObject.categorySub,
                                value: newValueMapForError,
                                objectId: action.payload.queueObject.objectId,
                                id: action.payload.queueObject.propertyId,
                            })
                        );

                        toastr.error(ucFirst(messageError), "", { timeOut: 8000 });
                        return ERROR_SET(messageError, "default");
                    }

                    if (error.request.status === 401) {
                        const messageSessionExpired =
                            store.getState().translations?.entities["valuation.error.sessionExpired"] ||
                            "your session with KATE is expired";

                        toastr.error(ucFirst(messageSessionExpired), "", { timeOut: 8000 });
                        store.dispatch(setAuthError("authenticationError"));
                        return ERROR_SET(messageSessionExpired, "default");
                    }

                    if (error.request && [404, 429, 461, 500, 400].includes(error.request.status)) {
                        const value =
                            getType(error.response.data) === "array"
                                ? error.response.data.data[0].value
                                : error.response.data.data.value;
                        const newValueMap = fromJS({
                            client: value,
                            server: value,
                            updating: false,
                            error: error.response.data.error[0],
                        });

                        store.dispatch(
                            ReduxActions.VALUATION_QUEUE_UPDATE_RESULT({
                                isObject: action.payload.queueObject.isObject,
                                valuationId: action.payload.queueObject.valuationId,
                                cat: action.payload.queueObject.isObject
                                    ? error.response.data[0]?.propertyDefinition.objectCategory
                                    : action.payload.queueObject.category,
                                subCat: action.payload.queueObject.categorySub,
                                value: newValueMap,
                                objectId: action.payload.queueObject.objectId,
                                id: action.payload.queueObject.propertyId,
                            })
                        );

                        toastr.error(error.response.data.error[0], "", { timeOut: 8000 });
                        return ERROR_SET(error.response.data.error[0], "default");
                    }

                    store.dispatch(
                        ReduxActions.VALUATION_QUEUE_UPDATE_RESULT({
                            isObject: action.payload.queueObject.isObject,
                            valuationId: action.payload.queueObject.valuationId,
                            cat: action.payload.queueObject.isObject
                                ? error.response.data[0]?.propertyDefinition.objectCategory
                                : action.payload.queueObject.category,
                            subCat: action.payload.queueObject.categorySub,
                            value: newValueMapForError,
                            objectId: action.payload.queueObject.objectId,
                            id: action.payload.queueObject.propertyId,
                        })
                    );

                    toastr.error(error.response?.data?.error[0] || error.message, "", { timeOut: 8000 });
                    return ERROR_SET(error.response?.data?.error[0] || error.message, "default");
                });

            next(action);
            break;

        case ReduxActions.VALUATION_DOWNLOAD_XBRL().type:
            valuationId = action.payload.valuationId;
            url = `${window.config.getState().path.core.base}/api/v1/valuations/${valuationId}/xbrl/download`;

            window.API.getClient()
                .post(url)
                .then((response) => {
                    FileDownload(response.data, `valuation-${valuationId}.xbrl`);
                    action.payload.callBack();
                })
                .catch((error) => {
                    action.payload.callBack();
                    toastr.error(error.response.data.error[0]);
                    store.dispatch(ERROR_SET(error.response, "default"));

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_TRANSFER_XBRL().type:
            valuationId = action.payload.valuationId;
            url = `${window.config.getState().path.core.base}/api/v1/valuations/${valuationId}/xbrl/transfer`;

            window.API.getClient()
                .post(url)
                .then((response) => {
                    FileDownload(response.data, `valuation-${valuationId}.xbrl`);
                    action.payload.callBack();
                })
                .catch((error) => {
                    action.payload.callBack();
                    toastr.error(error.response.data.error[0]);
                    store.dispatch(ERROR_SET(error.response, "default"));

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_EXPORT().type:
            const valuationIds = action.payload.selectedValuations.map((item) => item.id);
            const requestObject = {
                valuationIds,
                valuationPropertyKeys: null,
            };

            url = `${base}/api/v1/valuations/export`;

            window.API.getClient()
                .post(url, requestObject)
                .then((data) => {
                    const { fileURL } = data.data.data;
                    window.open(base + fileURL, "_blank");
                    store.dispatch(ReduxActions.VALUATION_EXPORTED_FLAG());
                })
                .catch((error) => {
                    store.dispatch(ReduxActions.VALUATION_EXPORTED_FLAG());

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        case ReduxActions.VALUATION_GET_GENERATED_REPORTS().type:
            const params = {
                valuationIds: action.payload.queue,
            };

            window.API.getClient()
                .post(action.payload.api, params, {
                    responseType: action.payload.responseType,
                })
                .then((response) => {
                    if (
                        response &&
                        response.headers &&
                        response.headers["content-type"] &&
                        (response.headers["content-type"] === "application/octet-stream" ||
                            response.headers["content-type"] === "application/x-zip")
                    ) {
                        const fileName = window.location.href
                            .replace(window.location.origin, "")
                            .replace("/", "")
                            .replace(/\//, "-");
                        FileDownload(response.data, fileName + ".zip");
                    }
                    store.dispatch(ReduxActions.VALUATION_END_GENERATED_REPORTS(true));
                })
                .catch((error) => {
                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

            next(action);
            break;

        default:
            next(action);
            break;
    }
};

export default middlewareValuation;

import Component from "../Component";
import BuildExpression from "../BuildExpression";
import moment from "moment";

class ParseDate extends Component {
    constructor(data, expression, index, tableIndex) {
        super();

        this.data = data;
        this.expression = expression;
        this.index = index;
        this.tableIndex = tableIndex;
    }

    calculate() {
        if (this.expression?.argument) {
            const arg = BuildExpression.getClass(
                this.data,
                this.expression?.argument,
                this.index,
                this.tableIndex
            ).calculate();
            const momentArg = moment(arg);

            if (momentArg.isValid()) {
                return momentArg.format(this.expression.format);
            }
        }

        return "";
    }
}

export default ParseDate;

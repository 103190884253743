import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class If extends Expression {
    calculate() {
        if (this.items.length !== 3) {
            return null; // @todo or Error
        }

        return this.items[0].calculate() ? this.items[1].calculate() : this.items[2].calculate();
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default If;

import { PORTFOLIO_QUERY, PORTFOLIO_QUERY_RESULT } from "../actions/action-portfolio";
import getEnvValue from "helpers/getEnvValue";

const middlewarePortfolio = (store) => (next) => (action) => {
    switch (action.type) {
        case PORTFOLIO_QUERY().type:
            window.API.getClient()
                .get(`${getEnvValue("KATE_CORE_URL")}/api/v1/portfolios`, { params: { isRecentUpdated: true } })
                .then((response) => {
                    store.dispatch(PORTFOLIO_QUERY_RESULT(response));
                })
                .catch();
            break;

        default:
            break;
    }
    next(action);
};

export default middlewarePortfolio;

const initialState = {
    selectedImports: [],
    declinedImports: [],
    closedAccordionId: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    const newState = { ...state };
    switch (type) {
        case "SELECT_IMPORT_ENTRY":
            let newSelectImports = [...state.selectedImports];
            if (!state.selectedImports.includes(payload.importEntryId)) {
                newSelectImports.push(payload.importEntryId);
                newState.selectedImports = newSelectImports;
                return newState;
            }

            const index = newSelectImports.indexOf(payload.importEntryId);
            newSelectImports.splice(index, 1);
            newState.selectedImports = newSelectImports;
            return newState;

        case "ADD_DECLINED_IMPORT_ENTRY":
            let newDeclinedImports = [...state.declinedImports];
            newDeclinedImports.push(payload.importEntryId);
            newState.declinedImports = newDeclinedImports;
            return newState;

        default:
            return state;
    }
}

import { createSlice } from "@reduxjs/toolkit";
import v2TokenApi from "./v2TokenApi";

const initialState = {
    token: null,
    customerPartition: null,
    expiresAt: null,
};

const v2 = createSlice({
    name: "v2Reducer",
    initialState,
    reducers: {},
    extraReducers: {
        [v2TokenApi.fulfilled]: (state, action) => {
            const newState = state;
            newState.token = action.payload.token;
            newState.customerPartition = action.payload.customerPartition;
            newState.expiresAt = action.payload.expiresAt;
        },
    },
});

const v2Reducer = v2.reducer;
export { v2Reducer };

export const GET_TRANSACTION = ({ id } = {}) => ({
    type: "GET_TRANSACTION",
    payload: {
        id,
    },
});

export const GET_TRANSACTION_RESULT = ({ id, data } = {}) => ({
    type: "GET_TRANSACTION_RESULT",
    payload: {
        id,
        data,
    },
});

export const TRANSACTION_SET_SELECTED = (selectedTransactions) => ({
    type: "TRANSACTION_SET_SELECTED",
    payload: {
        selectedTransactions,
    },
});

export const TRANSACTION_EXPORT = (selectedTransactions) => ({
    type: "TRANSACTION_EXPORT",
    payload: {
        selectedTransactions,
    },
});

export const TRANSACTION_EXPORTED_FLAG = (transactionsExported = true) => ({
    type: "TRANSACTION_EXPORTED_FLAG",
    payload: {
        transactionsExported,
    },
});

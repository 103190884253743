import BuildExpression from "../BuildExpression";
import AreEmpty from "./AreEmpty";

class IsEmpty extends AreEmpty {
    _setItems() {
        if (this.expression?.argument) {
            this.add(BuildExpression.getClass(this.data, this.expression.argument, this.index, this.tableIndex));
        }
    }
}

export default IsEmpty;

import { apiCall, removeStorageItem } from "@kateinnovations/javascript-helpers";

const logoutExternalSource = async (logoutUrl, sourceName) => {
    await apiCall({
        url: logoutUrl,

        mode: "cors",
    });
    const actualSourceName = sourceName.startsWith("brainbay") ? "brainbay" : sourceName;
    removeStorageItem(`${actualSourceName}Token`);
};

export default logoutExternalSource;

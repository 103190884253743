import React, { useEffect, useState, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { animated } from "react-spring";
import loadable from "@loadable/component";
import {
    getValidationTypes,
    isElementValid,
    getType,
    capitalize,
    getRandomAlphanumericInsensitive,
} from "@kateinnovations/javascript-helpers";
import { useDebounce } from "@kateinnovations/react-hooks";

import styles from "./resources/styles/inputTypeText.module.scss";

const Icons = loadable(() => import(/* webpackChunkName: "Icons" */ `../Icons`));
const Button = loadable(() => import(/* webpackChunkName: "ApprovalsButton" */ `../Button`));

const buttonTypes = ["button", "reset", "submit"];

const currencyFormatter = new Intl.NumberFormat("nl", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}).format;

const numberFormatter = new Intl.NumberFormat("nl", {
    style: "decimal",
    maximumFractionDigits: 0,
}).format;

const formatValue = (value, valueFormat) => {
    let newValue = value;
    switch (valueFormat) {
        case "capitalize":
            newValue = capitalize(value);
            break;
        case "number":
            newValue = numberFormatter(value);
            break;
        case "percentage":
            newValue = `${parseFloat(value).toFixed(2)}%`;
            break;
        case "currency":
            newValue = currencyFormatter(value);
            break;
        default:
            break;
    }

    return newValue;
};

const InputTypeText = ({
    label,
    attributes,
    variant,
    clearValue,
    defaultValue,
    customEventHandler,
    valueFormat,
    resetButton,
    resetButtonTitle,
    size,
}) => {
    const inputElementRef = useRef();
    const elementAttributes = { ...InputTypeText.defaultProps.attributes, ...attributes };
    const { disabled, readOnly } = elementAttributes;
    const [randomAlphanumericInsensitive] = useState(getRandomAlphanumericInsensitive());
    const initState = disabled || readOnly || !elementAttributes["data-required"] ? "isValid" : "isEmpty";

    const [validationTypes] = useState(
        getValidationTypes(elementAttributes["data-required"], elementAttributes["data-validation-types"])
    );
    const [inputfieldValid, setInputfieldValid] = useState(initState);
    const [containerState, setContainerState] = useState(initState);
    const [inputState, setInputState] = useState();
    const [eventType, setEventType] = useState();
    const [previousEventType, setPreviousEventType] = useState();
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [inputElement, setInputElement] = useState();
    const [formattedValue, setFormattedValue] = useState();
    const [variantColor] = useState(variant.split("-").pop());
    const containerVariant = [variant];

    if (elementAttributes.disabled) containerVariant.push("disabled");
    if (elementAttributes.readOnly) containerVariant.push("read-only");
    if (size) containerVariant.push(size);
    if (buttonTypes.includes(elementAttributes.type)) containerVariant.push("no-placeholder");

    const debouncedCurrentValue = useDebounce(currentValue, 100);

    const inputEventHandler = (event) => {
        const element = event.target;
        const elementType = element.type;

        let elementValue = event.type === "blur" && elementType !== "password" ? element.value.trim() : element.value;
        let formattedValue = elementValue;

        if (valueFormat && ["text", "number"].includes(elementType)) {
            formattedValue = formatValue(elementValue, valueFormat);
        }

        if (customEventHandler) customEventHandler(elementValue, event);

        setEventType(event.type);
        setCurrentValue(valueFormat === "capitalize" ? formattedValue : elementValue);
    };

    const labelOnMouseDownHandler = (event) => event.preventDefault();

    const setToDefaultValue = useCallback(
        (reset = false) => {
            const elementState = disabled || readOnly ? "isValid" : isElementValid(validationTypes, defaultValue);
            const value = valueFormat ? formatValue(defaultValue, valueFormat) : defaultValue;

            if (valueFormat && valueFormat !== "capitalize") setFormattedValue(value);

            if (clearValue || reset) setCurrentValue("");
            setInputfieldValid(elementState);

            if (eventType === "blur") setContainerState(elementState);

            setInputState(elementState);
        },
        [disabled, readOnly, validationTypes, defaultValue, valueFormat, clearValue, eventType]
    );
    const resetHandler = (event) => {
        setToDefaultValue(true);
        if (customEventHandler) customEventHandler(defaultValue);
    };

    useEffect(() => {
        if (inputElementRef.current) setInputElement(inputElementRef.current);
    }, [inputElementRef]);

    useEffect(() => {
        if (debouncedCurrentValue || debouncedCurrentValue === "") {
            const elementState = disabled || readOnly ? "isValid" : isElementValid(validationTypes, currentValue);
            setInputfieldValid(elementState);
        }
    }, [debouncedCurrentValue, validationTypes, currentValue, disabled, readOnly]);

    useEffect(() => {
        if (eventType !== previousEventType) {
            const validationState = isElementValid(validationTypes, currentValue);
            const elementState =
                validationState === "isEmpty" && currentValue && currentValue.length ? "inValid" : validationState;
            setPreviousEventType(eventType);
            setInputState(validationState);

            switch (eventType) {
                case "focus":
                case "change":
                    setContainerState("isFocussed");
                    break;
                case "blur":
                default:
                    if (validationState === "isEmpty" && inputElement.type !== "password") {
                        inputElement.value = ".";
                        inputElement.value = "";
                    }

                    setContainerState(elementState);
            }
        }
    }, [eventType, previousEventType, validationTypes, currentValue, inputElement]);

    useEffect(() => {
        setInputState(inputfieldValid);
    }, [inputfieldValid]);

    useEffect(() => {
        if (clearValue || defaultValue || defaultValue === 0) setToDefaultValue();
    }, [clearValue, defaultValue, setToDefaultValue]);

    return (
        <div className={styles.container} state={containerState} variant={containerVariant.join(" ")}>
            {(("text" in label && getType(label.text) === "string") || label.icon || !!resetButton) && (
                <div className={styles.titleContainer}>
                    {(getType(label.text) === "string" || label.icon) && (
                        <animated.label
                            className={styles.label}
                            htmlFor={`${label.for}-${randomAlphanumericInsensitive}`}
                            onMouseDown={labelOnMouseDownHandler}
                        >
                            {label.icon && <Icons icon={label.icon} />}
                            {getType(label.text) === "string" && <div className={styles.text}>{label.text}</div>}
                        </animated.label>
                    )}

                    {resetButton && (
                        <Button
                            text={resetButtonTitle.toLowerCase()}
                            color="color-black"
                            onClickFunction={resetHandler}
                            textAlign="right"
                        />
                    )}
                </div>
            )}
            <div className={styles.inputContainer}>
                {(!!formattedValue || formattedValue === 0) && (
                    <output className={styles.output}>{formattedValue}</output>
                )}
                <animated.input
                    ref={inputElementRef}
                    id={`${label.for}-${randomAlphanumericInsensitive}`}
                    className={styles.input}
                    onChange={inputEventHandler}
                    onFocus={inputEventHandler}
                    onBlur={inputEventHandler}
                    {...elementAttributes}
                    state={inputState || containerState}
                    tabIndex={elementAttributes.disabled || elementAttributes.readOnly ? -1 : null}
                    value={
                        buttonTypes.includes(elementAttributes.type) && currentValue === "" ? undefined : currentValue
                    }
                />
            </div>
        </div>
    );
};

InputTypeText.defaultProps = {
    label: {
        for: undefined,
        icon: undefined,
        text: undefined,
    },
    attributes: {
        placeholder: undefined,
        type: "text",
        autoComplete: "off",
        autoFocus: false,
        "data-required": false,
        "data-validation-types": undefined,
        disabled: false,
        readOnly: false,
    },
    defaultValue: "",
    variant: "color-white",
    clearValue: false,
    customEventHandler: undefined,
    valueFormat: undefined,
    resetButton: false,
    size: "normal",
};

InputTypeText.propTypes = {
    label: PropTypes.shape({
        for: PropTypes.string,
        icon: PropTypes.string,
        text: PropTypes.string,
    }),
    attributes: PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        type: PropTypes.oneOf([
            "text",
            "password",
            "email",
            "number",
            "tel",
            "url",
            "date",
            "button",
            "submit",
            "reset",
        ]),
        autoComplete: PropTypes.string,
        autoFocus: PropTypes.bool,
        "data-required": PropTypes.bool,
        "data-validation-types": PropTypes.string,
        readOnly: PropTypes.bool,
        disabled: PropTypes.bool,
    }),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf(["color-white", "color-gray"]),
    clearValue: PropTypes.bool,
    customEventHandler: PropTypes.func,
    valueFormat: PropTypes.oneOf(["capitalize", "percentage", "currency", "number"]),
    resetButton: PropTypes.bool,
    size: PropTypes.oneOf(["small", "normal"]),
};

export default InputTypeText;

import Component from "../Component";
import BuildExpression from "../BuildExpression";

class IsNull extends Component {
    constructor(data, expression, index, tableIndex) {
        super();

        this.data = data;
        this.expression = expression;
        this.index = index;
        this.tableIndex = tableIndex;
    }

    calculate() {
        if (this.expression?.argument) {
            return (
                BuildExpression.getClass(
                    this.data,
                    this.expression?.argument,
                    this.index,
                    this.tableIndex
                ).calculate() == null
            );
        }

        return true;
    }
}

export default IsNull;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationPredefinedTextApi = createAsyncThunk(
    "valuationEdit/valuationPredefinedTextApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        if (
            valuationEdit.predefinedText.loading === false ||
            requestId !== valuationEdit.predefinedText.currentRequestId
        )
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.predefinedText.replace("{{valuationId}}", valuationId);

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            return response.data;
        }

        return rejectWithValue({ error });
    }
);

export default valuationPredefinedTextApi;

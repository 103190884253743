import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const sendErrorMail = (jwt, mailBody) => {
    // constants
    const mailUrl = defaultConfig.api.errorMail;

    // argument validation
    if (!jwt || !mailBody) {
        console.error("Missing required arguments for sendErrorMail");
        return false;
    }

    return apiCall({
        url: mailUrl,
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${jwt}`,
        },
        credentials: "omit",
        mode: "cors",
        body: mailBody,
    });
};

export default sendErrorMail;

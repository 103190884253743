import PropTypes from "prop-types";
import { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, parent, className }) => {
    const element = useMemo(() => document.createElement("div"), []);

    useEffect(() => {
        const target = parent && parent.appendChild ? parent : document.body;
        const classNameAsArray = className?.split(" ") || [];
        const classList = ["portal-container", ...classNameAsArray];

        element.classList.add(...classList);

        target.appendChild(element);

        return () => {
            setTimeout(() => {
                target.removeChild(element);
            }, 250);
        };
    }, [element, parent, className]);

    return createPortal(children, element);
};

Portal.defaultProps = {
    parent: undefined,
};

Portal.propTypes = {
    children: PropTypes.node.isRequired,
    parent: PropTypes.node,
    className: PropTypes.string.isRequired,
};

export default Portal;

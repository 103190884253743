import * as ReduxActions from "../actions/action-transaction";
import { GET_TRANSACTION_RESULT } from "../actions/action-transaction";
import getEnvValue from "helpers/getEnvValue";
import { setAuthError } from "features/authentication/authenticationSlice";

const middleWareTransaction = (store) => (next) => (action) => {
    const base = getEnvValue("KATE_CORE_URL");
    let url;

    switch (action.type) {
        case ReduxActions.GET_TRANSACTION().type:
            url = `${base}/api/v1/transactions/${action.payload.id}/formatted`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(GET_TRANSACTION_RESULT({ id: action.payload.id, data: result }));
                });

            next(action);
            break;

        case ReduxActions.TRANSACTION_EXPORT().type:
            const transactionIds = action.payload.selectedTransactions.map((x) => x.id);
            const requestObject = {
                transactionIds,
                transactionPropertyKeys: null,
            };

            url = `${base}/api/v1/transactions/export`;

            window.API.getClient()
                .post(url, requestObject)
                .then((data) => {
                    const { fileURL } = data.data.data;
                    window.open(base + fileURL, "_blank");
                    store.dispatch(ReduxActions.TRANSACTION_EXPORTED_FLAG());
                })
                .catch((error) => {
                    store.dispatch(ReduxActions.TRANSACTION_EXPORTED_FLAG());

                    if (error.request.status === 401) {
                        store.dispatch(setAuthError("authenticationError"));
                    }
                });

        default:
            next(action);
            break;
    }
};

export default middleWareTransaction;

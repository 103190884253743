const pathToPropertyValue = ({ ids = {}, valuationState } = {}) => {
    if (!valuationState || !valuationState.getIn) return;

    const propertyDefinition = valuationState.getIn(["propertyDefinitions", ids.propertyId]);
    if (!propertyDefinition) return;

    if (ids.objectId) return [ids.valuationId, "objects", 0, "properties", "address", "properties"];

    return [
        ids.valuationId,
        "properties",
        propertyDefinition.get("category"),
        "sub_category",
        propertyDefinition.get("subCategory") || propertyDefinition.get("categorySub"),
        "properties",
        ids.propertyId,
        "value",
    ];
};

export default pathToPropertyValue;

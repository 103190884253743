import produce from "immer";

import {
    CHANGE_VALUE,
    ADD_ROW,
    LOAD_PAGE_DATA,
    REQUEST,
    SUCCESS,
    FAILURE,
    CALCULATE,
    ADD_TABLE,
    REMOVE_ROW,
    REMOVE_TABLE,
} from "../constants";
import BuildExpression from "../../app/Calculation/BuildExpression";
import { isEditable } from "../../app/InputHelpers";

const initialState = {
    data: {},
    changedAt: null,
    loading: false,
    loaded: false,
    error: null,
};

export default produce((draft = initialState, action) => {
    const { type, key, field, index, tableIndex, input, value, expression, data, error } = action;

    switch (type) {
        case LOAD_PAGE_DATA + REQUEST: {
            draft.error = null;
            draft.loading = true;
            draft.loaded = false;

            break;
        }
        case LOAD_PAGE_DATA + SUCCESS: {
            draft.loading = false;
            draft.loaded = true;
            draft.data = { ...draft.data, ...data.data };
            draft.actions = { ...data.actions };
            break;
        }
        case LOAD_PAGE_DATA + FAILURE: {
            draft.loading = false;
            draft.loaded = false;
            draft.error = error;
            break;
        }
        case CALCULATE: {
            const builder = new BuildExpression(draft.data, expression, index, tableIndex);
            const result = builder.calculate();

            if (tableIndex !== null && index !== null) {
                if (draft.data[key][tableIndex][index][field] !== result) {
                    draft.data[key][tableIndex][index][field] = result;
                }
                break;
            }

            if (index !== null) {
                if (draft.data[key][index][field] !== result) {
                    draft.data[key][index][field] = result;
                }
                break;
            }

            if (field) {
                if (draft.data[key][field] !== result) {
                    draft.data[key][field] = result;
                }
                break;
            }

            if (draft.data[key] !== result) {
                draft.data[key] = result;
            }

            break;
        }
        case CHANGE_VALUE:
            draft.changedAt = Date.now();

            if (tableIndex !== null && index !== null) {
                draft.data[key][tableIndex][index][field] = value;
                break;
            }

            if (index !== null) {
                draft.data[key][index][field] = value;
                break;
            }
            if (field) {
                draft.data[key][field] = value;
                break;
            }
            draft.data[key] = value;

            break;
        case ADD_ROW: {
            let obj = {};
            Object.keys(input.items.keys).forEach((key) => (obj[key] = null));

            let list = tableIndex !== null && tableIndex !== undefined ? draft.data[key][tableIndex] : draft.data[key];
            list.push(obj);
            break;
        }
        case REMOVE_ROW: {
            let list = tableIndex !== null && tableIndex !== undefined ? draft.data[key][tableIndex] : draft.data[key];
            list.splice(index, 1);
            break;
        }
        case ADD_TABLE: {
            let list = [];

            let keys = input.items.items.keys;
            draft.data[key][0].forEach((row, index) => {
                let obj = {};
                Object.keys(keys).forEach(
                    (field) => (obj[field] = isEditable(keys[field], draft.data, index, 0) ? null : row[field])
                );
                list.push(obj);
            });

            draft.data[key].push(list);
            break;
        }
        case REMOVE_TABLE: {
            draft.data[key].splice(tableIndex, 1);
            break;
        }

        default:
            return draft;
    }
});

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./resources/styles/loading.module.scss";

const Loading = ({ delay, text, minHeight, textColor }) => {
    const [showLoadingIndicator, setLoadingIndicatorVisibility] = useState(false);
    const [loadingText] = useState("....".split(""));
    const [variant] = useState(() => [minHeight && "min-height", textColor && `color-${textColor}`]);

    useEffect(() => {
        const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);

        return () => {
            clearTimeout(timer);
        };
    });

    if (showLoadingIndicator)
        return (
            <div className={styles.container} variant={variant.join(" ")}>
                {text || "Loading"}
                {loadingText.map((char, index) => {
                    const key = index;
                    const style = { animationDelay: 0.5 + index / 5 + "s" };
                    return (
                        <span key={key} style={style}>
                            {char}
                        </span>
                    );
                })}
            </div>
        );

    return null;
};

Loading.defaultProps = {
    delay: 5,
    text: undefined,
    minHeight: true,
    textColor: "default",
};

Loading.propTypes = {
    delay: PropTypes.number,
    text: PropTypes.string,
    minHeight: PropTypes.bool,
    textColor: PropTypes.oneOf(["default", "inherit"]),
};

export default Loading;

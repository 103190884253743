import { createSlice } from "@reduxjs/toolkit";
import infoDataBoxApi from "./infoDataBoxApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
    show: false,
    showList: false,
    listItemActive: false,
    activeItemsList: [],
};

const infoDataBox = createSlice({
    name: "infoDataBox",
    initialState,
    reducers: {
        resetInfoDataBox() {
            return initialState;
        },
        setInfoDataBoxShow(state, action) {
            const newState = state;
            newState.show = action.payload;
        },
        setListBoxShow(state, action) {
            const newState = state;
            newState.showList = action.payload;
        },
        resetActiveItemsList(state) {
            const newState = state;
            newState.activeItemsList = initialState.activeItemsList;
        },
    },
    extraReducers: {
        [infoDataBoxApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [infoDataBoxApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [infoDataBoxApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.show = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
    },
});

const { resetInfoDataBox, setInfoDataBoxShow, setListBoxShow, resetActiveItemsList } = infoDataBox.actions;
const infoDataBoxReducer = infoDataBox.reducer;

export {
    infoDataBoxReducer,
    resetInfoDataBox,
    setInfoDataBoxShow,
    infoDataBoxApi,
    setListBoxShow,
    resetActiveItemsList,
};

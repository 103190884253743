import { createSlice } from "@reduxjs/toolkit";
import newsCategoriesApi from "./newsCategoriesApi";
import newsCategoryItemsApi from "./newsCategoryItemsApi";

const initialState = {
    loading: false,
    entities: undefined,
    activeCategoryId: undefined,
    currentRequestId: undefined,
    error: undefined,
};

const news = createSlice({
    name: "news",
    initialState,
    reducers: {
        resetNews() {
            return initialState;
        },
        setActiveCategoryId(state, action) {
            const newState = state;
            newState.activeCategoryId = action.payload;
        },
    },
    extraReducers: {
        [newsCategoriesApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [newsCategoriesApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload.entities;
                newState.currentRequestId = undefined;
                newState.activeCategoryId = action.payload.activeCategoryId;
            }
        },
        [newsCategoriesApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
        [newsCategoryItemsApi.pending]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;

            newState.entities.map((item) => {
                const newItemState = item;
                if (
                    newItemState.id === arg.activeCategoryId &&
                    newItemState.loading === false &&
                    !newItemState.currentRequestId
                ) {
                    newItemState.loading = true;
                    newItemState.currentRequestId = requestId;
                }
                return newItemState;
            });
        },
        [newsCategoryItemsApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;

            newState.entities.map((item) => {
                const newItemState = item;
                if (
                    newItemState.id === arg.activeCategoryId &&
                    newItemState.loading === true &&
                    newItemState.currentRequestId === requestId
                ) {
                    newItemState.loading = false;
                    newItemState.currentRequestId = undefined;
                    newItemState.entities = action.payload.entities;
                    newItemState.date = action.payload.date;
                }
                return newItemState;
            });
        },
        [newsCategoryItemsApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId, arg } = action.meta;

            newState.entities.map((item) => {
                const newItemState = item;
                if (
                    newItemState.id === arg.activeCategoryId &&
                    newItemState.loading === true &&
                    newItemState.currentRequestId === requestId
                ) {
                    newItemState.loading = false;
                    newItemState.currentRequestId = undefined;
                    newItemState.error = action.payload?.error;
                }
                return newItemState;
            });
        },
    },
});

const { resetNews, setActiveCategoryId } = news.actions;
const newsReducer = news.reducer;

export { newsReducer, resetNews, setActiveCategoryId, newsCategoriesApi, newsCategoryItemsApi };

// VALUATION
export const VALUATION_RETRIEVE = (valuationId, refreshValuation) => ({
    type: "VALUATION_RETRIEVE",
    payload: {
        valuationId,
        refreshValuation,
    },
});

export const VALUATION_RETRIEVE_RESULT = (valuationId, valuation) => ({
    type: "VALUATION_RETRIEVE_RESULT",
    payload: {
        valuationId,
        valuation,
    },
});

export const VALUATION_CLEAR_DATA = () => ({
    type: "VALUATION_CLEAR_DATA",
    payload: {},
});

export const VALUATION_ROTATE_IMAGE = (valuationId, propertyId, fileName, direction, extension) => ({
    type: "VALUATION_ROTATE_IMAGE",
    payload: {
        valuationId,
        propertyId,
        fileName,
        direction,
        extension,
    },
});

export const VALUATION_REORDER_IMAGES = (valuationId, propertyId, from, to, images) => ({
    type: "VALUATION_REORDER_IMAGES",
    payload: {
        valuationId,
        propertyId,
        from,
        to,
        images,
    },
});

export const VALUATION_REORDER_IMAGES_RESULT = (valuationId, propertyId, images) => ({
    type: "VALUATION_REORDER_IMAGES_RESULT",
    payload: {
        valuationId,
        propertyId,
        images,
    },
});
// SET SELECTED VALUATIONS FROM TABLES
export const VALUATION_SET_SELECTED = (selectedValuations) => ({
    type: "VALUATION_SET_SELECTED",
    payload: {
        selectedValuations,
    },
});

// PROPERTIES
export const VALUATION_RETRIEVE_PROPERTIES = (valuationId) => ({
    type: "VALUATION_RETRIEVE_PROPERTIES",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_PROPERTIES_RESULT = ({
    valuationId,
    valuationProperties,
    propertyDefinitions,
} = {}) => ({
    type: "VALUATION_RETRIEVE_PROPERTIES_RESULT",
    payload: {
        valuationId,
        valuationProperties,
        propertyDefinitions,
    },
});

export const VALUATION_RETRIEVE_PROPERTY_DEFINITION_CONFIG_ANALYSIS = () => ({
    type: "VALUATION_RETRIEVE_PROPERTY_DEFINITION_CONFIG_ANALYSIS",
    payload: {},
});

// FILES
export const VALUATION_UPLOAD_FILE = (valuationId, propertyId, dataUri, onSuccess, onError) => ({
    type: "VALUATION_UPLOAD_FILE",
    payload: {
        valuationId,
        propertyId,
        dataUri,
        onSuccess,
        onError,
    },
});

// Set the file locations in the store
export const VALUATION_UPLOAD_FILE_RESULT = ({ valuationId, propertyId, fileLocations } = {}) => ({
    type: "VALUATION_UPLOAD_FILE_RESULT",
    payload: {
        valuationId,
        propertyId,
        fileLocations,
    },
});

export const VALUATION_GET_FILES = ({ valuationId, propertyId, onSuccess, onError } = {}) => ({
    type: "VALUATION_GET_FILES",
    payload: {
        valuationId,
        propertyId,
        onSuccess,
        onError,
    },
});

export const VALUATION_GET_FILES_RESULT = ({ valuationId, propertyId, files, onError } = {}) => ({
    type: "VALUATION_GET_FILES_RESULT",
    payload: {
        valuationId,
        propertyId,
        files,
        onError,
    },
});

export const VALUATION_REMOVE_FILE = (valuationId, propertyId, fileName, onSuccess, onError) => ({
    type: "VALUATION_REMOVE_FILE",
    payload: {
        valuationId,
        propertyId,
        fileName,
        onSuccess,
        onError,
    },
});

export const VALUATION_REMOVE_FILE_RESULT = (valuationId, propertyId, fileName) => ({
    type: "VALUATION_REMOVE_FILE_RESULT",
    payload: {
        valuationId,
        propertyId,
        fileName,
    },
});

// upload, get and delete images

export const VALUATION_UPLOAD_IMAGE = (valuationId, propertyId, dataUri, callback) => ({
    type: "VALUATION_UPLOAD_IMAGE",
    payload: {
        valuationId,
        propertyId,
        dataUri,
        callback,
    },
});

export const VALUATIONS_SET_GALLERY_ENTRY = (propertyId, disabled, image) => ({
    type: "VALUATIONS_SET_GALLERY_ENTRY",
    payload: {
        propertyId,
        disabled,
        image,
    },
});

export const VALUATION_RETRIEVE_IMAGES = (valuationId, propertyId) => ({
    type: "VALUATION_RETRIEVE_IMAGES",
    payload: {
        valuationId,
        propertyId,
    },
});

export const VALUATION_RETRIEVE_IMAGES_RESULT = (valuationId, propertyId, images) => ({
    type: "VALUATION_RETRIEVE_IMAGES_RESULT",
    payload: {
        valuationId,
        propertyId,
        images,
    },
});

export const VALUATION_DELETE_IMAGE = (valuationId, propertyId, fileNames) => ({
    type: "VALUATION_DELETE_IMAGE",
    payload: {
        valuationId,
        propertyId,
        fileNames,
    },
});

export const VALUATION_DELETE_IMAGE_RESULT = (valuationId, propertyId, fileNames) => ({
    type: "VALUATION_DELETE_IMAGE_RESULT",
    payload: {
        valuationId,
        propertyId,
        fileNames,
    },
});

// OBJECTS
export const VALUATION_RETRIEVE_OBJECTS = (valuationId) => ({
    type: "VALUATION_RETRIEVE_OBJECTS",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_OBJECTS_RESULT = (valuationId, objects) => ({
    type: "VALUATION_RETRIEVE_OBJECTS_RESULT",
    payload: {
        valuationId,
        objects,
    },
});

export const VALUATION_RETRIEVE_OBJECT_PROPERTIES = (valuationId, objectId) => ({
    type: "VALUATION_RETRIEVE_OBJECT_PROPERTIES",
    payload: {
        valuationId,
        objectId,
    },
});

export const VALUATION_RETRIEVE_OBJECT_PROPERTIES_RESULT = (valuationId, objectId, objectProperties) => ({
    type: "VALUATION_RETRIEVE_OBJECT_PROPERTIES_RESULT",
    payload: {
        valuationId,
        objectId,
        objectProperties,
    },
});

export const VALUATION_DELETE_OBJECT = (valuationId, objectId, onSuccess, onError) => ({
    type: "VALUATION_DELETE_OBJECT",
    payload: {
        valuationId,
        objectId,
        onSuccess,
        onError,
    },
});

export const VALUATION_DELETE_OBJECT_RESULT = (valuationId, objectId) => ({
    type: "VALUATION_DELETE_OBJECT_RESULT",
    payload: {
        valuationId,
        objectId,
    },
});

// HISTORY
export const VALUATION_RETRIEVE_HISTORY = (valuationId) => ({
    type: "VALUATION_RETRIEVE_HISTORY",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_HISTORY_RESULT = (valuationId, history) => ({
    type: "VALUATION_RETRIEVE_HISTORY_RESULT",
    payload: {
        valuationId,
        history,
    },
});

// APPROVALS
export const VALUATION_RETRIEVE_APPROVALS = (valuationId) => ({
    type: "VALUATION_RETRIEVE_APPROVALS",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_APPROVALS_RESULT = (valuationId, approvals) => ({
    type: "VALUATION_RETRIEVE_APPROVALS_RESULT",
    payload: {
        valuationId,
        approvals,
    },
});

// APPROVALS
export const VALUATION_RETRIEVE_COI_ENTRIES = (valuationId) => ({
    type: "VALUATION_RETRIEVE_COI_ENTRIES",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_COI_ENTRIES_RESULT = (valuationId, coiEntries) => ({
    type: "VALUATION_RETRIEVE_COI_ENTRIES_RESULT",
    payload: {
        valuationId,
        coiEntries,
    },
});

// ACTIVE TAB
export const VALUATION_EDIT_CHANGE_TAB = (valuationId, activeTab) => ({
    type: "VALUATION_EDIT_CHANGE_TAB",
    payload: {
        valuationId,
        activeTab,
    },
});

export const VALUATION_SWAP_PHOTO = (valuationId, fileName, propertyIdFrom, propertyIdTo, callback) => ({
    type: "VALUATION_SWAP_PHOTO",
    payload: {
        valuationId,
        fileName,
        propertyIdFrom,
        propertyIdTo,
        callback,
    },
});

// The client changes a property value (i.e. triggered by onChange event of an input)
export const VALUATION_PROPERTY_VALUE_CHANGED = ({
    valuationId,
    propertyId,
    value,
    objectId = null,
    debounceTime,
    isImport,
    onSuccess,
} = {}) => ({
    type: "VALUATION_PROPERTY_VALUE_CHANGED",
    payload: {
        valuationId,
        propertyId,
        objectId,
        value,
        debounceTime,
        isImport,
        onSuccess,
    },
});

// Make a PUT server request in order to update a valuation property's value
export const VALUATION_PROPERTY_VALUE_PUT = ({
    valuationId,
    propertyId,
    oldValue,
    newValue,
    isImport,
    onSuccess,
} = {}) => ({
    type: "VALUATION_PROPERTY_VALUE_PUT",
    payload: {
        valuationId,
        propertyId,
        oldValue, // What was the value received from the server upon which this update was based? Could be replaced by a version id at some point...
        newValue,
        isImport,
        onSuccess,
    },
});

// A new valuation property's value was received from the server (i.e. in the response of a GET/PUT request)
export const VALUATION_PROPERTY_VALUES_RECEIVED = ({ valuationId, propertyId, isImport, value } = {}) => ({
    type: "VALUATION_PROPERTY_VALUES_RECEIVED",
    payload: {
        valuationId,
        propertyId,
        isImport,
        value,
    },
});

export const VALUATION_END_IMPORT = ({ valuationId, propertyId } = {}) => ({
    type: "VALUATION_END_IMPORT",
    payload: {
        valuationId,
        propertyId,
    },
});

// Dispatch this action whenever an error occurs in the process of updating a valuation property's value
export const VALUATION_PROPERTY_VALUES_RECEIVED_ERROR = ({ valuationId, propertyId, error } = {}) => ({
    type: "VALUATION_PROPERTY_VALUES_RECEIVED_ERROR",
    payload: {
        valuationId,
        propertyId,
        error,
    },
});

export const VALUATION_RETRIEVE_GENERATED_REPORTS = (valuationId, onError) => ({
    type: "VALUATION_RETRIEVE_GENERATED_REPORTS",
    payload: {
        valuationId,
        onError,
    },
});

export const VALUATION_RETRIEVE_GENERATED_REPORTS_RESULT = (valuationId, generatedReports) => ({
    type: "VALUATION_RETRIEVE_GENERATED_REPORTS_RESULT",
    payload: {
        valuationId,
        generatedReports,
    },
});

export const VALUATION_RETRIEVE_REPORT_PREVIEWS = (valuationId) => ({
    type: "VALUATION_RETRIEVE_REPORT_PREVIEWS",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_REPORT_PREVIEWS_RESULT = (valuationId, reportPreviews) => ({
    type: "VALUATION_RETRIEVE_REPORT_PREVIEWS_RESULT",
    payload: {
        valuationId,
        reportPreviews,
    },
});

export const VALUATION_RETRIEVE_CLIENTS = (valuationId, onError) => ({
    type: "VALUATION_RETRIEVE_CLIENTS",
    payload: {
        valuationId,
        onError,
    },
});

export const VALUATION_RETRIEVE_CLIENTS_RESULT = (valuationId, clients) => ({
    type: "VALUATION_RETRIEVE_CLIENTS_RESULT",
    payload: {
        valuationId,
        clients,
    },
});

export const VALUATION_RETRIEVE_SINGLE_CLIENT = (clientId, valuationId) => ({
    type: "VALUATION_RETRIEVE_SINGLE_CLIENT",
    payload: {
        valuationId,
        clientId,
    },
});

export const VALUATION_RETRIEVE_SINGLE_CLIENT_RESULT = (valuationId, client) => ({
    type: "VALUATION_RETRIEVE_SINGLE_CLIENT_RESULT",
    payload: {
        client,
        valuationId,
    },
});

export const VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA = (valuationId, onError) => ({
    type: "VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA",
    payload: {
        valuationId,
        onError,
    },
});

export const VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA_RESULT = (valuationId, clientsStatusLegenda) => ({
    type: "VALUATION_RETRIEVE_CLIENTS_STATUS_LEGENDA_RESULT",
    payload: {
        valuationId,
        clientsStatusLegenda,
    },
});

export const VALUATION_RETRIEVE_TRANSACTIONS = (valuationId, onError) => ({
    type: "VALUATION_RETRIEVE_TRANSACTIONS",
    payload: {
        valuationId,
        onError,
    },
});

export const VALUATION_RETRIEVE_TRANSACTIONS_RESULT = (valuationId, transactions) => ({
    type: "VALUATION_RETRIEVE_TRANSACTIONS_RESULT",
    payload: {
        valuationId,
        transactions,
    },
});

export const VALUATION_DELETE_TRANSACTION = (valuationId, transactionId, onError) => ({
    type: "VALUATION_DELETE_TRANSACTION",
    payload: {
        valuationId,
        transactionId,
        onError,
    },
});

export const VALUATION_DELETE_TRANSACTION_RESULT = (valuationId, transactionId) => ({
    type: "VALUATION_DELETE_TRANSACTION_RESULT",
    payload: {
        valuationId,
        transactionId,
    },
});

export const VALUATION_UPDATE_CLIENT = (valuationId, clientId, onError) => ({
    type: "VALUATION_UPDATE_CLIENT",
    payload: {
        valuationId,
        clientId,
        onError,
    },
});

export const VALUATION_RETRIEVE_PORTFOLIOS = (valuationId, onError) => ({
    type: "VALUATION_RETRIEVE_PORTFOLIOS",
    payload: {
        valuationId,
        onError,
    },
});

export const VALUATION_RETRIEVE_PORTFOLIOS_RESULT = (valuationId, portfolios) => ({
    type: "VALUATION_RETRIEVE_PORTFOLIOS_RESULT",
    payload: {
        valuationId,
        portfolios,
    },
});

export const VALUATION_UPDATE_PORTFOLIO = (valuationId, portfolioId, onError) => ({
    type: "VALUATION_UPDATE_PORTFOLIO",
    payload: {
        valuationId,
        portfolioId,
        onError,
    },
});

export const VALUATION_RETRIEVE_TRANSITIONS = (valuationId, onSuccess, onError) => ({
    type: "VALUATION_RETRIEVE_TRANSITIONS",
    payload: {
        valuationId,
        onSuccess,
        onError,
    },
});

export const VALUATION_CLEAR_TRANSITIONS = (valuationId) => ({
    type: "VALUATION_CLEAR_TRANSITIONS",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_TRANSITIONS_RESULT = (valuationId, transitions) => ({
    type: "VALUATION_RETRIEVE_TRANSITIONS_RESULT",
    payload: {
        valuationId,
        transitions,
    },
});

export const VALUATION_DO_TRANSITION = (valuationId, transitionId, comment, callBack) => ({
    type: "VALUATION_DO_TRANSITION",
    payload: {
        valuationId,
        transitionId,
        comment,
        callBack,
    },
});

export const VALUATION_RETRIEVE_PREDEFINED_TEXTS = (valuationId) => ({
    type: "VALUATION_RETRIEVE_PREDEFINED_TEXTS",
    payload: {
        valuationId,
    },
});

export const VALUATION_RETRIEVE_PREDEFINED_TEXTS_RESULT = (valuationId, predefinedTexts) => ({
    type: "VALUATION_RETRIEVE_PREDEFINED_TEXTS_RESULT",
    payload: {
        valuationId,
        predefinedTexts,
    },
});

export const VALUATION_UPDATE_PREDEFINED_TEXTS = (valuationId, propertyId) => ({
    type: "VALUATION_UPDATE_PREDEFINED_TEXTS",
    payload: {
        valuationId,
        propertyId,
    },
});

export const VALUATION_UPDATE_PREDEFINED_TEXTS_RESULT = (valuationId, propertyId, predefinedTexts) => ({
    type: "VALUATION_UPDATE_PREDEFINED_TEXTS_RESULT",
    payload: {
        valuationId,
        propertyId,
        predefinedTexts,
    },
});

// VALUES IN PROPERTIES
export const VALUATION_OBJECT_VALUE_UPDATE = (valuationId, objectId, propertyId, objectCategory, value) => ({
    type: "VALUATION_OBJECT_VALUE_UPDATE",
    payload: {
        valuationId,
        objectId,
        propertyId,
        objectCategory,
        value,
    },
});

export const VALUATION_OBJECT_VALUE_UPDATE_RESULT = (valuationId, objectId, propertyId, objectCategory, value) => ({
    type: "VALUATION_OBJECT_VALUE_UPDATE_RESULT",
    payload: {
        valuationId,
        objectId,
        propertyId,
        objectCategory,
        value,
    },
});

export const VALUATION_TRANSACTION_TABLE_UPDATE_ORDER = (valuationId, id, oldIndex, newIndex) => ({
    type: "VALUATION_TRANSACTION_TABLE_UPDATE_ORDER",
    payload: {
        valuationId,
        id,
        oldIndex,
        newIndex,
    },
});

//queue in redux

export const VALUATION_QUEUE_UPDATE = ({
    valuationId,
    propertyId,
    isObject,
    newValue,
    objectId,
    subCat,
    category,
} = {}) => ({
    type: "VALUATION_QUEUE_UPDATE",
    payload: {
        valuationId,
        propertyId,
        isObject,
        newValue,
        objectId,
        subCat,
        category,
    },
});

export const VALUATION_QUEUE_UPDATE_RESULT = ({ valuationId, cat, subCat, id, value, isObject, objectId } = {}) => ({
    type: "VALUATION_QUEUE_UPDATE_RESULT",
    payload: {
        valuationId,
        cat,
        subCat,
        id,
        value,
        isObject,
        objectId,
    },
});

export const VALUATION_QUEUE_UPDATE_PENDING = ({ valuationId, cat, subCat, id, value, isObject, objectId } = {}) => ({
    type: "VALUATION_QUEUE_UPDATE_PENDING",
    payload: {
        valuationId,
        cat,
        subCat,
        id,
        value,
        isObject,
        objectId,
    },
});

export const VALUATION_QUEUE_UPDATE_DELETE = ({ propertyId } = {}) => ({
    type: "VALUATION_QUEUE_UPDATE_DELETE",
    payload: {
        propertyId,
    },
});

export const VALUATION_QUEUE_UPDATE_APPEND = ({ propertyId, queueObject } = {}) => ({
    type: "VALUATION_QUEUE_UPDATE_APPEND",
    payload: {
        propertyId,
        queueObject,
    },
});

export const VALUATION_QUEUE_PROCESS_UPDATE_APPEND = (queueObject = {}) => ({
    type: "VALUATION_QUEUE_PROCESS_UPDATE_APPEND",
    payload: {
        queueObject,
    },
});

export const VALUATION_QUEUE_UPDATE_CLEAR = ({ error } = {}) => ({
    type: "VALUATION_QUEUE_UPDATE_CLEAR",
    payload: {
        error,
    },
});

export const VALUATION_QUEUE_PROCESS_UPDATE_CLEAR = () => ({
    type: "VALUATION_QUEUE_PROCESS_UPDATE_CLEAR",
    payload: {},
});

export const VALUATION_QUEUE_SET_UPDATING = (newUpdateState = {}) => ({
    type: "VALUATION_QUEUE_SET_UPDATING",
    payload: {
        newUpdateState,
    },
});

export const VALUATION_QUEUE_HANDLE_ENTRY = (queueObject = {}) => ({
    type: "VALUATION_QUEUE_HANDLE_ENTRY",
    payload: {
        queueObject,
    },
});

// XBRL processing
export const VALUATION_DOWNLOAD_XBRL = (valuationId, callBack) => ({
    type: "VALUATION_DOWNLOAD_XBRL",
    payload: {
        valuationId,
        callBack,
    },
});

export const VALUATION_TRANSFER_XBRL = (valuationId, callBack) => ({
    type: "VALUATION_TRANSFER_XBRL",
    payload: {
        valuationId,
        callBack,
    },
});
// export valuations
export const VALUATION_EXPORT = (selectedValuations) => ({
    type: "VALUATION_EXPORT",
    payload: {
        selectedValuations,
    },
});

export const VALUATION_EXPORTED_FLAG = (valuationsExported = true) => ({
    type: "VALUATION_EXPORTED_FLAG",
    payload: {
        valuationsExported,
    },
});

// get generated reports
export const VALUATION_GET_GENERATED_REPORTS = (queue, api, responseType) => ({
    type: "VALUATION_GET_GENERATED_REPORTS",
    payload: {
        queue,
        api,
        responseType,
    },
});

export const VALUATION_END_GENERATED_REPORTS = (flag) => ({
    type: "VALUATION_END_GENERATED_REPORTS",
    payload: {
        flag,
    },
});

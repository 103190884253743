import { REQUEST, SUCCESS, FAILURE, CALCULATE } from "../constants";
import { setAuthError } from "features/authentication/authenticationSlice";
import { updateCalculationData } from "features/calculationModel/helpers";

const api =
    ({ getState, dispatch }) =>
    (next) =>
    async (action) => {
        if (action.type === CALCULATE) {
            updateCalculationData();
        }
        if (!action.CallAPI) return next(action);

        const { CallAPI, type, ...rest } = action;
        const { auth, userProfile } = getState();

        next({ ...rest, type: type + REQUEST });

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return next({
                ...rest,
                type: type + FAILURE,
                error: { status: 401, messages: "authenticationError" },
            });
        }
        const { jwt } = auth;

        const getResponse = await fetch(CallAPI, {
            method: "GET",
            mode: "cors",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
            },
            cache: "default",
            credentials: "omit",
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = ["application/json"].includes(contentType);

        if (isJsonResponse) {
            const response = await getResponse.json();

            if (!getResponse.ok) {
                if (getResponse.status === 401) dispatch(setAuthError("authenticationError"));

                return next({
                    ...rest,
                    type: type + FAILURE,
                    error: { status: getResponse.status, messages: response.error },
                });
            }

            return next({ ...rest, type: type + SUCCESS, data: response });
        }

        return next({
            ...rest,
            type: type + FAILURE,
            error: { status: getResponse.status, messages: "applicationError" },
        });
    };

export default api;

import { fromJS } from "immutable";

import * as ReduxActions from "../actions/action-importer";

const emptyState = fromJS({
    objectProperties: null,
    valuationId: null,
});

export default (state = emptyState, action) => {
    switch (action.type) {
        case ReduxActions.IMPORTER_GET_PROPERTIES_RESULT().type:
            return state.set(action.payload.propertiesName, fromJS(action.payload.properties));
        default:
            return state;
    }
};

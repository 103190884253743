import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class LessThan extends Expression {
    calculate() {
        let result = false;

        if (this.items.length === 2) {
            result = Number(this.items[0].calculate()) < Number(this.items[1].calculate());
        }

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default LessThan;

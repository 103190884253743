import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class PowerOf extends Expression {
    calculate() {
        let result = false;

        if (this.items.length === 2) {
            let item1 = Number(this.items[0].calculate());
            let item2 = Number(this.items[1].calculate());
            result = item1 ** item2;
        }

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default PowerOf;

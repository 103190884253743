import translationMessages from "react/i18n";
import defaultConfig from "config/constants/defaultConfig";
import { getType, apiCall, getStorageItem, setStorageItem, validations } from "@kateinnovations/javascript-helpers";
import getEnvValue from "helpers/getEnvValue";

const defaultLanguage = getEnvValue("LOCAL_LANGUAGE")?.toLowerCase() || "en";

const serializeTranslations = (data) => {
    if (validations.isEmpty(data) || getType(data) !== "object") return {};

    const translation = Object.keys(data).reduce((result, key) => {
        const newResult = result;
        let value = data[key];

        if (value === "n/a") value = " ";

        if (getType(value) !== "string") value = JSON.stringify(value);

        newResult[key] = value;

        return newResult;
    }, {});

    return translation;
};

const getTranslationsFromLocal = async (languageCode) => {
    const localTranslationsDefault = await translationMessages[defaultLanguage];
    const localTranslations =
        languageCode && languageCode !== defaultLanguage ? await translationMessages[languageCode] : undefined;

    const localTranslationsObject = {
        ...localTranslationsDefault,
        ...localTranslations,
    };

    const translation = serializeTranslations(localTranslationsObject);

    return translation;
};

const getTranslationsFromApi = async (languageCode) => {
    const localTranslations = await getTranslationsFromLocal(languageCode);
    const { jwt } = getStorageItem("kateToken");
    const cache = getStorageItem("kateUseHTTPCache") ? "force-cache" : "default";
    const url = `${defaultConfig.api.translations}?language=${languageCode}`;

    const getResponse = await apiCall({
        url,
        method: "GET",
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${jwt}`,
        },
        cache,
        credentials: "omit",
        mode: "cors",
    });

    if (getResponse.ok) {
        const apiTranslation = await getResponse.json();
        const translationSerialized = serializeTranslations(apiTranslation);

        const translations = {
            ...localTranslations,
            ...translationSerialized,
        };

        setStorageItem("kateUseHTTPCache", true);

        return translations;
    }

    return localTranslations;
};

const fetchTranslations = {
    local: async (languageCode) => {
        const translations = await getTranslationsFromLocal(languageCode);
        return translations;
    },
    distributed: async (languageCode) => {
        const translations = await getTranslationsFromApi(languageCode);
        return translations;
    },
};

export default fetchTranslations;

import BuildExpression from "./Calculation/BuildExpression";
import store from "Store";

export const isEditable = (input, data, index, tableIndex) => {
    if (input.type === "switchIndex" && input.arguments && input.arguments[index]) {
        input = input.arguments[index];
    }

    if (input.editable && typeof input.editable !== "boolean") {
        return new BuildExpression(
            data || store.getState().pageData.data,
            input.editable,
            index,
            tableIndex
        ).calculate();
    }

    return input.editable;
};

export const formatValue = (value, minDecimals = 2) => {
    const formattedValue =
        typeof value === "number"
            ? new Intl.NumberFormat("nl-NL", { minimumFractionDigits: minDecimals, maximumFractionDigits: 2 }).format(
                  value
              )
            : value;
    return formattedValue === "NaN" ? "0" : formattedValue;
};

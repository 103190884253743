const countryListEN = [
    { countryCode: 4, country: "Afghanistan", alpha2: "af", alpha3: "afg" },
    { countryCode: 248, country: "Åland Islands", alpha2: "ax", alpha3: "ala" },
    { countryCode: 8, country: "Albania", alpha2: "al", alpha3: "alb" },
    { countryCode: 12, country: "Algeria", alpha2: "dz", alpha3: "dza" },
    { countryCode: 16, country: "American Samoa", alpha2: "as", alpha3: "asm" },
    { countryCode: 20, country: "Andorra", alpha2: "ad", alpha3: "and" },
    { countryCode: 24, country: "Angola", alpha2: "ao", alpha3: "ago" },
    { countryCode: 660, country: "Anguilla", alpha2: "ai", alpha3: "aia" },
    { countryCode: 10, country: "Antarctica", alpha2: "aq", alpha3: "ata" },
    { countryCode: 28, country: "Antigua and Barbuda", alpha2: "ag", alpha3: "atg" },
    { countryCode: 32, country: "Argentina", alpha2: "ar", alpha3: "arg" },
    { countryCode: 51, country: "Armenia", alpha2: "am", alpha3: "arm" },
    { countryCode: 533, country: "Aruba", alpha2: "aw", alpha3: "abw" },
    { countryCode: 36, country: "Australia", alpha2: "au", alpha3: "aus" },
    { countryCode: 40, country: "Austria", alpha2: "at", alpha3: "aut" },
    { countryCode: 31, country: "Azerbaijan", alpha2: "az", alpha3: "aze" },
    { countryCode: 44, country: "Bahamas", alpha2: "bs", alpha3: "bhs" },
    { countryCode: 48, country: "Bahrain", alpha2: "bh", alpha3: "bhr" },
    { countryCode: 50, country: "Bangladesh", alpha2: "bd", alpha3: "bgd" },
    { countryCode: 52, country: "Barbados", alpha2: "bb", alpha3: "brb" },
    { countryCode: 112, country: "Belarus", alpha2: "by", alpha3: "blr" },
    { countryCode: 56, country: "Belgium", alpha2: "be", alpha3: "bel" },
    { countryCode: 84, country: "Belize", alpha2: "bz", alpha3: "blz" },
    { countryCode: 204, country: "Benin", alpha2: "bj", alpha3: "ben" },
    { countryCode: 60, country: "Bermuda", alpha2: "bm", alpha3: "bmu" },
    { countryCode: 64, country: "Bhutan", alpha2: "bt", alpha3: "btn" },
    { countryCode: 68, country: "Bolivia (Plurinational State of)", alpha2: "bo", alpha3: "bol" },
    { countryCode: 535, country: "Bonaire, Sint Eustatius and Saba", alpha2: "bq", alpha3: "bes" },
    { countryCode: 70, country: "Bosnia and Herzegovina", alpha2: "ba", alpha3: "bih" },
    { countryCode: 72, country: "Botswana", alpha2: "bw", alpha3: "bwa" },
    { countryCode: 74, country: "Bouvet Island", alpha2: "bv", alpha3: "bvt" },
    { countryCode: 76, country: "Brazil", alpha2: "br", alpha3: "bra" },
    { countryCode: 86, country: "British Indian Ocean Territory", alpha2: "io", alpha3: "iot" },
    { countryCode: 96, country: "Brunei Darussalam", alpha2: "bn", alpha3: "brn" },
    { countryCode: 100, country: "Bulgaria", alpha2: "bg", alpha3: "bgr" },
    { countryCode: 854, country: "Burkina Faso", alpha2: "bf", alpha3: "bfa" },
    { countryCode: 108, country: "Burundi", alpha2: "bi", alpha3: "bdi" },
    { countryCode: 132, country: "Cabo Verde", alpha2: "cv", alpha3: "cpv" },
    { countryCode: 116, country: "Cambodia", alpha2: "kh", alpha3: "khm" },
    { countryCode: 120, country: "Cameroon", alpha2: "cm", alpha3: "cmr" },
    { countryCode: 124, country: "Canada", alpha2: "ca", alpha3: "can" },
    { countryCode: 136, country: "Cayman Islands", alpha2: "ky", alpha3: "cym" },
    { countryCode: 140, country: "Central African Republic", alpha2: "cf", alpha3: "caf" },
    { countryCode: 148, country: "Chad", alpha2: "td", alpha3: "tcd" },
    { countryCode: 152, country: "Chile", alpha2: "cl", alpha3: "chl" },
    { countryCode: 156, country: "China", alpha2: "cn", alpha3: "chn" },
    { countryCode: 162, country: "Christmas Island", alpha2: "cx", alpha3: "cxr" },
    { countryCode: 166, country: "Cocos (Keeling) Islands", alpha2: "cc", alpha3: "cck" },
    { countryCode: 170, country: "Colombia", alpha2: "co", alpha3: "col" },
    { countryCode: 174, country: "Comoros", alpha2: "km", alpha3: "com" },
    { countryCode: 178, country: "Congo", alpha2: "cg", alpha3: "cog" },
    { countryCode: 180, country: "Congo, Democratic Republic of the", alpha2: "cd", alpha3: "cod" },
    { countryCode: 184, country: "Cook Islands", alpha2: "ck", alpha3: "cok" },
    { countryCode: 188, country: "Costa Rica", alpha2: "cr", alpha3: "cri" },
    { countryCode: 384, country: "Côte d'Ivoire", alpha2: "ci", alpha3: "civ" },
    { countryCode: 191, country: "Croatia", alpha2: "hr", alpha3: "hrv" },
    { countryCode: 192, country: "Cuba", alpha2: "cu", alpha3: "cub" },
    { countryCode: 531, country: "Curaçao", alpha2: "cw", alpha3: "cuw" },
    { countryCode: 196, country: "Cyprus", alpha2: "cy", alpha3: "cyp" },
    { countryCode: 203, country: "Czechia", alpha2: "cz", alpha3: "cze" },
    { countryCode: 208, country: "Denmark", alpha2: "dk", alpha3: "dnk" },
    { countryCode: 262, country: "Djibouti", alpha2: "dj", alpha3: "dji" },
    { countryCode: 212, country: "Dominica", alpha2: "dm", alpha3: "dma" },
    { countryCode: 214, country: "Dominican Republic", alpha2: "do", alpha3: "dom" },
    { countryCode: 218, country: "Ecuador", alpha2: "ec", alpha3: "ecu" },
    { countryCode: 818, country: "Egypt", alpha2: "eg", alpha3: "egy" },
    { countryCode: 222, country: "El Salvador", alpha2: "sv", alpha3: "slv" },
    { countryCode: 226, country: "Equatorial Guinea", alpha2: "gq", alpha3: "gnq" },
    { countryCode: 232, country: "Eritrea", alpha2: "er", alpha3: "eri" },
    { countryCode: 233, country: "Estonia", alpha2: "ee", alpha3: "est" },
    { countryCode: 748, country: "Eswatini", alpha2: "sz", alpha3: "swz" },
    { countryCode: 231, country: "Ethiopia", alpha2: "et", alpha3: "eth" },
    { countryCode: 238, country: "Falkland Islands (Malvinas)", alpha2: "fk", alpha3: "flk" },
    { countryCode: 234, country: "Faroe Islands", alpha2: "fo", alpha3: "fro" },
    { countryCode: 242, country: "Fiji", alpha2: "fj", alpha3: "fji" },
    { countryCode: 246, country: "Finland", alpha2: "fi", alpha3: "fin" },
    { countryCode: 250, country: "France", alpha2: "fr", alpha3: "fra" },
    { countryCode: 254, country: "French Guiana", alpha2: "gf", alpha3: "guf" },
    { countryCode: 258, country: "French Polynesia", alpha2: "pf", alpha3: "pyf" },
    { countryCode: 260, country: "French Southern Territories", alpha2: "tf", alpha3: "atf" },
    { countryCode: 266, country: "Gabon", alpha2: "ga", alpha3: "gab" },
    { countryCode: 270, country: "Gambia", alpha2: "gm", alpha3: "gmb" },
    { countryCode: 268, country: "Georgia", alpha2: "ge", alpha3: "geo" },
    { countryCode: 276, country: "Germany", alpha2: "de", alpha3: "deu" },
    { countryCode: 288, country: "Ghana", alpha2: "gh", alpha3: "gha" },
    { countryCode: 292, country: "Gibraltar", alpha2: "gi", alpha3: "gib" },
    { countryCode: 300, country: "Greece", alpha2: "gr", alpha3: "grc" },
    { countryCode: 304, country: "Greenland", alpha2: "gl", alpha3: "grl" },
    { countryCode: 308, country: "Grenada", alpha2: "gd", alpha3: "grd" },
    { countryCode: 312, country: "Guadeloupe", alpha2: "gp", alpha3: "glp" },
    { countryCode: 316, country: "Guam", alpha2: "gu", alpha3: "gum" },
    { countryCode: 320, country: "Guatemala", alpha2: "gt", alpha3: "gtm" },
    { countryCode: 831, country: "Guernsey", alpha2: "gg", alpha3: "ggy" },
    { countryCode: 324, country: "Guinea", alpha2: "gn", alpha3: "gin" },
    { countryCode: 624, country: "Guinea-Bissau", alpha2: "gw", alpha3: "gnb" },
    { countryCode: 328, country: "Guyana", alpha2: "gy", alpha3: "guy" },
    { countryCode: 332, country: "Haiti", alpha2: "ht", alpha3: "hti" },
    { countryCode: 334, country: "Heard Island and McDonald Islands", alpha2: "hm", alpha3: "hmd" },
    { countryCode: 336, country: "Holy See", alpha2: "va", alpha3: "vat" },
    { countryCode: 340, country: "Honduras", alpha2: "hn", alpha3: "hnd" },
    { countryCode: 344, country: "Hong Kong", alpha2: "hk", alpha3: "hkg" },
    { countryCode: 348, country: "Hungary", alpha2: "hu", alpha3: "hun" },
    { countryCode: 352, country: "Iceland", alpha2: "is", alpha3: "isl" },
    { countryCode: 356, country: "India", alpha2: "in", alpha3: "ind" },
    { countryCode: 360, country: "Indonesia", alpha2: "id", alpha3: "idn" },
    { countryCode: 364, country: "Iran (Islamic Republic of)", alpha2: "ir", alpha3: "irn" },
    { countryCode: 368, country: "Iraq", alpha2: "iq", alpha3: "irq" },
    { countryCode: 372, country: "Ireland", alpha2: "ie", alpha3: "irl" },
    { countryCode: 833, country: "Isle of Man", alpha2: "im", alpha3: "imn" },
    { countryCode: 376, country: "Israel", alpha2: "il", alpha3: "isr" },
    { countryCode: 380, country: "Italy", alpha2: "it", alpha3: "ita" },
    { countryCode: 388, country: "Jamaica", alpha2: "jm", alpha3: "jam" },
    { countryCode: 392, country: "Japan", alpha2: "jp", alpha3: "jpn" },
    { countryCode: 832, country: "Jersey", alpha2: "je", alpha3: "jey" },
    { countryCode: 400, country: "Jordan", alpha2: "jo", alpha3: "jor" },
    { countryCode: 398, country: "Kazakhstan", alpha2: "kz", alpha3: "kaz" },
    { countryCode: 404, country: "Kenya", alpha2: "ke", alpha3: "ken" },
    { countryCode: 296, country: "Kiribati", alpha2: "ki", alpha3: "kir" },
    { countryCode: 408, country: "Korea (Democratic People's Republic of)", alpha2: "kp", alpha3: "prk" },
    { countryCode: 410, country: "Korea, Republic of", alpha2: "kr", alpha3: "kor" },
    { countryCode: 414, country: "Kuwait", alpha2: "kw", alpha3: "kwt" },
    { countryCode: 417, country: "Kyrgyzstan", alpha2: "kg", alpha3: "kgz" },
    { countryCode: 418, country: "Lao People's Democratic Republic", alpha2: "la", alpha3: "lao" },
    { countryCode: 428, country: "Latvia", alpha2: "lv", alpha3: "lva" },
    { countryCode: 422, country: "Lebanon", alpha2: "lb", alpha3: "lbn" },
    { countryCode: 426, country: "Lesotho", alpha2: "ls", alpha3: "lso" },
    { countryCode: 430, country: "Liberia", alpha2: "lr", alpha3: "lbr" },
    { countryCode: 434, country: "Libya", alpha2: "ly", alpha3: "lby" },
    { countryCode: 438, country: "Liechtenstein", alpha2: "li", alpha3: "lie" },
    { countryCode: 440, country: "Lithuania", alpha2: "lt", alpha3: "ltu" },
    { countryCode: 442, country: "Luxembourg", alpha2: "lu", alpha3: "lux" },
    { countryCode: 446, country: "Macao", alpha2: "mo", alpha3: "mac" },
    { countryCode: 450, country: "Madagascar", alpha2: "mg", alpha3: "mdg" },
    { countryCode: 454, country: "Malawi", alpha2: "mw", alpha3: "mwi" },
    { countryCode: 458, country: "Malaysia", alpha2: "my", alpha3: "mys" },
    { countryCode: 462, country: "Maldives", alpha2: "mv", alpha3: "mdv" },
    { countryCode: 466, country: "Mali", alpha2: "ml", alpha3: "mli" },
    { countryCode: 470, country: "Malta", alpha2: "mt", alpha3: "mlt" },
    { countryCode: 584, country: "Marshall Islands", alpha2: "mh", alpha3: "mhl" },
    { countryCode: 474, country: "Martinique", alpha2: "mq", alpha3: "mtq" },
    { countryCode: 478, country: "Mauritania", alpha2: "mr", alpha3: "mrt" },
    { countryCode: 480, country: "Mauritius", alpha2: "mu", alpha3: "mus" },
    { countryCode: 175, country: "Mayotte", alpha2: "yt", alpha3: "myt" },
    { countryCode: 484, country: "Mexico", alpha2: "mx", alpha3: "mex" },
    { countryCode: 583, country: "Micronesia (Federated States of)", alpha2: "fm", alpha3: "fsm" },
    { countryCode: 498, country: "Moldova, Republic of", alpha2: "md", alpha3: "mda" },
    { countryCode: 492, country: "Monaco", alpha2: "mc", alpha3: "mco" },
    { countryCode: 496, country: "Mongolia", alpha2: "mn", alpha3: "mng" },
    { countryCode: 499, country: "Montenegro", alpha2: "me", alpha3: "mne" },
    { countryCode: 500, country: "Montserrat", alpha2: "ms", alpha3: "msr" },
    { countryCode: 504, country: "Morocco", alpha2: "ma", alpha3: "mar" },
    { countryCode: 508, country: "Mozambique", alpha2: "mz", alpha3: "moz" },
    { countryCode: 104, country: "Myanmar", alpha2: "mm", alpha3: "mmr" },
    { countryCode: 516, country: "Namibia", alpha2: "na", alpha3: "nam" },
    { countryCode: 520, country: "Nauru", alpha2: "nr", alpha3: "nru" },
    { countryCode: 524, country: "Nepal", alpha2: "np", alpha3: "npl" },
    { countryCode: 528, country: "Netherlands", alpha2: "nl", alpha3: "nld" },
    { countryCode: 540, country: "New Caledonia", alpha2: "nc", alpha3: "ncl" },
    { countryCode: 554, country: "New Zealand", alpha2: "nz", alpha3: "nzl" },
    { countryCode: 558, country: "Nicaragua", alpha2: "ni", alpha3: "nic" },
    { countryCode: 562, country: "Niger", alpha2: "ne", alpha3: "ner" },
    { countryCode: 566, country: "Nigeria", alpha2: "ng", alpha3: "nga" },
    { countryCode: 570, country: "Niue", alpha2: "nu", alpha3: "niu" },
    { countryCode: 574, country: "Norfolk Island", alpha2: "nf", alpha3: "nfk" },
    { countryCode: 807, country: "North Macedonia", alpha2: "mk", alpha3: "mkd" },
    { countryCode: 580, country: "Northern Mariana Islands", alpha2: "mp", alpha3: "mnp" },
    { countryCode: 578, country: "Norway", alpha2: "no", alpha3: "nor" },
    { countryCode: 512, country: "Oman", alpha2: "om", alpha3: "omn" },
    { countryCode: 586, country: "Pakistan", alpha2: "pk", alpha3: "pak" },
    { countryCode: 585, country: "Palau", alpha2: "pw", alpha3: "plw" },
    { countryCode: 275, country: "Palestine, State of", alpha2: "ps", alpha3: "pse" },
    { countryCode: 591, country: "Panama", alpha2: "pa", alpha3: "pan" },
    { countryCode: 598, country: "Papua New Guinea", alpha2: "pg", alpha3: "png" },
    { countryCode: 600, country: "Paraguay", alpha2: "py", alpha3: "pry" },
    { countryCode: 604, country: "Peru", alpha2: "pe", alpha3: "per" },
    { countryCode: 608, country: "Philippines", alpha2: "ph", alpha3: "phl" },
    { countryCode: 612, country: "Pitcairn", alpha2: "pn", alpha3: "pcn" },
    { countryCode: 616, country: "Poland", alpha2: "pl", alpha3: "pol" },
    { countryCode: 620, country: "Portugal", alpha2: "pt", alpha3: "prt" },
    { countryCode: 630, country: "Puerto Rico", alpha2: "pr", alpha3: "pri" },
    { countryCode: 634, country: "Qatar", alpha2: "qa", alpha3: "qat" },
    { countryCode: 638, country: "Réunion", alpha2: "re", alpha3: "reu" },
    { countryCode: 642, country: "Romania", alpha2: "ro", alpha3: "rou" },
    { countryCode: 643, country: "Russian Federation", alpha2: "ru", alpha3: "rus" },
    { countryCode: 646, country: "Rwanda", alpha2: "rw", alpha3: "rwa" },
    { countryCode: 652, country: "Saint Barthélemy", alpha2: "bl", alpha3: "blm" },
    { countryCode: 654, country: "Saint Helena, Ascension and Tristan da Cunha", alpha2: "sh", alpha3: "shn" },
    { countryCode: 659, country: "Saint Kitts and Nevis", alpha2: "kn", alpha3: "kna" },
    { countryCode: 662, country: "Saint Lucia", alpha2: "lc", alpha3: "lca" },
    { countryCode: 663, country: "Saint Martin (French part)", alpha2: "mf", alpha3: "maf" },
    { countryCode: 666, country: "Saint Pierre and Miquelon", alpha2: "pm", alpha3: "spm" },
    { countryCode: 670, country: "Saint Vincent and the Grenadines", alpha2: "vc", alpha3: "vct" },
    { countryCode: 882, country: "Samoa", alpha2: "ws", alpha3: "wsm" },
    { countryCode: 674, country: "San Marino", alpha2: "sm", alpha3: "smr" },
    { countryCode: 678, country: "Sao Tome and Principe", alpha2: "st", alpha3: "stp" },
    { countryCode: 682, country: "Saudi Arabia", alpha2: "sa", alpha3: "sau" },
    { countryCode: 686, country: "Senegal", alpha2: "sn", alpha3: "sen" },
    { countryCode: 688, country: "Serbia", alpha2: "rs", alpha3: "srb" },
    { countryCode: 690, country: "Seychelles", alpha2: "sc", alpha3: "syc" },
    { countryCode: 694, country: "Sierra Leone", alpha2: "sl", alpha3: "sle" },
    { countryCode: 702, country: "Singapore", alpha2: "sg", alpha3: "sgp" },
    { countryCode: 534, country: "Sint Maarten (Dutch part)", alpha2: "sx", alpha3: "sxm" },
    { countryCode: 703, country: "Slovakia", alpha2: "sk", alpha3: "svk" },
    { countryCode: 705, country: "Slovenia", alpha2: "si", alpha3: "svn" },
    { countryCode: 90, country: "Solomon Islands", alpha2: "sb", alpha3: "slb" },
    { countryCode: 706, country: "Somalia", alpha2: "so", alpha3: "som" },
    { countryCode: 710, country: "South Africa", alpha2: "za", alpha3: "zaf" },
    { countryCode: 239, country: "South Georgia and the South Sandwich Islands", alpha2: "gs", alpha3: "sgs" },
    { countryCode: 728, country: "South Sudan", alpha2: "ss", alpha3: "ssd" },
    { countryCode: 724, country: "Spain", alpha2: "es", alpha3: "esp" },
    { countryCode: 144, country: "Sri Lanka", alpha2: "lk", alpha3: "lka" },
    { countryCode: 729, country: "Sudan", alpha2: "sd", alpha3: "sdn" },
    { countryCode: 740, country: "Suricountry", alpha2: "sr", alpha3: "sur" },
    { countryCode: 744, country: "Svalbard and Jan Mayen", alpha2: "sj", alpha3: "sjm" },
    { countryCode: 752, country: "Sweden", alpha2: "se", alpha3: "swe" },
    { countryCode: 756, country: "Switzerland", alpha2: "ch", alpha3: "che" },
    { countryCode: 760, country: "Syrian Arab Republic", alpha2: "sy", alpha3: "syr" },
    { countryCode: 158, country: "Taiwan, Province of China", alpha2: "tw", alpha3: "twn" },
    { countryCode: 762, country: "Tajikistan", alpha2: "tj", alpha3: "tjk" },
    { countryCode: 834, country: "Tanzania, United Republic of", alpha2: "tz", alpha3: "tza" },
    { countryCode: 764, country: "Thailand", alpha2: "th", alpha3: "tha" },
    { countryCode: 626, country: "Timor-Leste", alpha2: "tl", alpha3: "tls" },
    { countryCode: 768, country: "Togo", alpha2: "tg", alpha3: "tgo" },
    { countryCode: 772, country: "Tokelau", alpha2: "tk", alpha3: "tkl" },
    { countryCode: 776, country: "Tonga", alpha2: "to", alpha3: "ton" },
    { countryCode: 780, country: "Trinidad and Tobago", alpha2: "tt", alpha3: "tto" },
    { countryCode: 788, country: "Tunisia", alpha2: "tn", alpha3: "tun" },
    { countryCode: 792, country: "Turkey", alpha2: "tr", alpha3: "tur" },
    { countryCode: 795, country: "Turkmenistan", alpha2: "tm", alpha3: "tkm" },
    { countryCode: 796, country: "Turks and Caicos Islands", alpha2: "tc", alpha3: "tca" },
    { countryCode: 798, country: "Tuvalu", alpha2: "tv", alpha3: "tuv" },
    { countryCode: 800, country: "Uganda", alpha2: "ug", alpha3: "uga" },
    { countryCode: 804, country: "Ukraine", alpha2: "ua", alpha3: "ukr" },
    { countryCode: 784, country: "United Arab Emirates", alpha2: "ae", alpha3: "are" },
    { countryCode: 826, country: "United Kingdom of Great Britain and Northern Ireland", alpha2: "gb", alpha3: "gbr" },
    { countryCode: 840, country: "United States of America", alpha2: "us", alpha3: "usa" },
    { countryCode: 581, country: "United States Minor Outlying Islands", alpha2: "um", alpha3: "umi" },
    { countryCode: 858, country: "Uruguay", alpha2: "uy", alpha3: "ury" },
    { countryCode: 860, country: "Uzbekistan", alpha2: "uz", alpha3: "uzb" },
    { countryCode: 548, country: "Vanuatu", alpha2: "vu", alpha3: "vut" },
    { countryCode: 862, country: "Venezuela (Bolivarian Republic of)", alpha2: "ve", alpha3: "ven" },
    { countryCode: 704, country: "Viet Nam", alpha2: "vn", alpha3: "vnm" },
    { countryCode: 92, country: "Virgin Islands (British)", alpha2: "vg", alpha3: "vgb" },
    { countryCode: 850, country: "Virgin Islands (U.S.)", alpha2: "vi", alpha3: "vir" },
    { countryCode: 876, country: "Wallis and Futuna", alpha2: "wf", alpha3: "wlf" },
    { countryCode: 732, country: "Western Sahara", alpha2: "eh", alpha3: "esh" },
    { countryCode: 887, country: "Yemen", alpha2: "ye", alpha3: "yem" },
    { countryCode: 894, country: "Zambia", alpha2: "zm", alpha3: "zmb" },
    { countryCode: 716, country: "Zimbabwe", alpha2: "zw", alpha3: "zwe" },
];

export default countryListEN;

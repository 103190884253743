import { CompViewFilter } from "modules/CompViewFilter.enum";

export const generateSearchParams = (filters) => {
    const searchParams = new URLSearchParams();

    /**
     * Geo-box is something like: "52.100556000000005,5.645832999999989;52.100556000000005,5.645832999999989"
     * North and south should not be the same. West and east should not be the same. If either is, return true.
     * @returns {boolean}
     */
    const isGeoBoxTheSameNorthAndSouthOrWestAndEast = () => {
        const params = searchParams.get(CompViewFilter.GEO_BOX_FILTER);
        const splitParams = params?.replace(";", ",").split(",");

        return splitParams?.[0] === splitParams?.[2] && splitParams?.[1] === splitParams?.[3];
    };

    const setGeoBoxFilterToDefault = () => {
        searchParams.set(
            CompViewFilter.GEO_BOX_FILTER,
            "54.00440740288465,2.471535886718761;50.60401582468414,7.267068113281261"
        );
    };
    if (filters) {
        Object.keys(filters).forEach((key) => {
            if (key !== CompViewFilter.SIZE) searchParams.set(key, filters[key] || "");
        });
    }

    if (isGeoBoxTheSameNorthAndSouthOrWestAndEast()) {
        setGeoBoxFilterToDefault();
    }

    return searchParams;
};

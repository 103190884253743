import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";
import getType from "@kateinnovations/javascript-helpers/getType";
import { validations } from "@kateinnovations/javascript-helpers/validations";

const valuationRealEstateObjectDefinitionsApi = createAsyncThunk(
    "valuationEdit/valuationRealEstateObjectPropertiesApi",
    async (objectId, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();
        if (
            valuationEdit.realEstateObjectDefinitions.loading === false ||
            requestId !== valuationEdit.realEstateObjectDefinitions.currentRequestId
        )
            return;

        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = `${defaultConfig.api.valuation.realEstateObjectDefinitions
            .replace(/{{valuationId}}/g, valuationId)
            .replace(/{{objectId}}/g, objectId)}`;
        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });

            const result = Object.keys(responseData).reduce((data, key) => {
                const tempData = data;
                const { properties } = responseData[key];
                const reoProperties = properties.map((item) => {
                    const type = item.propertyDefinition.type.split("\\").reverse()[0];

                    const customValues =
                        (getType(item.propertyDefinition.customValues) === "object" &&
                            validations.isNotEmpty(item.propertyDefinition.customValues, true) &&
                            Object.keys(item.propertyDefinition.customValues).map(
                                (valueKey) => item.propertyDefinition.customValues[valueKey]
                            )) ||
                        [];
                    const possibleValues = [...customValues, ...item.propertyDefinition.possibleValues].map(
                        (option) => {
                            const label =
                                type === "ValueSelectType"
                                    ? `property.${item.propertyDefinition.propertyKey}.values.${option.value}`
                                    : option.value;
                            const value = option.id;

                            const optionObject = {
                                label,
                                value,
                            };

                            return optionObject;
                        }
                    );

                    const propertyObject = {
                        ...item.propertyDefinition,
                        value: item.value,
                        possibleValues,
                    };
                    return propertyObject;
                });

                const valueObject = {
                    ...responseData[key],
                    title: responseData[key].i18NKey,
                    properties: reoProperties,
                    updating: false,
                };
                delete valueObject.i18NKey;
                tempData.push(valueObject);
                return tempData;
            }, []);
            return { objectId, reoDefinitions: result };
        }

        return rejectWithValue({ error });
    }
);

export default valuationRealEstateObjectDefinitionsApi;

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class SwitchIndex extends Expression {
    calculate() {
        if (this.items[this.index]) {
            return this.items[this.index].calculate();
        }

        return null;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default SwitchIndex;

/* eslint-disable prefer-destructuring */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationPropertyNotesApi = createAsyncThunk(
    "valuationEdit/valuationPropertyNotesApi",
    async (data, { getState, requestId, rejectWithValue }) => {
        const { auth, valuationEdit } = getState();

        if (valuationEdit.notes.loading === false || requestId !== valuationEdit.notes.currentRequestId) return;

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.notes.getAll.replace("{{valuationId}}", valuationId);
        try {
            const getResponse = await apiCall({
                url,
                method: "GET",
                headers: {
                    accept: "application/json",
                    "content-Type": "application/json",
                    authorization: `Bearer ${jwt}`,
                },
                credentials: "omit",
                mode: "cors",
            });

            if (getResponse.ok) {
                const response = await getResponse.json();
                const responseData = response.data;
                const subCategories = valuationEdit.categories?.propertiesBySubCategory;
                const subCategoriesList = Object.entries(subCategories);
                const getSubCategoryIdByNote = (propertyId) => {
                    let match;

                    subCategoriesList.map((subCategory) => {
                        return subCategory[1]?.properties?.map((property) => {
                            if (property === propertyId) {
                                match = {
                                    subCategoryId: subCategory[0],
                                    subCategoryTitle: subCategory[1]?.title,
                                };
                            }

                            return subCategory;
                        });
                    });

                    return match;
                };

                const tempData = responseData.map((note) => {
                    let tempNote = note;
                    const subCategory = getSubCategoryIdByNote(note.subEntityId);
                    if (subCategory) tempNote = { ...tempNote, ...subCategory };

                    return tempNote;
                });
                return tempData;
            }

            if (!getResponse.ok) {
                const response = await getResponse.json();
                const { error } = response;

                return rejectWithValue({ error });
            }
        } catch (err) {
            rejectWithValue("unexpected Error. KATE has been notified");
        }
    }
);

export default valuationPropertyNotesApi;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationCategoriesApi = createAsyncThunk(
    "valuationEdit/valuationCategoriesApi",
    async (data, { dispatch, getState, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.categoryWithProperties.replace("{{valuationId}}", valuationId);
        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });
            const responseDataKeys = Object.keys(responseData);

            const returnValueObject = responseDataKeys.reduce(
                (categoriesObject, categoryKey) => {
                    const tempValueObject = categoriesObject;

                    const { id, translationKey, order, icon, key, subCategories } = responseData[categoryKey];

                    const category = {
                        id,
                        title: key,
                        translationKey,
                        icon,
                        order,
                    };

                    const subCategoriesKeys = Object.keys(subCategories);

                    const subCategoriesData = subCategoriesKeys.reduce(
                        (subCategoriesObject, subCategoryKey) => {
                            const tempSubCategoriesObject = subCategoriesObject;
                            const subCategoryObject = subCategories[subCategoryKey];
                            const newSubCategoryObject = {
                                id: subCategoryObject.id,
                                categoryId: subCategoryObject.categoryId,
                                title: subCategoryKey,
                                translationKey: subCategoryObject.translationKey,
                                order: subCategoryObject.order,
                            };

                            tempSubCategoriesObject.items.push(newSubCategoryObject);

                            tempSubCategoriesObject.entities[subCategoryObject.id] = {
                                properties: subCategoryObject.properties,
                                title: subCategoryKey,
                            };

                            return tempSubCategoriesObject;
                        },
                        { items: [], entities: {} }
                    );

                    subCategoriesData.items.sort((previous, current) => (previous.order < current.order ? -1 : 1));

                    const propertiesBySubCategory = {
                        ...tempValueObject.propertiesBySubCategory,
                        ...subCategoriesData.entities,
                    };

                    tempValueObject.categories.items.push(category);
                    tempValueObject.categories.entities[id] = {
                        subCategories: { ...subCategoriesData },
                        translationKey,
                    };
                    tempValueObject.propertiesBySubCategory = propertiesBySubCategory;

                    delete tempValueObject.categories.entities[id].subCategories.entities;

                    return tempValueObject;
                },
                { categories: { items: [], entities: {} }, propertiesBySubCategory: {} }
            );

            returnValueObject.categories.items.sort((previous, current) => (previous.order < current.order ? -1 : 1));

            return returnValueObject;
        }

        return rejectWithValue({ error });
    },
    {
        condition: (data, { getState }) => {
            const { valuationEdit } = getState();

            if (valuationEdit.categories.loading === true) {
                return false;
            }
        },
    }
);

export default valuationCategoriesApi;

import { fromJS } from "immutable";

import * as ReduxActions from "../actions/action-transaction";

const emptyState = fromJS({
    transactions: null,
    currentSelected: null,
});

export default (state = emptyState, action) => {
    switch (action.type) {
        case ReduxActions.GET_TRANSACTION_RESULT().type:
            const transactions = state.get("transactions");

            if (!transactions) {
                return state.set(
                    "transactions",
                    fromJS({
                        [action.payload.id]: action.payload.data,
                    })
                );
            }

            const currentTransaction = transactions.get(action.payload.id);
            if (currentTransaction) {
                return state.setIn(["transactions", action.payload.id], fromJS(action.payload.data));
            }

            const { ...newTransactions } = transactions.toJS();
            newTransactions[action.payload.id] = action.payload.data;
            return state.set("transactions", fromJS(newTransactions));

        case ReduxActions.TRANSACTION_SET_SELECTED().type:
            return state.set("currentSelected", action.payload.selectedTransactions);

        case ReduxActions.TRANSACTION_EXPORTED_FLAG().type:
            return state.set("transactionsExported", action.payload.transactionsExported);

        default:
            return state;
    }
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const customerApi = createAsyncThunk(
    "customer/customerApi",
    async (value, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, customer, userProfile } = getState();

        if (customer.loading === false || requestId !== customer.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const url = defaultConfig.api.customer;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if ([401, 403].includes(getResponse.status)) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const { data } = response || {};
            const dataConverted = await convertObjectKeys({ dataObject: data, convertType: "camelCase" });

            return dataConverted;
        }

        return rejectWithValue({ error });
    }
);

export default customerApi;

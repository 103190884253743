export default {
    primary: "#005b86",
    primaryRgba: "rgba(0, 91, 134, 0.8)",
    secondary: "#337ab7",
    background: "#f3f6fb",
    backgroundLight: "#fefefe",
    blueish: "#b7d0e5",
    success: "#50af31",
    gray: "#b5b6b3",
    dark: "#333",
    alert: "#EF2729",
    grayAlt: "#C2C2C2",
    attention: "#ff9c00",
    compview: {
        category: {
            sales: "#ef716c",
            rental: "#ff9c00",
            valuation: "#67c88c",
            lonResSales: "#d90070",
            lonResArchive: "#0d87d9",
            brainbay: "rgb(12, 71, 167)",
        },
    },
    datatable: {
        footerBackground: "#f5f5f5",
        footerColor: "#404040",
    },
    droppable: "aliceblue",
};

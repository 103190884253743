import getEnvValue from "helpers/getEnvValue";

const defaultPageData = {
    siteUrl: getEnvValue("APP_DOMAIN"),
    htmlAttribute: {
        lang: getEnvValue("LOCAL_LANGUAGE") || "en",
        itemscope: undefined,
        itemtype: "http://schema.org/WebPage",
    },
    defaultTitle: getEnvValue("PAGE_TITLE"),
    description: getEnvValue("PAGE_DESCRIPTION"),
    separator: " | ",
};

export default defaultPageData;

import countryListNL from "./nl";
import countryListEN from "./en";
import countryListDE from "./de";
import countryListFR from "./fr";

const countryList = {
    nl: countryListNL,
    en: countryListEN,
    de: countryListDE,
    fr: countryListFR,
};

const countryListForSelect = countryListEN.map(({ alpha2 }) => {
    const value = alpha2.toUpperCase();
    const label = `country.${value}`;

    return {
        value,
        label,
    };
});

export { countryList, countryListForSelect };

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { reducer as toastrReducer } from "react-redux-toastr";
import reducerValuation from "react/redux/reducers/valuation-reducer";
import reducerConfigSettings from "react/redux/reducers/config-settings-reducer";
import portfolioReducer from "react/redux/reducers/portfolio-reducer";
import importerReducer from "react/redux/reducers/importer-reducer";
import importReducer from "react/redux/reducers/import-reducer";
import errorReducer from "react/redux/reducers/error-reducer";
import modalReducer from "react/redux/reducers/modal-reducer";
import BatchActionReducer from "react/redux/reducers/batch-action-reducer";
import TransactionReducer from "react/redux/reducers/transaction-reducer";
import BatchActionsOverviewReducer from "react/redux/reducers/batch-actions-overview-reducer";

import { versionReducer } from "features/Version/versionSlice";
import { applicationLoaderReducer } from "features/ApplicationLoader/applicationLoaderSlice";
import { authenticationReducer } from "features/authentication/authenticationSlice";
import { functionalityReducer } from "features/functionality/functionalitySlice";
import { configSettingsReducer } from "features/configSettings/configSettingsSlice";
import { menuReducer } from "features/Menu/menuSlice";
import { translationsReducer } from "features/translations/translationsSlice";
import { newsReducer } from "features/News/newsSlice";
import { userProfileReducer } from "features/userProfile/userProfileSlice";
import { infoDataBoxReducer } from "features/InfoDataBox/infoDataBoxSlice";
import { compViewReducer } from "features/compView/compViewSlice";
import { customerReducer } from "features/customer/customerSlice";
import { valuationReducer } from "features/valuation/valuationSlice";
import { dynamicFieldsReducer } from "features/dynamicFields/dynamicFiledsSlice";

import pageDataReducer from "features/calculationModel/redux/reducer/pageData";
import pageSectionsReducer from "features/calculationModel/redux/reducer/pageSections";
import pageModalReducer from "features/calculationModel/redux/reducer/pageModal";
import overviewData from "features/calculationModel/redux/reducer/overviewData";
import calculationData from "features/calculationModel/redux/reducer/calculationData";
import navigationData from "features/calculationModel/redux/reducer/navigationData";
import cells from "features/calculationModel/redux/reducer/cells";

import middlewareValuation from "react/redux/middleware/valuation-middleware";
import middlewarePortfolio from "react/redux/middleware/portfolio-middleware";
import middlewareImporter from "react/redux/middleware/importer-middleware";
import middlewareImport from "react/redux/middleware/import-middleware";
import middleWareBatchAction from "react/redux/middleware/batch-action-middleware";
import middleWareTransaction from "react/redux/middleware/transaction-middleware";

import api from "features/calculationModel/redux/middleware/api";
import { v2Reducer } from "../features/v2/v2Slice";

const reducer = combineReducers({
    configSettingsImmutable: reducerConfigSettings,
    error: errorReducer,
    valuation: reducerValuation,
    toastr: toastrReducer,
    portfolio: portfolioReducer,
    importer: importerReducer,
    importState: importReducer,
    modal: modalReducer,
    batchActionState: BatchActionReducer,
    TransactionState: TransactionReducer,
    batchActionsOverview: BatchActionsOverviewReducer,
    applicationLoader: applicationLoaderReducer,
    auth: authenticationReducer,
    compView: compViewReducer,
    configSettings: configSettingsReducer,
    functionality: functionalityReducer,
    infoDataBox: infoDataBoxReducer,
    menu: menuReducer,
    news: newsReducer,
    translations: translationsReducer,
    userProfile: userProfileReducer,
    customer: customerReducer,
    valuationEdit: valuationReducer,
    pageData: pageDataReducer,
    pageSections: pageSectionsReducer,
    pageModal: pageModalReducer,
    overviewData,
    navigationData,
    calculationData,
    cells,
    dynamicFields: dynamicFieldsReducer,
    version: versionReducer,
    v2: v2Reducer,
});

const rootReducer = (state, action) => {
    if (action.type === "authentication/resetAuth") return reducer(undefined, action);

    return reducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {},
            immutableCheck: false,
            serializableCheck: false,
        }).concat(
            middlewareValuation,
            middlewarePortfolio,
            middlewareImporter,
            middlewareImport,
            middleWareBatchAction,
            middleWareTransaction,
            api
        ),
    devTools: process.env.NODE_ENV === "development",
});

export default store;

export function RETRIEVE_CONFIG_SETTINGS() {
    return {
        type: "RETRIEVE_CONFIG_SETTINGS",
        payload: {},
    };
}

export function GET_MODULE_CONFIG() {
    return {
        type: "GET_MODULE_CONFIG",
    };
}

export function GET_MODULE_CONFIG_RESULT({ result } = {}) {
    return {
        type: "GET_MODULE_CONFIG_RESULT",
        payload: { result },
    };
}

export function RETRIEVE_CONFIG_SETTINGS_RESULT(data) {
    return {
        type: "RETRIEVE_CONFIG_SETTINGS_RESULT",
        payload: {
            data,
        },
    };
}

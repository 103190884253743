const generateRangeConfig = (
    title,
    field,
    external = false,
    hidden = false,
    conField = "",
    conValue = "",
    displayType = "number"
) => {
    return {
        title,
        field,
        type: "range",
        displayType: displayType,
        condition: {
            default_hidden: hidden,
            field: conField,
            value: conValue,
        },
        settings: {
            default_hidden: hidden,
            required: true,
            negative: false,
            load_external_options: external,
            load_external_data_type: "number",
            enabled: true,
        },
        options: {
            low: 0,
            high: 1,
            between: [],
        },
    };
};

const generateDateTimeConfig = (title, field, external = false, hidden = false, conField = "", conValue = "") => {
    return {
        title,
        field,
        type: "date",
        condition: {
            default_hidden: hidden,
            field: conField,
            value: conValue,
        },
        settings: {
            default_hidden: hidden,
            required: true,
            negative: false,
            load_external_options: external,
            load_external_data_type: "date",
            enabled: true,
        },
    };
};

const generateFuzzyConfig = (
    title,
    field,
    hidden = false,
    conField = "",
    conValue = "",
    required = false,
    negative = false,
    external = false,
    fieldType = "text"
) => {
    return {
        title,
        field,
        type: "fuzzy",
        condition: {
            default_hidden: hidden,
            field: conField,
            value: conValue,
        },
        settings: {
            enabled: true,
            field_type: fieldType,
            required: required,
            negative: negative,
            load_external_data_type: "text",
            load_external_options: external,
        },
    };
};

const generateExactRequiredConfig = (
    title,
    field,
    hidden = false,
    conField = "",
    conValue = "",
    required = false,
    negative = false,
    external = false,
    fieldType = "text"
) => {
    return {
        title,
        field,
        type: "exact",
        condition: {
            default_hidden: hidden,
            field: conField,
            value: conValue,
        },
        settings: {
            enabled: true,
            field_type: fieldType,
            required: required,
            negative: negative,
            load_external_options: external,
            load_external_data_type: "text",
        },
    };
};

const generateQueryConfig = (
    title,
    field,
    hidden = false,
    conField = "",
    conValue = "",
    required = false,
    negative = false,
    external = false
) => {
    return {
        title,
        field,
        type: "query",
        condition: {
            default_hidden: hidden,
            field: conField,
            value: conValue,
        },
        settings: {
            enabled: true,
            field_type: "text",
            required: required,
            negative: negative,
            load_external_options: external,
            load_external_data_type: "text",
        },
    };
};

const generateSelectConfig = (
    title,
    field,
    options,
    hidden = false,
    conditionField = false,
    conditionValue = false,
    external = false,
    translationPrefix,
    maxValues
) => {
    return {
        title: title,
        field: field,
        type: "select",
        condition: {
            default_hidden: hidden,
            field: conditionField,
            value: conditionValue,
        },
        settings: {
            field_type: "text",
            translation_prefix: translationPrefix,
            enabled: !hidden,
            load_external_data_type: "text",
            load_external_options: external,
            default_hidden: hidden,
            required: true,
            negative: false,
            maxValues: maxValues,
        },
        options,
    };
};

export {
    generateRangeConfig,
    generateDateTimeConfig,
    generateFuzzyConfig,
    generateExactRequiredConfig,
    generateQueryConfig,
    generateSelectConfig,
};

export function MODAL_SET_REF(ref, modalType) {
    return {
        type: "MODAL_SET_REF",
        payload: {
            ref,
            modalType,
        },
    };
}

export function MODAL_CLEAR_REF() {
    return {
        type: "MODAL_CLEAR_REF",
        payload: {},
    };
}

import produce from "immer";

import { LOAD_OVERVIEW_DATA, REQUEST, SUCCESS, FAILURE, SET_REDIRECT_DATA, SET_MANAGEMENT_DATA, RESET_OVERVIEW_DATA } from "../constants";

const initialState = {
    data: [],
    management: null,
    changedAt: null,
    loading: false,
    loaded: false,
    error: null,
    redirect: null,
};

export default produce((draft = initialState, action) => {
    const { type, data, error, redirect } = action;

    switch (type) {
        case RESET_OVERVIEW_DATA: {
           return initialState;
        }
        case LOAD_OVERVIEW_DATA + REQUEST: {
            draft.error = null;
            draft.loading = true;
            break;
        }
        case LOAD_OVERVIEW_DATA + SUCCESS: {
            const tableNames = Object.keys(data.tables)
            const tables = tableNames?.map((tableName, index) => {
                return { 
                    name: tableName, 
                    data: Object.values(data.tables)?.[index] 
                } 
            })
            draft.loading = false;
            draft.loaded = true;
            draft.data = [ ...draft.data, ...tables ];
            break;
        }
        case LOAD_OVERVIEW_DATA + FAILURE: {
            draft.loading = false;
            draft.loaded = false;
            draft.error = error;
            break;
        }
        case SET_REDIRECT_DATA: {
            draft.redirect = redirect;
        }
        case SET_MANAGEMENT_DATA: {
            draft.management = data;
        }
        default:
            return draft;
    }
});

import * as ReduxActions from "../actions/action-importer";
import { v4 as uuid } from "uuid";
import { ERROR_SET } from "../actions/action-errors";
import getEnvValue from "helpers/getEnvValue";

const middleWareImporter = (store) => (next) => (action) => {
    const base = getEnvValue("KATE_CORE_URL");

    switch (action.type) {
        case ReduxActions.IMPORTER_GET_PROPERTIES().type:
            const propertiesExists = store.getState().importer.getIn([action.payload.propertiesName]);

            if (!propertiesExists) {
                const propertiesURL = base + action.payload.propertiesURL;

                window.API.getClient()
                    .get(propertiesURL)
                    .then(({ data: { data: result } }) => {
                        let properties;
                        if (action.payload.type === "transaction") {
                            properties = Object.values(result)
                                .map((item) => item.sub_category)
                                .map((item) => Object.values(item))
                                .map((item) => item.map((item) => item.properties))
                                .flat()
                                .flat();
                        } else if (action.payload.type === "valuation") {
                            properties = Object.entries(result)
                                .filter((category) => category[0] !== "system")
                                .map((category) => category[1].propertyDefinitions)
                                .flat()
                                .map((item) => {
                                    item["mapped"] = false;
                                    return item;
                                });
                        }

                        store.dispatch(
                            ReduxActions.IMPORTER_GET_PROPERTIES_RESULT({
                                propertiesName: action.payload.propertiesName,
                                properties,
                            })
                        );
                    });
            }

            next(action);
            break;
        case ReduxActions.IMPORTER_IMPORT().type:
            const POST_ENDPOINT = base + action.payload.postURL;
            const { importItems, type, transactionType } = action.payload;

            let postImportItems = importItems;
            if (type === "transaction") {
                const transactionImportItems = {};
                for (let b = 0; b < importItems.length; b++) {
                    let tempImportItem = importItems[b];
                    tempImportItem["transactionType"] = transactionType;
                    transactionImportItems[uuid()] = importItems[b];
                }
                postImportItems = transactionImportItems;
            }

            window.API.getClient()
                .post(POST_ENDPOINT, {
                    ...postImportItems,
                })
                .then(({ data: { data: result } }) => {
                    action.payload.onSuccess(result);
                })
                .catch((error) => {
                    store.dispatch(ERROR_SET(error.response, "import"));
                    if (error && error.response && error.response.data && error.response.data.error) {
                        action.payload.onError(error.response.data.error[0]);
                    } else {
                        action.payload.onError("An unknown error occurred, please contact the helpdesk.");
                    }
                });

            next(action);
            break;
        default:
            next(action);
            break;
    }
};

export default middleWareImporter;

import BuildExpression from "../BuildExpression";
import Expression from "./Expression";

class AreEmpty extends Expression {
    calculate() {
        let result = true;

        this.items.forEach((item) => {
            const itemResult = item.calculate();

            if (
                !(
                    itemResult === undefined ||
                    itemResult === "" ||
                    itemResult === null ||
                    itemResult === 0 ||
                    itemResult === "0"
                )
            ) {
                result = false;
            }
        });

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default AreEmpty;

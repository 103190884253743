import { fromJS } from "immutable";
import { ERROR_CLEAR, ERROR_SET } from "../actions/action-errors";

const emptyState = fromJS({
    error: fromJS({}),
    status: false,
});

export default function (state = emptyState, action) {
    switch (action.type) {
        case ERROR_SET().type:
            return state.merge({
                error: action.payload,
                status: true,
            });

        case ERROR_CLEAR().type:
            return state.merge({
                error: {},
                status: false,
            });

        default:
            return state;
    }
}

import Component from "../Component";
import BuildExpression from "../BuildExpression";

class Not extends Component {
    constructor(data, expression, index, tableIndex) {
        super();

        this.data = data;
        this.expression = expression;
        this.index = index;
        this.tableIndex = tableIndex;
    }

    calculate() {
        if (this.expression?.argument) {
            return !BuildExpression.getClass(
                this.data,
                this.expression?.argument,
                this.index,
                this.tableIndex
            ).calculate();
        }

        return true;
    }
}

export default Not;

import React, { useRef, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import FocusTrap from "focus-trap-react";
import { keyEvent } from "@kateinnovations/javascript-helpers";
import Portal from "./Portal";
import styles from "./resources/styles/modal.module.scss";

const Modal = ({ open, onCloseHandler, locked, children, header, footer, showCloseButton, containerClass }) => {
    const [isActive, setIsActive] = useState(false);
    const backdrop = useRef(null);

    const closeModal = useCallback(() => {
        if (locked) return undefined;

        onCloseHandler(false);
    }, [locked, onCloseHandler]);

    const onEventHandlerClose = ({ target, currentTarget }) => target === currentTarget && !locked && closeModal();
    const onClickHandlerCloseButton = () => !locked && closeModal();
    const onTransitionEndHandler = () => setIsActive(open);

    useEffect(() => {
        const { current } = backdrop || {};
        let ignore = false;

        const onKeyUpHandler = ({ type, code }) => type === "keyup" && keyEvent.esc === code && !locked && closeModal();

        const timeoutFunction = () => {
            setTimeout(() => {
                if (!ignore && open) {
                    document.activeElement.blur();
                    setIsActive(open);
                }
            }, 10);
        };

        if (current && !ignore) window.document.addEventListener("keyup", onKeyUpHandler);

        if (!ignore) timeoutFunction();

        return () => {
            ignore = true;

            if (current && ignore) window.document.removeEventListener("keyup", onKeyUpHandler);
            if (ignore) clearTimeout(timeoutFunction);
        };
    }, [open, locked, closeModal]);

    if (!open && !isActive) return null;

    return (
        <Portal className="modal-portal">
            <FocusTrap>
                <div
                    ref={backdrop}
                    className={styles.backdrop}
                    variant="backdrop"
                    state={isActive && open ? "is-active" : "is-hidden"}
                    onTransitionEnd={onTransitionEndHandler}
                    onClick={onEventHandlerClose}
                    onKeyUp={onEventHandlerClose}
                    role="button"
                    tabIndex="0"
                >
                    <section className={`${styles.container} ${containerClass}`}>
                        <div className={styles.unit}>
                            {!!header && <header className={styles.header}>{header}</header>}
                            <article className={styles.content}>{children}</article>
                            {!!footer && <footer className={styles.footer}>{footer}</footer>}
                        </div>
                        {showCloseButton && (
                            <div className={styles.closeButtonContainer}>
                                <button
                                    className={styles.button}
                                    type="button"
                                    onClick={onClickHandlerCloseButton}
                                    disabled={locked}
                                >
                                    <i className="fas fa-times" />
                                    <span className={styles.text}>close modal</span>
                                </button>
                            </div>
                        )}
                    </section>
                </div>
            </FocusTrap>
        </Portal>
    );
};

Modal.defaultProps = {
    header: undefined,
    footer: undefined,
    open: false,
    locked: false,
    showCloseButton: true,
};

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    header: PropTypes.node,
    footer: PropTypes.node,
    onCloseHandler: PropTypes.func.isRequired,
    open: PropTypes.bool,
    locked: PropTypes.bool,
    showCloseButton: PropTypes.bool,
};

export default Modal;

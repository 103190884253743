import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import getEnvValue from "helpers/getEnvValue";
import { apiCall } from "@kateinnovations/javascript-helpers";

import defaultConfig from "config/constants/defaultConfig";

const menuApi = createAsyncThunk(
    "menu/menuApi",
    async (value, { dispatch, getState, signal, requestId, rejectWithValue }) => {
        const { menu, auth, userProfile } = getState();

        if (menu.loading === false || requestId !== menu.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const url = defaultConfig.api.menuItems;
        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });
        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();

        if (getResponse.ok && contentType === "application/json") {
            const response = await getResponse.json();
            const coreUrl = getEnvValue("KATE_CORE_URL");

            const data = Object.keys(response.data).map((key, index) => {
                const currentItem = response.data[key];
                const currentPath = currentItem.path;

                if (currentItem.coreRoute) {
                    currentItem.path = `${coreUrl}${currentPath}`;
                    currentItem.target = `_top`;
                }

                currentItem.id = index + 1;
                delete currentItem.coreRoute;
                delete currentItem.coreIcon;
                delete currentItem.type;

                return currentItem;
            });

            data.sort((object1, object2) => {
                if (object1.order === object2.order) {
                    return object1.translation < object2.translation ? -1 : 1;
                }
                return object1.order > object2.order ? 1 : -1;
            });

            return data;
        }

        dispatch(setAuthError("authenticationError"));
        return rejectWithValue({ error });
    }
);

export default menuApi;

import Component from "../Component";
import jp from "jsonpath";

class JPList extends Component {
    constructor(data, path) {
        super();

        this.data = data;
        this.path = path?.argument;
    }

    calculate() {
        return jp.query(this.data, this.path);
    }
}

export default JPList;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import {
    apiCall,
    convertObjectKeys,
    camelCase,
    convertToGivenSeparator,
    validations,
} from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationPropertyDefinitionsApi = createAsyncThunk(
    "valuationEdit/valuationPropertyDefinitionsApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        if (
            valuationEdit.propertyDefinitions.loading === false ||
            requestId !== valuationEdit.propertyDefinitions.currentRequestId
        )
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.propertyDefinitions.replace("{{valuationId}}", valuationId);
        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const result = response.data.properties?.reduce((resultObject, propertyDefinition, index) => {
                const tempPropertyDefinition = propertyDefinition;
                const tempResultObject = resultObject;
                const type = camelCase(propertyDefinition.type.split("\\").pop());
                const name = convertToGivenSeparator(propertyDefinition.propertyKey, "-")?.toLowerCase();
                const postIcons =
                    tempPropertyDefinition.postIcons.map((postIcon, postIconIndex) => {
                        const postIconData =
                            postIcon?.data?.map((itemData, itemDataIndex) => {
                                const newItemData = {
                                    ...itemData,
                                    id: itemDataIndex + 1,
                                };
                                return newItemData;
                            }) || undefined;

                        const newDataObject = {
                            ...postIcon,
                            id: postIconIndex + 1,
                            data: postIconData,
                        };

                        return newDataObject;
                    }) || null;

                let value =
                    validations.isJSONString(response.data.properties[index].value) &&
                    !["customTextType", "textType"].includes(type)
                        ? JSON.parse(response.data.properties[index].value)
                        : response.data.properties[index].value;

                let { options } = propertyDefinition || {};
                if (propertyDefinition.type === "fixedTableType") options = response.data.properties[index].options;

                if (["null", "false", "true", "undefined", "0"].includes(response.data.properties[index].value))
                    value = response.data.properties[index].value;

                delete tempPropertyDefinition.value;
                delete tempPropertyDefinition.postIcons;
                delete tempPropertyDefinition.type;
                delete tempPropertyDefinition.name;
                delete tempPropertyDefinition.options;

                const propertyObject = {
                    ...tempPropertyDefinition,
                    options,
                    value,
                    type,
                    name,
                    postIcons,
                    updating: false,
                };

                tempResultObject[propertyDefinition.id] = propertyObject;

                return tempResultObject;
            }, {});

            return result;
        }

        return rejectWithValue({ error });
    }
);

export default valuationPropertyDefinitionsApi;

import enTranslationMessages from "./translations/en.json";
import nlTranslationMessages from "./translations/nl.json";
import deTranslationMessages from "./translations/de.json";
import frTranslationMessages from "./translations/fr.json";

const translationMessages = {
    nl: nlTranslationMessages,
    en: enTranslationMessages,
    de: deTranslationMessages,
    fr: frTranslationMessages,
};

export default translationMessages;

import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Round extends Expression {
    calculate() {
        if (this.items.length === 2) {
            return Number(this.items[0].calculate().toFixed(this.items[1].calculate()));
        }

        return 0;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default Round;

import addressFormat from "./addressFormat";
import { countryList, countryListForSelect } from "./countryList";
import getPageTitle from "./getPageTitle";
import getEnvValue from "./getEnvValue";
import { getLatLngCenterPoint, getDistanceBetweenPoints, getLatLngByAddress } from "./googleMaps";
import mobileDetector from "./mobileDetector";
import { fetchAndUpdateValuationProperties } from "./updateValuationProperties/fetchAndUpdateValuationProperties";
import { uncapitalizeFirstLetter } from './UncapitalizeFirstLetter'

const isJSON = (value) => {
    try {
        JSON.parse(value);
    } catch (e) {
        return false;
    }
    return true;
};

export {
    addressFormat,
    countryList,
    countryListForSelect,
    getPageTitle,
    getEnvValue,
    getLatLngCenterPoint,
    getDistanceBetweenPoints,
    getLatLngByAddress,
    isJSON,
    mobileDetector,
    fetchAndUpdateValuationProperties,
    uncapitalizeFirstLetter
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    entities: {
        version: undefined,
        update: false,
    },
};

const version = createSlice({
    name: "ApplicationVersion",
    initialState,
    reducers: {
        setVersion: (state, action) => {
            const newState = state;
            newState.entities.version = action.payload;
            return newState;
        },
        updateVersion: (state, action) => {
            const newState = state;
            newState.entities.update = action.payload;
            return newState;
        },
        resetVersion() {
            return initialState;
        },
    },
});

const { resetVersion, updateVersion, setVersion } = version.actions;
const versionReducer = version.reducer;

export { versionReducer, resetVersion, updateVersion, setVersion };

import * as ReduxActions from "../actions/action-batch-action";
import { MODAL_CLEAR_REF, MODAL_SET_REF } from "../actions/action-modal";
import { ERROR_SET } from "../actions/action-errors";
import { toastr } from "react-redux-toastr";
import { setAuthError } from "features/authentication/authenticationSlice";
import getEnvValue from "helpers/getEnvValue";
import { validations, apiCall } from "@kateinnovations/javascript-helpers";
import getType from "@kateinnovations/javascript-helpers/getType";

const middleWareBatchAction = (store) => (next) => (action) => {
    const base = getEnvValue("KATE_CORE_URL");
    const { jwt } = store.getState().auth || {};
    let url = `${base}/api/v1/imports/bulk-edit-valuations`;
    let payloadToSend = null;

    switch (action.type) {
        case ReduxActions.FETCH_BATCH_ACTION_RESOURCES().type:
            if (action.payload.type["mainType"] === "bulk-edit") {
                url = `${url}/properties`;
                payloadToSend = {
                    statuses: action.payload.statuses,
                };
            }

            if (validations.isNotEmpty(payloadToSend, true)) {
                apiCall({
                    url,
                    method: "POST",
                    headers: {
                        authorization: `Bearer ${jwt}`,
                    },
                    credentials: "omit",
                    mode: "cors",
                    body: payloadToSend,
                })
                    .then((response) => {
                        const contentType = response?.headers?.get("content-type")?.split(";")?.shift();
                        const isJsonResponse = contentType === "application/json";

                        if (response.status === 401) {
                            store.dispatch(setAuthError("authenticationError"));
                            throw ["AUTHORIZATION_JWT_MISSING"];
                        }

                        if (isJsonResponse) return response.json();

                        throw [response.message];
                    })
                    .then((result) => {
                        if (getType(result.data) === "array" || validations.isNotEmpty(result.error, true)) {
                            const error = validations.isEmpty(result.error) ? result.data : result.error;
                            throw error;
                        }

                        if (getType(result.data) === "object") return result.data;
                    })
                    .then((result) => {
                        const { orderedSubCategories } = result || {};
                        const properties = result.properties?.map((property) => {
                            const tempProperty = property;
                            let possibleValues = [];

                            if (
                                validations.isNotEmpty(property.possibleValues, true) &&
                                getType(property.possibleValues) === "array"
                            ) {
                                possibleValues = property.possibleValues.map((selectOption) => {
                                    const label = `property.${property.propertyKey}.values.${selectOption.value}`;
                                    const value = selectOption.id;
                                    const active = selectOption.status === "ACTIVE";

                                    const returnValue = {
                                        label,
                                        value,
                                        active,
                                    };

                                    return returnValue;
                                });
                            }

                            if (
                                validations.isNotEmpty(property.customValues, true) &&
                                getType(property.customValues) === "object"
                            ) {
                                const { customValues } = property || {};
                                const customValuesKeys = Object.keys(customValues || {});
                                possibleValues = customValuesKeys
                                    .map((key) => {
                                        const selectOption = customValues[key];
                                        const label = selectOption.value;
                                        const value = selectOption.id;

                                        const returnValue = {
                                            label,
                                            value,
                                        };

                                        return returnValue;
                                    })
                                    .sort((previous, current) => (previous.label < current.label ? -1 : 1));
                            }

                            delete tempProperty.customValues;
                            tempProperty.possibleValues = possibleValues;

                            return tempProperty;
                        });

                        const batchActionResources = {
                            orderedSubCategories,
                            properties,
                            statuses: [...payloadToSend.statuses],
                        };

                        store.dispatch(
                            ReduxActions.FETCH_BATCH_ACTION_RESOURCES_RESULT({ data: batchActionResources })
                        );
                    })
                    .catch((error) => {
                        const ErrorMessage = () => {
                            return (
                                <>
                                    {error.length && (
                                        <ul className="list-container">
                                            {error.map((message, index) => {
                                                const key = index;
                                                return <li key={key}>{message}</li>;
                                            })}
                                        </ul>
                                    )}
                                </>
                            );
                        };

                        const toastrOptions = {
                            timeOut: 0,
                            showCloseButton: true,
                            closeOnToastrClick: false,
                            removeOnHover: false,
                            icon: <i className="fas fa-exclamation-circle" />,
                            component: <ErrorMessage />,
                        };

                        toastr.error("", "", toastrOptions);

                        store.dispatch(MODAL_CLEAR_REF());
                    });
            }

            next(action);
            break;

        case ReduxActions.START_BATCH_ACTION_EDIT().type:
            url = `${base}/api/v1/imports/bulk-edit-valuations`;
            payloadToSend = {
                valuationIds: action.payload.valuationIds,
                properties: action.payload.properties.map((property) => {
                    return { propertyId: property.id, value: property.value };
                }),
            };

            window.API.getClient()
                .post(url, {
                    ...payloadToSend,
                })
                .then(({ data: { data: result } }) => {
                    store.dispatch(MODAL_SET_REF("bulk-edit-confirmation"));
                    store.dispatch(ReduxActions.RESET_BATCH_ACTION());
                })
                .catch((error) => {
                    store.dispatch(MODAL_CLEAR_REF());
                    store.dispatch(ReduxActions.RESET_BATCH_ACTION());
                    store.dispatch(ERROR_SET(error.response));
                });

            next(action);
            break;

        case ReduxActions.RENDER_TRANSITION_SUCCESS().type:
            store.dispatch(MODAL_SET_REF("bulk-transition-confirmation", action.payload.modalType));

            next(action);
            break;

        default:
            next(action);
            break;
    }
};

export default middleWareBatchAction;

import Divide from "./Expressions/Divide";
import Multiply from "./Expressions/Multiply";
import JPItem from "./Simple/JPItem";
import Static from "./Simple/Static";
import Sum from "./Expressions/Sum";
import SumProduct from "./Expressions/SumProduct";
import JPList from "./Simple/JPList";
import Round from "./Expressions/Round";
import Add from "./Expressions/Add";
import Subtract from "./Expressions/Subtract";
import SubtractDates from "./Expressions/SubtractDates";
import PresentValue from "./Expressions/PresentValue";
import PowerOf from "./Expressions/PowerOf";
import If from "./Expressions/If";
import IsNull from "./Simple/IsNull";
import Not from "./Simple/Not";
import IsEmpty from "./Expressions/IsEmpty";
import LessThan from "./Expressions/LessThan";
import Equal from "./Expressions/Equal";
import GreaterThan from "./Expressions/GreaterThan";
import SwitchIndex from "./Expressions/SwitchIndex";
import ParseDate from "./Simple/ParseDate";
import AreEmpty from "./Expressions/AreEmpty";
import MRound from "./Expressions/MRound";

class BuildExpression {
    constructor(data, expression, index, tableIndex) {
        this.data = data;
        this.expression = expression;
        this.index = index;
        this.tableIndex = tableIndex;
    }

    calculate() {
        const obj = BuildExpression.getClass(this.data, this.expression, this.index, this.tableIndex);
        return obj.calculate();
    }

    static parseIndex(expression, index, tableIndex) {
        let expressionStr = JSON.stringify(expression);
        expressionStr = expressionStr.replace(/%tableIndex%/gi, tableIndex);
        expressionStr = expressionStr.replace(/%index%/gi, index);

        return JSON.parse(expressionStr);
    }

    static getClass(data, expression, index, tableIndex) {
        expression = BuildExpression.parseIndex(expression, index, tableIndex);

        switch (expression?.type) {
            case "divide":
                return new Divide(data, expression, index, tableIndex);
            case "sum":
                return new Sum(data, expression, index, tableIndex);
            case "add":
                return new Add(data, expression, index, tableIndex);
            case "subtract":
                return new Subtract(data, expression, index, tableIndex);
            case "subtractDates":
                return new SubtractDates(data, expression, index, tableIndex);
            case "sumProduct":
                return new SumProduct(data, expression, index, tableIndex);
            case "multiply":
                return new Multiply(data, expression, index, tableIndex);
            case "powerOf":
                return new PowerOf(data, expression, index, tableIndex);
            case "round":
                return new Round(data, expression, index, tableIndex);
            case "mRound":
                return new MRound(data, expression, index, tableIndex);
            case "jsonpath.item":
                return new JPItem(data, expression);
            case "jsonpath.list":
                return new JPList(data, expression);
            case "switchIndex":
                return new SwitchIndex(data, expression, index, tableIndex);
            case "if":
                return new If(data, expression, index, tableIndex);
            case "<":
                return new LessThan(data, expression, index, tableIndex);
            case ">":
                return new GreaterThan(data, expression, index, tableIndex);
            case "=":
                return new Equal(data, expression, index, tableIndex);
            case "isNull":
                return new IsNull(data, expression, index, tableIndex);
            case "constant":
                return new Static(expression);
            case "not":
                return new Not(data, expression, index, tableIndex);
            case "isEmpty":
                return new IsEmpty(data, expression, index, tableIndex);
            case "areEmpty":
                return new AreEmpty(data, expression, index, tableIndex);
            case "parseDate":
                return new ParseDate(data, expression, index, tableIndex);
            case "presentValue":
                return new PresentValue(data, expression, index, tableIndex);
            default:
                return new Static(1);
        }
    }
}

export default BuildExpression;

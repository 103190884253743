import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const compViewDynamicCategoriesApi = createAsyncThunk(
    "compView/compViewDynamicCategoriesApi",
    async (_, { dispatch, getState, signal, rejectWithValue }) => {
        const { auth, userProfile } = getState();
        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const url = defaultConfig.api.compView.config;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const responseData = await convertObjectKeys({ dataObject: response.data });
            const tempData = responseData;
            const categories = {};
            tempData.forEach((x, i) => {
                const tempCategory = x;
                const lastDot = x.translationKey.lastIndexOf(".") + 1;
                const categoryName = x.translationKey.slice(lastDot, x.translationKey.length);
                tempCategory.id = i;
                tempCategory.title = x.key.toLowerCase();
                tempCategory.type = "transaction";
                tempCategory.subType = x.types.join("|").toLowerCase();
                tempCategory.active = true;
                tempCategory.hidden = false;
                tempCategory.disabled = false;
                tempCategory.loading = false;
                tempCategory.entities = undefined;
                tempCategory.currentRequestId = undefined;
                tempCategory.error = undefined;
                tempCategory.categoryName = categoryName;
                categories[categoryName.toLowerCase()] = tempCategory;
            });
            return categories;
        }

        return rejectWithValue({ error });
    }
);

export default compViewDynamicCategoriesApi;

/* eslint-disable import/no-cycle */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { setCategoryActive } from "features/compView/compViewSlice";
import { RETRIEVE_CONFIG_SETTINGS_RESULT } from "react/redux/actions/action-config-settings";
import { apiCall, convertObjectKeys, ucFirst, getType } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const setGoogleMaps = (userProfile, googleMapsApiKey) => {
    const googleLibraries = ["geometry", "drawing", "places"];
    const languageCode = userProfile?.entities?.languageCode;
    if (languageCode) {
        document.querySelectorAll('script[src^="//maps.googleapis.com"]').forEach((script) => {
            script.remove();
        });

        if (window.google) delete window.google.maps;

        const newAPI = document.createElement("script");
        newAPI.src = `//maps.googleapis.com/maps/api/js?v=weekly&libraries=${googleLibraries.join(
            ","
        )}&key=${googleMapsApiKey}&language=${languageCode}&callback=googleMapsAPILoaded`;

        window.googleMapsAPILoaded = () => {
            const event = new CustomEvent("googleMapsAPILoaded");
            window.dispatchEvent(event);
        };

        // eslint-disable-next-line no-new
        new Promise((resolve) => {
            window.addEventListener("googleMapsAPILoaded", () => {
                resolve();
            });
        });

        document.querySelector("head").appendChild(newAPI);
    }
};

const configSettingsApi = createAsyncThunk(
    "configSettings/configSettingsApi",
    async (value, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, configSettings, userProfile, compView } = getState();

        if (configSettings.loading === false || requestId !== configSettings.currentRequestId) return;
        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const { jwt } = auth;
        const url = defaultConfig.api.configSettings;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if ([401, 403].includes(getResponse.status)) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            dispatch(RETRIEVE_CONFIG_SETTINGS_RESULT(response));
            const { data } = response || {};
            const googleMapsApiKey = data?.reactConfig?.google_web_map_api_key;
            setGoogleMaps(userProfile, googleMapsApiKey);
            const dataConverted = await convertObjectKeys({ dataObject: data, convertType: "camelCase" });
            const categories = Object.keys(compView.categories);

            categories?.forEach((category) => {
                const objectKey = `compview${ucFirst(category)}`;
                const categoryValue = !!dataConverted.modules[objectKey];
                const categoryIsActive = getType(categoryValue) === "boolean";

                if (categoryIsActive) {
                    dispatch(setCategoryActive({ category, active: categoryIsActive }));
                }
            });

            return dataConverted;
        }

        return rejectWithValue({ error });
    }
);

export default configSettingsApi;

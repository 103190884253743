import Component from "../Component";

class Expression extends Component {
    constructor(data, expression, index, tableIndex) {
        super();

        this.data = data;
        this.expression = expression;
        this.index = index;
        this.tableIndex = tableIndex;
        this.items = [];

        this._setItems();
    }

    add(item) {
        this.items.push(item);
    }

    _setItems() {}
}

export default Expression;

import produce from "immer";
import { SET_VALUE_PATH, SET_VALUE_TITLE } from "../constants";

const initialState = {
    totalValuePath: "",
    totalValueTitle: "",
};

const valueTitles = {
    sar: "SAR",
    gvw: "GVW",
    "residuele-grondwaarde": "Residuele Grondwaarde",
    "waardeber--land--wonen": "Waardeber. land. Wonen",
};

export default produce((draft = initialState, action) => {
    const { type, totalValuePath, totalValueTitle } = action;

    switch (type) {
        case SET_VALUE_PATH: {
            draft.totalValuePath = totalValuePath;
            break;
        }
        case SET_VALUE_TITLE: {
            draft.totalValueTitle = valueTitles[totalValueTitle];
            break;
        }

        default:
            return draft;
    }
});

import { createSlice } from "@reduxjs/toolkit";
import userProfileApi from "./userProfileApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
};

const userProfile = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        resetUserProfile() {
            return initialState;
        },
    },
    extraReducers: {
        [userProfileApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [userProfileApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [userProfileApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
    },
});

const { resetUserProfile } = userProfile.actions;
const userProfileReducer = userProfile.reducer;

export { userProfileReducer, resetUserProfile, userProfileApi };

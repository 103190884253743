const getUserProfile = ({ userProfile }) => userProfile.entities;
const getUserPermissions = ({ userProfile }) => userProfile.entities?.permissions;
const getUserLanguage = ({ userProfile }) => userProfile?.entities?.languageCode;
const getUserProfileError = ({ userProfile }) => userProfile.error;
const getUserId = ({ userProfile }) => userProfile.entities?.id;
const isUserActive = ({ userProfile }) => userProfile.entities?.active;
const isUserProfileLoaded = ({ userProfile }) => userProfile.entities && !userProfile.loading;

export {
    getUserProfile,
    getUserPermissions,
    getUserLanguage,
    getUserProfileError,
    getUserId,
    isUserActive,
    isUserProfileLoaded,
};

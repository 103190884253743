import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Subtract extends Expression {
    calculate() {
        let result = parseFloat(this.items[0].calculate());

        this.items.slice(1).forEach((item) => {
            result -= parseFloat(item.calculate() || 0);
        });

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default Subtract;

import { nanoid } from "@reduxjs/toolkit";
import store from "Store";
import { debounce } from "throttle-debounce";

export const fetchCells = (columns, sectionInput, sectionKey, index, tableIndex) => {
    const cells = [];

    columns?.forEach((column) => {
        const { key } = column;
        let inputRow;
        if (key) {
            inputRow = sectionInput.keys[key];
        }

        const { expression, arguments: expressionArguments } = inputRow || {};
        let cellExpression;
        if (expression) {
            cellExpression = expression;
        }
        if (expressionArguments) {
            cellExpression = expressionArguments.find((item) => !!item.expression)?.expression;
        }

        const cell = {
            id: nanoid(),
            field: key,
            section: sectionKey,
            index,
            tableIndex,
            expression: cellExpression,
        };

        cells.push(cell);
    });

    return cells;
};

export const formatDate = (providedDate = Date.now(), format = "yyyymmdd") => {
    if (format === "yyyymmdd") {
        let formattedDate = new Date(providedDate).toISOString();
        return formattedDate.substring(0, formattedDate.indexOf("T")).replace(/-/g, "");
    }
};

export const updateCalculationData = debounce(1000, () => {
    const { auth, pageData, calculationData } = store.getState();
    const { actions, data } = pageData;

    const updateEndpoint = actions?.update;
    if (!!updateEndpoint && calculationData.data.status === "active") {
        fetch(updateEndpoint, {
            method: "PUT",
            headers: {
                accept: "application/json",
                "content-type": "application/json; charset=UTF-8",
                authorization: `Bearer ${auth.jwt}`,
            },
            cache: "default",
            mode: "cors",
            credentials: "omit",
            body: JSON.stringify({ data }),
        });
    }
});

import Component from "../Component";
import jp from "jsonpath";

class JPItem extends Component {
    constructor(data, path) {
        super();

        this.data = data;
        this.path = path?.argument;
    }

    calculate() {
        return jp.query(this.data, this.path)[0];
    }
}

export default JPItem;

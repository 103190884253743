import Expression from "./Expression";
import BuildExpression from "../BuildExpression";

class Multiply extends Expression {
    calculate() {
        if (!this.items.length) {
            return null;
        }

        let result = 1;

        this.items.forEach((item) => {
            result *= item.calculate();
        });

        return result;
    }

    _setItems() {
        this.expression?.arguments?.forEach((argument) => {
            this.add(BuildExpression.getClass(this.data, argument, this.index, this.tableIndex));
        });
    }
}

export default Multiply;

/* eslint-disable import/no-cycle */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { externalAuth, setAuthError } from "features/authentication/authenticationSlice";
import { setCategoryDisabled } from "features/compView/compViewSlice";
import {
    apiCall,
    convertObjectKeys,
    getStorageItem,
    getType,
    objectAsUrlParams,
    removeStorageItem,
    ucFirst,
    validations,
} from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";
import serializeCompViewData from "features/compView/helpers/serializeCompViewData";
import addressFormat from "helpers/addressFormat";
import { toastr } from "react-redux-toastr";
import v2TokenApi from "../v2/v2TokenApi";
import { isV2TokenExpired } from "../../helpers/v2TokenHandler";

const buttonAddValuation = {
    name: "compview.addToValuation",
    onClickHandler: "onClickHandlerAddValuation",
    setValue: "valuationId",
};

const getUrl = ({ id, isExternalSource = false, sourceType, isBrainbayV2Connection = false }) => {
    if (validations.isEmpty(id) || validations.isEmpty(sourceType)) return undefined;

    const urlFromConfig = defaultConfig?.api?.compView[sourceType]?.item;
    if (validations.isEmpty(urlFromConfig)) return undefined;

    if (isExternalSource && sourceType.startsWith("brainbay")) {
        if (!isBrainbayV2Connection) {
            const tokenObject = getStorageItem("brainbayToken", "localStorage");
            if (validations.isEmpty(tokenObject)) return undefined;
        }
        let connectionType;
        switch (sourceType) {
            case "brainbay":
            case "brainbayAgriculture":
                connectionType = "aenl";
                break;
            case "brainbayBusiness":
                connectionType = "bog";
                break;
            case "brainbayResidential":
                connectionType = "resi";
                break;
            default:
                if (isBrainbayV2Connection) {
                    return `${urlFromConfig}${id}`;
                }
                connectionType = "aenl";
                break;
        }
        const urlParamAsObject = {
            connectionType,
            id,
        };
        const urlParams = objectAsUrlParams(urlParamAsObject);
        return `${urlFromConfig}${urlParams}`;
    }

    return urlFromConfig.replace(/{{id}}/g, id);
};

const getBody = ({ auth, id, isExternalSource, sourceType }) => {
    if (!isExternalSource) return undefined;

    if (isExternalSource && sourceType.startsWith("brainbay")) {
        const { login, error, ...authObject } = auth[sourceType];

        if (validations.isEmpty(authObject)) return undefined;

        return {
            id,
        };
    }

    return {
        id,
    };
};

const getHeaders = ({ isExternalSource, sourceType, auth, v2Token = null, isBrainbayV2Connection = false }) => {
    const headers = {};

    if (isExternalSource && sourceType.startsWith("brainbay") && !isBrainbayV2Connection)
        return { "content-type": "application/x-www-form-urlencoded" };

    const { jwt } = auth || {};

    headers.accept = "application/json";
    headers["content-type"] = "application/json";

    if (!isExternalSource && validations.isNotEmpty(jwt, true)) headers.authorization = `Bearer ${jwt}`;
    if (isBrainbayV2Connection) {
        headers["content-type"] = "application/x-www-form-urlencoded";
        headers.authorization = `Bearer ${v2Token}`;
    }
    return headers;
};

const getImageUrl = async ({ id, sourceType = "valuation", jwt = undefined }) => {
    if (validations.isEmpty(id) || validations.isEmpty(sourceType) || validations.isEmpty(jwt)) return undefined;

    const defaultUrl =
        sourceType === "valuation" ? defaultConfig.api[sourceType]?.image?.main : defaultConfig.api[sourceType]?.image;

    if (getType(defaultUrl) === "string") {
        const url =
            defaultUrl
                ?.replace(/{{id}}/g, id)
                ?.replace(/{{valuationId}}/g, id)
                ?.replace(/{{name}}/g, id)
                ?.replace(/{{jwt}}/g, jwt) || undefined;
        return url;
    }

    return undefined;
};

const serializeData = async ({
    data,
    sourceType = "valuation",
    jwt = undefined,
    addButtonAddValuation = false,
    language = "en",
}) => {
    if (validations.isEmpty(data)) return undefined;

    const { address, actions, properties, id, mainPhotoAvailable } = data || {};
    address.lng = address.lon || address.lng;
    delete address.lon;

    const type = data.type?.toLowerCase() || sourceType?.toLowerCase();
    const imageUrl = mainPhotoAvailable && (await getImageUrl({ id, sourceType, jwt }));
    const title = addressFormat({ address, language }) || undefined;

    if (addButtonAddValuation) actions.push(buttonAddValuation);

    const content = await properties.reduce(async (accumulator, currentValue, currentIndex) => {
        const tempData = await accumulator;

        if (validations.isNotEmpty(currentValue.key, true)) {
            const value =
                (getType(currentValue.value) === "array" &&
                    currentValue.value.map((valueItem) => {
                        const returnValue = validations.isJSONString(valueItem) ? JSON.parse(valueItem) : valueItem;
                        return returnValue;
                    })) ||
                currentValue.value;

            const valueIsMultiple = getType(currentValue.value) === "array";

            const newValueObject = {
                id: currentIndex + 1,
                key: currentValue.key,
                label: currentValue.translationKey || currentValue.key,
                value,
                valueIsMultiple,
                type: currentValue.type || "string",
                format: currentValue.format,
            };

            tempData.push(newValueObject);
        }

        return tempData;
    }, []);

    const footer = await actions.reduce(async (accumulator, currentValue, currentIndex) => {
        const tempData = await accumulator;

        const { url } = currentValue || {};
        const contentType = url ? "link" : "button";

        const newValueObject = {
            id: currentIndex + 1,
            title: currentValue.name,
            url,
            target: url && url.split("//").length > 1 ? "_top" : undefined,
            onClickHandler: currentValue.onClickHandler || undefined,
            setValue: currentValue.setValue || undefined,
            icon: "fa fa-external-link",
            type: contentType,
        };

        if (newValueObject.title.includes("edit")) {
            newValueObject.icon = "fa fa-edit";
            newValueObject.target = "_blank";
        }
        if (newValueObject.title.includes("add")) newValueObject.icon = "fa fa-plus";
        if (newValueObject.title.includes("generate")) {
            newValueObject.icon = "fa fa-download";
            newValueObject.target = "_blank";
        }

        tempData.push(newValueObject);

        return tempData;
    }, []);

    const newDataObject = {
        id,
        image: {
            url: imageUrl,
            title,
        },
        address,
        title: {
            text: title,
            additional: type,
        },
        content,
        footer,
        type,
    };

    return newDataObject;
};

const infoDataBoxApi = createAsyncThunk(
    "infoDataBox/infoDataBoxApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { id, intl, category, type, isExternalSource, addButtonAddValuation } = data || {};
        const { auth, infoDataBox, userProfile, configSettings, v2, compView } = getState();
        const isBrainbayV2Connection = compView.categories[category]?.isV2Brainbay ?? false;
        if (infoDataBox.loading === false || requestId !== infoDataBox.currentRequestId) return;
        const v2Token = isV2TokenExpired(v2) ? (await dispatch(v2TokenApi()))?.payload?.token : v2.token;
        if (isBrainbayV2Connection && !v2Token) return {};
        const googleAPIKey = configSettings.entities.reactConfig.googleWebMapApiKey;
        const error = ["AUTHENTICATION_FAILED"];
        const language = userProfile?.entities?.languageCode;

        if (!auth || !auth?.jwt || !!userProfile.error) return rejectWithValue({ error });

        const sourceType = isExternalSource ? category : type?.toLowerCase();
        const { jwt } = auth || {};
        const url = getUrl({ isExternalSource, sourceType, id, isBrainbayV2Connection });

        if (validations.isEmpty(url)) rejectWithValue({ error });

        const headers = getHeaders({ isExternalSource, sourceType, auth, v2Token, isBrainbayV2Connection });
        const credentials = isExternalSource && !isBrainbayV2Connection ? "include" : "omit";
        const method = isExternalSource ? "POST" : "GET";
        const body = !isBrainbayV2Connection ? getBody({ auth, id, isExternalSource, sourceType }) : undefined;

        const getResponse = await apiCall({
            url,
            method,
            headers,
            credentials,
            mode: "cors",
            body,
            signal,
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = ["application/json", "text/plain"].includes(contentType);

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const dataObject = await convertObjectKeys({ dataObject: response, convertType: "camelCase" });
            if (isExternalSource) {
                return serializeCompViewData[category]({
                    dataObject,
                    addButtonAddValuation,
                    language,
                    googleAPIKey,
                });
            }

            const responseData = await serializeData({
                data: dataObject.data,
                sourceType,
                jwt,
                addButtonAddValuation,
                language,
            });

            return responseData;
        }

        if (isExternalSource) {
            const correctedSourceType = sourceType.startsWith("brainbay") ? "brainbay" : sourceType;
            removeStorageItem(`${correctedSourceType}Token`, "localStorage");
            toastr.error(
                ucFirst(
                    intl.formatMessage({
                        id: `${correctedSourceType}.error.message.sessionExpired`,
                    })
                )
            );
            dispatch(
                externalAuth({
                    [correctedSourceType]: undefined,
                })
            );
            // todo deal with the V2 session expired stuff
            if (correctedSourceType === "brainbay") {
                dispatch(setCategoryDisabled("brainbayAgriculture"));
                dispatch(setCategoryDisabled("brainbayBusiness"));
                dispatch(setCategoryDisabled("brainbayResidential"));
            } else {
                dispatch(setCategoryDisabled(sourceType));
            }
            return rejectWithValue({ error });
        }

        dispatch(setAuthError("authenticationError"));
        toastr.error(
            ucFirst(
                intl.formatMessage({
                    id: "login.error.sessionExpired",
                })
            )
        );
        return rejectWithValue({ error });
    }
);

export default infoDataBoxApi;

import { createSlice } from "@reduxjs/toolkit";
import menuApi from "./menuApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
    show: false,
};

const menu = createSlice({
    name: "menu",
    initialState,
    reducers: {
        setMenuShow: (state, action) => {
            const newState = state;
            newState.show = action.payload;

            return newState;
        },
        resetMenu() {
            return initialState;
        },
    },
    extraReducers: {
        [menuApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [menuApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [menuApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
    },
});

const { resetMenu, setMenuShow } = menu.actions;
const menuReducer = menu.reducer;

export { menuReducer, resetMenu, menuApi, setMenuShow };

import produce from "immer";

import { REQUEST, SUCCESS, FAILURE, LOAD_CALCULATION_DATA, RESET_CALCULATION_DATA, SET_CALCULATION_STATUS, SET_IMPORT_DATA, UPDATE_CALCULATION_NAME } from "../constants";

const initialState = {
    data: {},
    changedAt: null,
    loading: false,
    loaded: false,
    error: null,
    updating: false,
    importData: null,
};

export default produce((draft = initialState, action) => {
    const { type, data, error } = action;
    switch (type) {
        case RESET_CALCULATION_DATA: {
            draft.data = {};
            draft.loaded = false;
            draft.loading = false;
            draft.error = null;
            break;
        }
        case LOAD_CALCULATION_DATA + REQUEST: {
            draft.error = null;
            draft.loading = true;
            break;
        }
        case LOAD_CALCULATION_DATA + SUCCESS: {
            draft.loading = false;
            draft.loaded = true;
            draft.data = { ...draft.data, ...data };
            break;
        }
        case LOAD_CALCULATION_DATA + FAILURE: {
            draft.loading = false;
            draft.loaded = false;
            draft.error = error;
            break;
        }
        case SET_CALCULATION_STATUS: {
            draft.data = {...draft.data, ...data}
            break;
        }
        case SET_IMPORT_DATA: {
            draft.importData = data;
            break;
        }
        case UPDATE_CALCULATION_NAME: {
            draft.data = {...draft.data, ...data}
            break;
        }
        default:
            return draft;
    }
});
import { createSlice } from "@reduxjs/toolkit";
import functionalityApi from "./functionalityApi";

const initialState = {
    loading: false,
    entities: undefined,
    currentRequestId: undefined,
    error: undefined,
};

const functionality = createSlice({
    name: "functionality",
    initialState,
    reducers: {
        resetFunctionality() {
            return initialState;
        },
    },
    extraReducers: {
        [functionalityApi.pending]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === false && !state.currentRequestId) {
                newState.loading = true;
                newState.currentRequestId = requestId;
            }
        },
        [functionalityApi.fulfilled]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.entities = action.payload;
                newState.currentRequestId = undefined;
            }
        },
        [functionalityApi.rejected]: (state, action) => {
            const newState = state;
            const { requestId } = action.meta;

            if (state.loading === true && state.currentRequestId === requestId) {
                newState.loading = false;
                newState.error = action.payload?.error;
                newState.currentRequestId = undefined;
            }
        },
    },
});

const { resetFunctionality } = functionality.actions;
const functionalityReducer = functionality.reducer;

export { functionalityReducer, resetFunctionality, functionalityApi };

import { fromJS } from "immutable";

export function ERROR_SET(error, type) {
    return {
        type: "ERROR_SET",
        payload: fromJS({
            error,
            type,
        }),
    };
}

export function ERROR_CLEAR() {
    return {
        type: "ERROR_CLEAR",
        payload: fromJS({}),
    };
}

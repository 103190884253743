import { validations } from "@kateinnovations/javascript-helpers";

const appEnvironment = window?.appEnvironment;
const getIsBrowser = () => !!appEnvironment;

const getEnvValue = (key = undefined, asString = false) => {
    if (validations.isEmpty(key)) return undefined;
    if (key === "NODE_ENV") return process.env.NODE_ENV;

    const enviromentKey = `REACT_APP_${key}`;
    const isBrowser = getIsBrowser();

    const value = isBrowser
        ? appEnvironment[enviromentKey] || process.env[enviromentKey]
        : process.env[enviromentKey] || undefined;

    return validations.isJSONString(value) && !asString ? JSON.parse(value) : value;
};

export default getEnvValue;

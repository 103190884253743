export default (objects) => {
    if (!objects || objects.size === 0) {
        return [];
    }

    const transformedObjects = [];
    // Creates sorting structure per objectCategory
    // address - sortedPropertyDefinitions
    Object.values(objects).forEach((object) => {
        const { id, address, addressString } = object;
        const { street, number, addition, city } = address;

        const propertiesPerObject = {
            id,
            addressString,
            street,
            number,
            addition,
            city,
            properties: {},
        };

        transformedObjects.push(propertiesPerObject);
    });
    return transformedObjects;
};

export const IMPORTER_START = ({ files, type } = {}) => ({
    type: "IMPORTER_START",
    payload: {
        files,
        type,
    },
});

export const IMPORTER_START_RESULT = ({ files } = {}) => ({
    type: "IMPORTER_START_RESULT",
    payload: {
        files,
    },
});

export const IMPORTER_SET_TYPE = (type, transactionsType = null) => ({
    type: "IMPORTER_SET_TYPE",
    payload: {
        type,
        transactionsType,
    },
});

export const IMPORTER_SET_HEADINGS = (headings) => ({
    type: "IMPORTER_SET_HEADINGS",
    payload: {
        headings,
    },
});

export const IMPORTER_VALIDATE = ({ type, importEntryData, importEntryId } = {}) => ({
    type: "IMPORTER_VALIDATE",
    payload: {
        type,
        importEntryData,
        importEntryId,
    },
});

export const IMPORTER_VALIDATE_RESULT = ({ result, importEntryId } = {}) => ({
    type: "IMPORTER_VALIDATE_RESULT",
    payload: {
        result,
        importEntryId,
    },
});

//MAPPINGS
export const IMPORTER_GET_MAPPINGS = ({ type } = {}) => ({
    type: "IMPORTER_GET_MAPPINGS",
    payload: {
        type,
    },
});

export const IMPORTER_GET_MAPPINGS_RESULT = ({ mappings } = {}) => ({
    type: "IMPORTER_GET_MAPPINGS_RESULT",
    payload: {
        mappings,
    },
});

export const IMPORTER_GET_MAPPING_OPTIONS = (type, transactionsType) => ({
    type: "IMPORTER_GET_MAPPING_OPTIONS",
    payload: {
        type,
        transactionsType,
    },
});

export const IMPORTER_GET_MAPPING_OPTIONS_RESULT = ({ mappingOptions } = {}) => ({
    type: "IMPORTER_GET_MAPPING_OPTIONS_RESULT",
    payload: {
        mappingOptions,
    },
});

export const IMPORTER_SET_MAPPING = ({ mappingId } = {}) => ({
    type: "IMPORTER_SET_MAPPING",
    payload: {
        mappingId,
    },
});

export const IMPORTER_SET_MAPPING_TRANSACTION_MODE = (transactionMappingType) => ({
    type: "IMPORTER_SET_MAPPING_TRANSACTION_MODE",
    payload: {
        transactionMappingType,
    },
});

export const IMPORTER_CREATE_MAPPING = ({ mappingName, mapping, mappingType } = {}) => ({
    type: "IMPORTER_CREATE_MAPPING",
    payload: {
        mappingName,
        mapping,
        mappingType,
    },
});

export const IMPORTER_CREATE_MAPPING_RESULT = (mapping) => ({
    type: "IMPORTER_CREATE_MAPPING_RESULT",
    payload: {
        mapping,
    },
});

export const IMPORTER_GET_MANDATORY_MAPPING = ({ type } = {}) => ({
    type: "IMPORTER_GET_MANDATORY_MAPPING",
    payload: {
        type,
    },
});

export const IMPORTER_GET_MANDATORY_MAPPING_RESULT = ({ mandatoryMappings } = {}) => ({
    type: "IMPORTER_GET_MANDATORY_MAPPING_RESULT",
    payload: {
        mandatoryMappings,
    },
});

export const IMPORTER_UPDATE_MAPPING = ({ mapping, mappingId, mappingName } = {}) => ({
    type: "IMPORTER_UPDATE_MAPPING",
    payload: {
        mapping,
        mappingName,
        mappingId,
    },
});

export const IMPORTER_UPDATE_MAPPING_RESULT = ({ mapping, mappingId, mappingName } = {}) => ({
    type: "IMPORTER_UPDATE_MAPPING_RESULT",
    payload: {
        mapping,
        mappingId,
        mappingName,
    },
});

export const IMPORTER_DELETE_MAPPING = ({ mappingId } = {}) => ({
    type: "IMPORTER_DELETE_MAPPING",
    payload: {
        mappingId,
    },
});

export const IMPORTER_DELETE_MAPPING_RESULT = ({ mappingId } = {}) => ({
    type: "IMPORTER_DELETE_MAPPING_RESULT",
    payload: {
        mappingId,
    },
});

export const IMPORTER_CLEAR_MAPPINGS = () => ({
    type: "IMPORTER_CLEAR_MAPPINGS",
    payload: {},
});

export const IMPORTER_CLEAR_MAPPING = () => ({
    type: "IMPORTER_CLEAR_MAPPING",
    payload: {},
});

export const IMPORTER_SET_MAPPING_RESULT = ({ mappingId } = {}) => ({
    type: "IMPORTER_SET_MAPPING_RESULT",
    payload: {
        mappingId,
    },
});

//END OF MAPPINGS

// connection checking
export const IMPORTER_CONNECTION_SET_VALUATION = ({ importEntryId } = {}) => ({
    type: "IMPORTER_CONNECTION_SET_VALUATION",
    payload: {
        importEntryId,
    },
});

export const IMPORTER_CHECK_ADDRESS_CONFIG = ({ type } = {}) => ({
    type: "IMPORTER_CHECK_ADDRESS_CONFIG",
    payload: {
        type,
    },
});

export const IMPORTER_CHECK_ADDRESS_CONFIG_RESULT = ({ config } = {}) => ({
    type: "IMPORTER_CHECK_ADDRESS_CONFIG_RESULT",
    payload: {
        config,
    },
});

export const IMPORTER_CHECK_ADDRESS = ({ searchProperties, importEntryId, type } = {}) => ({
    type: "IMPORTER_CHECK_ADDRESS",
    payload: {
        searchProperties,
        importEntryId,
        type,
    },
});

export const IMPORTER_FIND_VALUATION_CONFIG = () => ({
    type: "IMPORTER_FIND_VALUATION_CONFIG",
    payload: {},
});

export const IMPORTER_FIND_VALUATION_CONFIG_RESULT = ({ config } = {}) => ({
    type: "IMPORTER_FIND_VALUATION_CONFIG_RESULT",
    payload: {
        config,
    },
});

export const IMPORTER_FIND_VALUATION = ({ searchProperties, importEntryId, portfolioId } = {}) => ({
    type: "IMPORTER_FIND_VALUATION",
    payload: {
        searchProperties,
        importEntryId,
        portfolioId,
    },
});
//end of connection checking

// save import
export const IMPORTER_SAVE_IMPORT = ({ importType, importData, mappingId, callback, fileName, portfolio } = {}) => ({
    type: "IMPORTER_SAVE_IMPORT",
    payload: {
        importType,
        importData,
        mappingId,
        callback,
        fileName,
        portfolio,
    },
});

export const IMPORTER_START_IMPORT = ({ importId, adminMode = false, callback } = {}) => ({
    type: "IMPORTER_START_IMPORT",
    payload: {
        importId,
        adminMode,
        callback,
    },
});

export const IMPORTER_UNDECLINE_IMPORT = ({ importId, adminMode = false } = {}) => ({
    type: "IMPORTER_UNDECLINE_IMPORT",
    payload: {
        importId,
        adminMode,
    },
});

export const IMPORTER_UNDECLINE_IMPORT_RESULT = ({ result } = {}) => ({
    type: "IMPORTER_UNDECLINE_IMPORT_RESULT",
    payload: {
        result,
    },
});

export const IMPORTER_CANCEL_IMPORT = ({ importId, adminMode = false } = {}) => ({
    type: "IMPORTER_CANCEL_IMPORT",
    payload: {
        importId,
        adminMode,
    },
});

export const IMPORTER_CANCEL_IMPORT_RESULT = ({ result } = {}) => ({
    type: "IMPORTER_CANCEL_IMPORT_RESULT",
    payload: {
        result,
    },
});

//get saved imports
export const IMPORTER_GET_SAVED_IMPORTS = () => ({
    type: "IMPORTER_GET_SAVED_IMPORTS",
    payload: {},
});

export const IMPORTER_GET_SAVED_IMPORTS_RESULT = ({ savedImports } = {}) => ({
    type: "IMPORTER_GET_SAVED_IMPORTS_RESULT",
    payload: {
        savedImports,
    },
});

export const IMPORTER_INITIALIZE = ({ id, importData } = {}) => ({
    type: "IMPORTER_INITIALIZE",
    payload: {
        id,
        importData,
    },
});

export const IMPORTER_GET_CLIENTS = () => ({
    type: "IMPORTER_GET_CLIENTS",
    payload: {},
});

export const IMPORTER_GET_CLIENTS_RESULT = ({ clients } = {}) => ({
    type: "IMPORTER_GET_CLIENTS_RESULT",
    payload: {
        clients,
    },
});
export const IMPORTER_CHANGE = ({ importEntryId, newProperties, callback } = {}) => ({
    type: "IMPORTER_CHANGE",
    payload: {
        importEntryId,
        newProperties,
        callback,
    },
});



export const IMPORTER_UPDATE = ({ importEntryId, newProperties, callback } = {}) => ({
    type: "IMPORTER_UPDATE",
    payload: {
        importEntryId,
        newProperties,
        callback,
    },
});

export const IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION = ({ id, importData } = {}) => ({
    type: "IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION",
    payload: {
        id,
        importData,
    },
});

export const IMPORTER_UPDATE_REQUEST = ({ id, importData, mappingId } = {}) => ({
    type: "IMPORTER_UPDATE_REQUEST",
    payload: {
        id,
        importData,
        mappingId,
    },
});

export const IMPORTER_GET_SAVED_IMPORT = ({ id } = {}) => ({
    type: "IMPORTER_GET_SAVED_IMPORT",
    payload: {
        id,
    },
});

export const IMPORTER_GET_SAVED_IMPORT_RESULT = ({ savedImport } = {}) => ({
    type: "IMPORTER_GET_SAVED_IMPORT_RESULT",
    payload: {
        savedImport,
    },
});

export const IMPORTER_SET_ID_PROPERTIES = ({ id, idProperties } = {}) => ({
    type: "IMPORTER_SET_ID_PROPERTIES",
    payload: {
        id,
        idProperties,
    },
});
// get import status

// queue
export const IMPORTER_ADD_TO_QUEUE = ({ entries } = {}) => ({
    type: "IMPORTER_ADD_TO_QUEUE",
    payload: {
        entries,
    },
});

export const IMPORTER_REMOVE_FROM_QUEUE = ({ importEntryId } = {}) => ({
    type: "IMPORTER_REMOVE_FROM_QUEUE",
    payload: {
        importEntryId,
    },
});

export const IMPORTER_ADD_TO_QUEUE_PROCESS = ({ importEntryId } = {}) => ({
    type: "IMPORTER_ADD_TO_QUEUE_PROCESS",
    payload: {
        importEntryId,
    },
});

export const IMPORTER_REMOVE_FROM_QUEUE_PROCESS = ({ importEntryId } = {}) => ({
    type: "IMPORTER_REMOVE_FROM_QUEUE_PROCESS",
    payload: {
        importEntryId,
    },
});

export const IMPORTER_UPDATE_SAVED_REQUEST = ({ importEntryId, update } = {}) => ({
    type: "IMPORTER_UPDATE_SAVED_REQUEST",
    payload: {
        importEntryId,
        update,
    },
});

// end of queue

export const IMPORTER_UPDATE_CHANGES = ({ importEntryId = null } = {}) => ({
    type: "IMPORTER_UPDATE_CHANGES",
    payload: { importEntryId },
});

//portfolios

export const IMPORTER_GET_PORTFOLIOS = () => ({
    type: "IMPORTER_GET_PORTFOLIOS",
    payload: {},
    //    /api/v1/portfolios met de optie isRecentUpdated=true
});

export const IMPORTER_GET_PORTFOLIOS_RESULT = ({ portfolios } = {}) => ({
    type: "IMPORTER_GET_PORTFOLIOS_RESULT",
    payload: { portfolios },
});

export const IMPORTER_SET_PORTFOLIO = ({ portfolio } = {}) => ({
    type: "IMPORTER_SET_PORTFOLIO",
    payload: { portfolio },
});
// pagination

export const IMPORTER_UPDATE_PAGINATION = ({ paginationData } = {}) => ({
    type: "IMPORTER_UPDATE_PAGINATION",
    payload: { paginationData },
});

export const IMPORTER_SELECT_FILTER = ({ filter, request } = {}) => ({
    type: "IMPORTER_SELECT_FILTER",
    payload: { filter, request },
});

export const IMPORTER_CLEAR = () => ({
    type: "IMPORTER_CLEAR",
    payload: {},
});

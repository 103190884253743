import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall, convertObjectKeys, ucFirst, validations } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationClientsApi = createAsyncThunk(
    "valuationEdit/valuationClientsApi",
    async (data, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile, translations } = getState();

        if (valuationEdit.clients.loading === false || requestId !== valuationEdit.clients.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const url = defaultConfig.api.valuation.clients.get;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        if ([401, 403].includes(getResponse.status)) {
            if (getResponse.status === 401) dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";
        const responseKey = isJsonResponse ? "json" : "text";
        const response = await getResponse[responseKey]();

        if (getResponse.ok && isJsonResponse) {
            const responseData = await convertObjectKeys({
                dataObject: response.data,
            });

            const clients = responseData.reduce((clientsObject, client) => {
                const tempData = clientsObject;
                const clientId = client.id;
                tempData[clientId] = {
                    ...client,
                };

                delete tempData[clientId].status;

                return tempData;
            }, {});

            const selectOptions = responseData.map(({ id, name, address, city, status }) => {
                const value = id;
                const clientStatus = ucFirst(translations.entities[`client.status.${status}`]) || ucFirst(status);
                const clientAddressAsArray = [];

                if (address) clientAddressAsArray.push(ucFirst(address));
                if (city) clientAddressAsArray.push(ucFirst(city));

                const clientAddress = validations.isNotEmpty(clientAddressAsArray, true)
                    ? `(${clientAddressAsArray.join(", ")})`
                    : "";

                const label = `[${clientStatus}] ${ucFirst(name || "")} ${clientAddress}`.trim();

                const valueObject = {
                    value,
                    label,
                    active: status !== "inactive",
                };

                return valueObject;
            });

            const result = {
                clients,
                selectOptions,
            };

            return result;
        }

        const statusCodeText = `status code: ${getResponse.status}`;
        const errorToStore = [statusCodeText, response.error];
        return rejectWithValue({ error: errorToStore });
    }
);

export default valuationClientsApi;

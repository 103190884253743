import Component from "../Component";

class Static extends Component {
    constructor(value) {
        super();
        this.value = value.hasOwnProperty("value") ? value.value : value;
    }

    calculate() {
        return this.value || null;
    }
}

export default Static;

import { createSlice } from "@reduxjs/toolkit";
import menuApi from "features/Menu/menuApi";
import customerApi from "features/customer/customerApi";
import functionalityApi from "features/functionality/functionalityApi";
import configSettingsApi from "features/configSettings/configSettingsApi";
import newsCategoriesApi from "features/News/newsCategoriesApi";

const initialState = {
    isLoaded: false,
    entities: [
        {
            id: 1,
            isLoaded: false,
            title: "userProfile",
            weight: 4,
        },
        {
            id: 2,
            isLoaded: false,
            title: "translations",
            weight: 5,
        },
        {
            id: 3,
            isLoaded: false,
            title: "customerInfo",
            weight: 3,
        },
        {
            id: 4,
            isLoaded: false,
            title: "configuration",
            weight: 3,
        },
        {
            id: 5,
            isLoaded: false,
            title: "menu",
            weight: 2,
        },
        {
            id: 6,
            isLoaded: false,
            title: "functionality",
            weight: 2,
        },
        {
            id: 7,
            isLoaded: false,
            title: "system",
            weight: 1,
        },
        {
            id: 8,
            isLoaded: false,
            title: "newsCategories",
            weight: 1,
        },
    ],
};

const applicationLoader = createSlice({
    name: "applicationLoader",
    initialState,
    reducers: {
        setApplicationModuleIsLoaded: (state, action) => {
            const { title } = action.payload;
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === title) {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });

            return newState;
        },
        setApplicationIsLoaded: (state) => {
            const newState = state;
            newState.isLoaded = true;

            return newState;
        },
    },
    extraReducers: {
        [functionalityApi.fulfilled]: (state) => {
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === "functionality") {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });
        },
        [configSettingsApi.fulfilled]: (state) => {
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === "configuration") {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });
        },
        [customerApi.fulfilled]: (state) => {
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === "customerInfo") {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });
        },
        [menuApi.fulfilled]: (state) => {
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === "menu") {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });
        },
        [newsCategoriesApi.fulfilled]: (state) => {
            const newState = state;
            newState.entities.map((item) => {
                const newItemState = item;
                if (newItemState.title === "newsCategories") {
                    newItemState.isLoaded = true;
                }
                return newItemState;
            });
        },
    },
});

const { setApplicationModuleIsLoaded, setApplicationIsLoaded } = applicationLoader.actions;
const applicationLoaderReducer = applicationLoader.reducer;

export { applicationLoaderReducer, setApplicationModuleIsLoaded, setApplicationIsLoaded };

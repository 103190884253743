import { fromJS } from "immutable";
import { MODAL_CLEAR_REF, MODAL_SET_REF } from "react/redux/actions/action-modal";

const emptyState = fromJS({
    ref: null,
});

export default function (state = emptyState, action) {
    switch (action.type) {
        case MODAL_SET_REF().type:
            return state.set("ref", action.payload.ref).set("modalType", action.payload.modalType);

        case MODAL_CLEAR_REF().type:
            return state.set("ref", null);
        default:
            return state;
    }
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "@kateinnovations/javascript-helpers";
import getEnvValue from "helpers/getEnvValue";
import defaultConfig from "config/constants/defaultConfig";

const newsCategoryItemsApi = createAsyncThunk(
    "news/newsCategoryItemsApi",
    async ({ newsCategoryTitle, activeCategoryId }, { getState, requestId, signal, rejectWithValue }) => {
        const { news, auth, userProfile } = getState();
        const newsItem = news.entities.find((item) => item.id === activeCategoryId);

        if (newsItem.loading === false || requestId !== newsItem.currentRequestId) return;

        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) return rejectWithValue({ error });

        const { jwt } = auth;
        const url = `${defaultConfig.api.news}/${newsCategoryTitle}`;
        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });
        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();

        if (getResponse.ok && contentType === "application/json") {
            const response = await getResponse.json();
            const maxNewsItems = getEnvValue("NEWS_ITEMS_MAX_TOTAL");
            const entities = response.data.slice(0, maxNewsItems);

            const date = new Date();

            const data = {
                entities,
                date,
            };

            return data;
        }

        return rejectWithValue({ error });
    }
);

export default newsCategoryItemsApi;

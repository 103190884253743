import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall, validations } from "@kateinnovations/javascript-helpers";

import defaultConfig from "config/constants/defaultConfig";
import { setAuthError } from "../authentication/authenticationSlice";

const newsCategoriesApi = createAsyncThunk(
    "news/newsCategoriesApi",
    async (value, { dispatch, getState, requestId, signal, rejectWithValue }) => {
        const { auth, news, userProfile } = getState();

        if (news.loading === false || requestId !== news.currentRequestId) return;
        const error = ["AUTHENTICATION_FAILED"];

        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const { jwt } = auth;
        const url = defaultConfig.api.news;

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const entities = response?.data?.channels?.map((title, index) => {
                const newDataObject = {
                    id: index + 1,
                    title,
                    loading: false,
                    entities: undefined,
                    currentRequestId: undefined,
                    error: undefined,
                };

                return newDataObject;
            });
            const activeCategoryId = validations.isNotEmpty(entities, true) ? entities[0].id : undefined;

            const data = {
                entities,
                activeCategoryId,
            };

            return data;
        }

        return rejectWithValue({ error });
    }
);

export default newsCategoriesApi;

export function PORTFOLIO_QUERY() {
    return {
        type: "PORTFOLIO_QUERY",
        payload: {},
    };
}

export function PORTFOLIO_QUERY_RESULT(payload) {
    return {
        type: "PORTFOLIO_QUERY_RESULT",
        payload,
    };
}

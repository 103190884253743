import { fromJS } from "immutable";
import { GET_MODULE_CONFIG_RESULT, RETRIEVE_CONFIG_SETTINGS_RESULT } from "../actions/action-config-settings";

const emptyState = fromJS({
    data: {},
    moduleConfig: null,
});

export default function (state = emptyState, action) {
    switch (action.type) {
        case RETRIEVE_CONFIG_SETTINGS_RESULT().type:
            return state.set("data", fromJS(action.payload.data.data));
        case GET_MODULE_CONFIG_RESULT().type:
            return state.set("moduleConfig", fromJS(action.payload.result));
        default:
            return state;
    }
}

export const createDateCustomFilter = (filterValue) => {
    /* Read model adds +1 to the Date range filter, therefore the results are off by a day.
       Using this we can prevent that and get the correct results.
       Todo remove this if Read model date range filter is fixed and will not add plus a day. */
    const dates = filterValue.split(";");
    if (dates.length === 2) {
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        return `${startDate.toISOString().split("T")[0]};${endDate.toISOString().split("T")[0]}`;
    }
    return null;
};

export const generateFilterParams = (formFilters, propertyHolderType, subTypes, isExternalSource) => {
    const type = propertyHolderType ?? "";
    const subType = subTypes.includes(":") ? subTypes : `subType:${subTypes}`;

    let filterParams = `?exact_required=type:${type}&exact_required=${subType}&exact_required=compview:true&exact_required=active:true`;

    formFilters.forEach((filter) => {
        if (filter.disabled === true) return;
        const { name, value, searchParam } = filter;
        const filterValue = value ?? "";

        if (filter.searchParam === "query") {
            const genericFilterParams = `&generic_filter_field=${name}&generic_filter_query=${filterValue}`;
            filterParams += genericFilterParams;
            return true;
        }

        let customFilterValue = null;
        if (!isExternalSource && filter.elementType === "date" && filter.searchParam.includes("range")) {
            customFilterValue = createDateCustomFilter(filter.value);
        }

        const filterParam = `&${searchParam}=${name}:${customFilterValue || filterValue}`;
        filterParams += filterParam;
    });
    return filterParams;
};

import { getType } from "@kateinnovations/javascript-helpers";
import getEnvValue from "helpers/getEnvValue";

const getStringFromEnvironment = (envKey, defaultValue) => {
    const value = getEnvValue(envKey, true);

    return value || defaultValue;
};

const getIntFromEnvironment = (envKey, defaultValue) => {
    const valueAsString = getStringFromEnvironment(envKey, JSON.stringify(defaultValue));
    const value = parseInt(valueAsString, 10);
    const valueType = getType(value);

    if (valueType === "number" && !Number.isNaN(value)) return value;

    return parseInt(defaultValue, 10);
};

const getFloatFromEnvironment = (envKey, defaultValue) => {
    const valueAsString = getStringFromEnvironment(envKey, JSON.stringify(defaultValue));
    const value = parseFloat(valueAsString);
    const valueType = getType(value);

    if (valueType === "number" && !Number.isNaN(value)) return value;

    return parseFloat(defaultValue);
};

export { getStringFromEnvironment, getIntFromEnvironment, getFloatFromEnvironment };

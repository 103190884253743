import { validations, ucFirst } from "@kateinnovations/javascript-helpers";
import { countryList } from "helpers/countryList";
import getEnvValue from "helpers/getEnvValue";

const defaultLanguage = getEnvValue("LOCAL_LANGUAGE")?.toLowerCase() || "en";

const addressFormat = ({
    address,
    cityIsFirstItem = false,
    addCity = true,
    addCountry = false,
    addPostalCode = false,
    language = defaultLanguage,
    forSort = false,
}) => {
    if (validations.isEmpty(address)) return undefined;

    let houseLetter = address.houseLetter || address.letter;
    const number = validations.isNotEmpty(address.number?.hoofdnummer, true)
        ? address.number?.hoofdnummer
        : address.number;
    let addition = `${houseLetter || ""}${address.addition || ""}`;
    if (addition && !isNaN(addition.charAt(0))) {
        // when addition starts with a number, make sure it
        addition = " " + addition;
    }
    const numberFull = `${number?.toString() || ""}${addition?.toString() || ""}`;

    const street = ucFirst(address.street?.toString()?.trim()) || "";
    const city = ucFirst(address.city?.toString()?.trim()) || "";
    const countryCode = address.country?.toString()?.toLowerCase();
    const countryName = countryCode && countryList[language].find((item) => item.alpha2 === countryCode)?.country;
    const postalCode =
        address.postalCode?.toString()?.replace(/\s+/g, "")?.toUpperCase() ||
        address.postal_code?.toString()?.replace(/\s+/g, "")?.toUpperCase();

    const addressAsArray = [street];
    const numberArrayMethod = !forSort && ["gb", "fr", "us"].includes(countryCode) ? "unshift" : "push";
    addressAsArray[numberArrayMethod](numberFull);

    if (addPostalCode && !["gb", "us"].includes(countryCode)) addressAsArray.push(postalCode);

    const cityArrayMethod = cityIsFirstItem ? "unshift" : "push";
    if (addCity) addressAsArray[cityArrayMethod](city);

    if (addPostalCode && ["gb", "us"].includes(countryCode)) addressAsArray.push(postalCode);

    if (addCountry) addressAsArray.push(countryName);

    const addressAsString = addressAsArray.join(" ").replace(/\s\s+/g, " ");

    return ucFirst(addressAsString).trim();
};

export default addressFormat;

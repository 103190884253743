import produce from "immer";
import { TOGGLE_MODAL, SET_STATUS_TRANSITION_CONFIRMATION } from "../constants";

const initialState = {
    isOpen: false,
    component: false,
    statusTransition: undefined,
    statusTransitionConfirmation: false,
};

export default produce((draft = initialState, action) => {
    const { type, component, statusTransition, confirmation, calculationId } = action;
    switch (type) {
        case TOGGLE_MODAL: {
            draft.isOpen = !draft.isOpen;
            draft.component = component;
            draft.statusTransition = statusTransition;
            draft.calculationId = calculationId;
            break;
        }

        case SET_STATUS_TRANSITION_CONFIRMATION: {
            draft.statusTransitionConfirmation = confirmation;
        }

        default:
            return draft;
    }
});

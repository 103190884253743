import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { apiCall } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const valuationSuggestionApi = createAsyncThunk(
    "valuationEdit/valuationSuggestionApi",
    async (data, { dispatch, getState, requestId, rejectWithValue }) => {
        const { auth, valuationEdit, userProfile } = getState();

        if (valuationEdit.suggestions.loading === false || requestId !== valuationEdit.suggestions.currentRequestId)
            return;

        const error = ["AUTHENTICATION_FAILED"];
        if (!auth || !auth?.jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const { jwt } = auth;
        const { valuationId } = valuationEdit;
        const url = defaultConfig.api.valuation.suggestions.get.replace(
            "{{valuationId}}",
            valuationId || data.valuationId
        );

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
        });

        if (getResponse.status === 401) {
            dispatch(setAuthError("authenicationError"));
            return rejectWithValue({ error });
        }

        if (getResponse.ok) {
            const response = await getResponse.json();
            return response.data;
        }
        return rejectWithValue({ error });
    }
);

export default valuationSuggestionApi;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import {
    generateDateTimeConfig,
    generateFuzzyConfig,
    generateRangeConfig,
    generateSelectConfig,
    generateQueryConfig,
} from "generateFieldConfig";
import { apiCall, ucFirst, convertToGivenSeparator, convertObjectKeys } from "@kateinnovations/javascript-helpers";
import applicationErrorMessage from "helpers/applicationErrorMessage";
import defaultConfig from "config/constants/defaultConfig";

const compViewDynamicFormFieldsApi = createAsyncThunk(
    "compView/compViewDynamicFormFieldsApi",
    async (category, { dispatch, getState, signal, rejectWithValue }) => {
        const { auth, compView, userProfile, translations } = getState();
        const formCategory = compView.categories[category];
        const error = ["AUTHENTICATION_FAILED"];
        const { jwt } = auth || {};

        if (!jwt || !!userProfile.error) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }
        const fieldsFromEndpoint = formCategory?.filters;
        const headers = {};
        const credentials = "omit";
        const newFields = [];

        fieldsFromEndpoint.forEach((x) => {
            switch (x.type) {
                case "fuzzy":
                    newFields.push(
                        generateFuzzyConfig(x.title, x.field, false, false, false, false, false, false, "text")
                    );
                    break;
                case "numberRange":
                    newFields.push(generateRangeConfig(x.title, x.field));
                    break;
                case "moneyRange":
                    newFields.push(generateRangeConfig(x.title, x.field, false, false, false, false, "currency"));
                    break;
                case "percentageRange":
                    newFields.push(generateRangeConfig(x.title, x.field, false, false, false, false, "percentage"));
                    break;
                case "datetime":
                    newFields.push(generateDateTimeConfig(x.title, x.field));
                    break;
                case "select":
                    {
                        const options = x.options ?? [];
                        const conditionField = x.conditionField ?? false;
                        const conditionValue = x.conditionValue ? [x.conditionValue] : false;
                        newFields.push(
                            generateSelectConfig(
                                x.title,
                                x.field,
                                options,
                                false,
                                conditionField,
                                conditionValue,
                                false,
                                x.translationPrefix
                            )
                        );
                    }
                    break;
                case "query":
                    newFields.push(generateQueryConfig(x.title, x.field));
                    break;
                default:
                    return x;
            }
        });

        const fields = await convertObjectKeys({ dataObject: newFields });

        const url = defaultConfig.api.compView.readModel.formFields;
        const subType = formCategory.types.join("|").toLowerCase();
        const exactRequired = ["type:transaction"];

        if (subType) exactRequired.push(`subType:${subType}`);

        const subs = await fields?.reduce(async (data, item) => {
            const tempData = await data;
            const { field, settings } = item || {};
            if (settings.enabled) {
                const itemObject = {
                    field,
                    type: settings.loadExternalDataType,
                };

                const title = [item.title];
                if (settings?.translation_prefix) title.unshift(settings?.translation_prefix);

                tempData.push(itemObject);
            }
            return tempData;
        }, []);

        headers.accept = "application/json";
        headers["content-Type"] = "application/json";
        headers.authorization = `Bearer ${jwt}`;

        const getResponse = await apiCall({
            url,
            method: "POST",
            headers,
            credentials,
            mode: "cors",
            signal,
            body: {
                simpleQuery: {
                    exactRequired,
                },
                subs,
            },
        });

        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();
        const isJsonResponse = contentType === "application/json";
        const messages = translations.entities;

        if ([401, 403].includes(getResponse.status)) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        if (getResponse.ok && isJsonResponse) {
            const response = await getResponse.json();
            const { date, number, text } = response || {};
            const formDataFromResponse = {
                ...date,
                ...number,
                ...text,
            };

            const formData = fields?.reduce((data, item, index) => {
                const tempData = data;
                const { title, field, displayType, settings, condition } = item || {};
                const formElementAttributes = formDataFromResponse[field];

                delete condition.defaultHidden;

                let searchParamType = item.type;
                if (item.type === "select") searchParamType = "exact";
                if (item.type === "date") searchParamType = "range";
                const searchParamAsArray = [searchParamType];
                if (settings.required) searchParamAsArray.push("required");
                if (settings.negative) searchParamAsArray.push("negative");

                const { min, max, start, end, top } = formElementAttributes || {};

                const selectOptions =
                    (item.type === "select" &&
                        top?.map((option) => {
                            const { key } = option || {};
                            const translationKey = [settings.translationPrefix, key]?.join("");
                            const defaultMessage = convertToGivenSeparator(key).toLowerCase();

                            const optionData = {
                                label: ucFirst(messages[translationKey] || defaultMessage),
                                value: key,
                            };

                            return optionData;
                        })) ||
                    undefined;

                const formElementData = {
                    id: index + 1,
                    title,
                    name: field,
                    value:
                        (min && max && `${min?.toString()};${max?.toString()}`) ||
                        (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                        null,
                    defaultValue:
                        (min && max && `${min?.toString()};${max?.toString()}`) ||
                        (start && end && `${start.split("T")[0]};${end.split("T")[0]}`) ||
                        "",
                    elementType: ["exact", "fuzzy", "query"].includes(item.type)
                        ? `inputfield${ucFirst(settings.loadExternalDataType)}`
                        : item.type,
                    searchParam: searchParamAsArray.join("_"),
                    min,
                    max,
                    start,
                    end,
                    selectOptions,
                    displayType,
                    condition,
                    hidden: !!condition.field,
                    conditionRules: !condition.field,
                    disabled: true,
                };

                tempData.push(formElementData);
                return tempData;
            }, []);

            return formData;
        }

        if (getResponse.status !== undefined) applicationErrorMessage({ jwt, getResponse, url });

        return rejectWithValue(["APPLICATION_ERROR"]);
    }
);

export default compViewDynamicFormFieldsApi;

/* eslint-disable import/no-cycle */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthError } from "features/authentication/authenticationSlice";
import { setApplicationModuleIsLoaded } from "features/ApplicationLoader/applicationLoaderSlice";
import { apiCall, convertObjectKeys, validations } from "@kateinnovations/javascript-helpers";
import defaultConfig from "config/constants/defaultConfig";

const userProfileApi = createAsyncThunk(
    "userProfile/userProfileApi",
    async ({ jwt }, { dispatch, getState, signal, requestId, rejectWithValue }) => {
        const { userProfile } = getState();

        if (userProfile.loading === false || requestId !== userProfile.currentRequestId) return;

        const url = defaultConfig?.api?.userProfile;
        const error = ["AUTHENTICATION_FAILED"];

        if (validations.isEmpty(jwt)) {
            dispatch(setAuthError("authenticationError"));
            return rejectWithValue({ error });
        }

        const getResponse = await apiCall({
            url,
            method: "GET",
            headers: {
                accept: "application/json",
                "content-type": "application/json",
                authorization: `Bearer ${jwt}`,
            },
            credentials: "omit",
            mode: "cors",
            signal,
        });
        const contentType = getResponse?.headers?.get("content-type")?.split(";")?.shift();

        if (getResponse.ok && contentType === "application/json") {
            const jsonResponse = await getResponse.json();
            const { data } = jsonResponse || {};
            const responseKeyConverted = await convertObjectKeys({ dataObject: data });
            delete data.dashboard;

            const response = {
                ...responseKeyConverted,
                ...responseKeyConverted.personal,
            };

            delete response.personal;

            dispatch(setApplicationModuleIsLoaded({ title: "userProfile" }));
            return response;
        }

        dispatch(setAuthError("authenticationError"));
        return rejectWithValue({ error });
    }
);

export default userProfileApi;

export const SET_BATCH_ACTION_SELECTION = ({ selection } = {}) => ({
    type: "SET_BATCH_ACTION_SELECTION",
    payload: {
        selection,
    },
});

export const FETCH_BATCH_ACTION_RESOURCES = ({ type, statuses } = {}) => ({
    type: "FETCH_BATCH_ACTION_RESOURCES",
    payload: {
        type,
        statuses,
    },
});

export const FETCH_BATCH_ACTION_RESOURCES_RESULT = ({ data } = {}) => ({
    type: "FETCH_BATCH_ACTION_RESOURCES_RESULT",
    payload: {
        data,
    },
});

export const UPDATE_BATCH_ACTION_PROPERTY = ({ propertyId, newValue } = {}) => ({
    type: "UPDATE_BATCH_ACTION_PROPERTY",
    payload: {
        propertyId,
        newValue,
    },
});

export const DELETE_BATCH_ACTION_PROPERTY = ({ propertyId } = {}) => ({
    type: "DELETE_BATCH_ACTION_PROPERTY",
    payload: {
        propertyId,
    },
});

export const START_BATCH_ACTION_EDIT = ({ properties, valuationIds, callback } = {}) => ({
    type: "START_BATCH_ACTION_EDIT",
    payload: {
        properties,
        valuationIds,
        callback,
    },
});

export const RESET_BATCH_ACTION = () => ({
    type: "RESET_BATCH_ACTION",
    payload: {},
});

export const RENDER_TRANSITION_SUCCESS = (modalType) => ({
    type: "RENDER_TRANSITION_SUCCESS",
    payload: {
        modalType,
    },
});

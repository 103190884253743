import axios from "axios";
import https from "https";
import { getStorageItem } from "@kateinnovations/javascript-helpers";

class API {
    constructor() {
        this.client = axios.create({
            httpsAgent: new https.Agent({
                rejectUnauthorized: false,
            }),
        });
        this.token = getStorageItem("kateToken");

        this.addInterceptors();
    }

    addInterceptors() {
        const publicAPIs = [
            "api.kateinnovations.com",
            "api2.kateinnovations.com",
            "api.test.kateinnovations.com",
            "api.accept.kateinnovations.com",
        ];

        this.client.interceptors.request.use(
            function (config) {
                const { url } = config;

                let isPublicRequest = false;
                publicAPIs.forEach((publicAPI) => {
                    if (url.indexOf(publicAPI) > -1) isPublicRequest = true;
                });

                const jwtToken = this.getJwtToken(isPublicRequest);

                config["headers"]["authorization"] = `Bearer ${jwtToken}`;

                return config;
            }.bind(this),
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    getJwtToken(isPublicRequest) {
        return isPublicRequest ? this.token.publicJwt : this.token.jwt;
    }

    getClient() {
        return this.client;
    }
}

export default API;

import * as ReduxActions from "../actions/action-import";
import { IMPORTER_GET_MAPPING_OPTIONS_RESULT } from "../actions/action-import";
import { IMPORTER_GET_MAPPINGS_RESULT } from "../actions/action-import";
import { IMPORTER_CREATE_MAPPING_RESULT } from "../actions/action-import";
import { IMPORTER_CLEAR_MAPPINGS } from "../actions/action-import";
import { MODAL_CLEAR_REF } from "../actions/action-modal";
import { IMPORTER_UPDATE_MAPPING_RESULT } from "../actions/action-import";
import { IMPORTER_GET_SAVED_IMPORTS_RESULT } from "../actions/action-import";
import { IMPORTER_GET_SAVED_IMPORT_RESULT } from "../actions/action-import";
import { IMPORTER_FIND_VALUATION_CONFIG_RESULT } from "../actions/action-import";
import { IMPORTER_CHECK_ADDRESS_CONFIG_RESULT } from "../actions/action-import";
import { IMPORTER_REMOVE_FROM_QUEUE_PROCESS } from "../actions/action-import";
import { IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION } from "../actions/action-import";
import { IMPORTER_UPDATE_CHANGES } from "../actions/action-import";
import { IMPORTER_GET_PORTFOLIOS_RESULT } from "../actions/action-import";
import { IMPORTER_GET_CLIENTS_RESULT } from "../actions/action-import";
import { IMPORTER_GET_MANDATORY_MAPPING_RESULT } from "../actions/action-import";
import { IMPORTER_UNDECLINE_IMPORT_RESULT } from "../actions/action-import";
import { IMPORTER_CANCEL_IMPORT_RESULT } from "../actions/action-import";
import { IMPORTER_VALIDATE_RESULT } from "../actions/action-import";
import getEnvValue from "helpers/getEnvValue";
import { fromJS } from "immutable";
import { toastr } from "react-redux-toastr";


const middlewareImport = (store) => (next) => (action) => {
    const base = getEnvValue("KATE_CORE_URL");
    const mappingUrl = "/api/v1/mapping";
    let searchProperties;
    let postData = {};
    let url = "";

    const getResultState = (result) => {
        if (!result || result.length <= 0) return "failed";

        if (result.length > 1) return "partial-success";

        return "success";
    };

    switch (action.type) {
        case ReduxActions.IMPORTER_START().type:
            const { files } = action.payload;
            if (action.payload.type === "address" || action.payload.type === "valuation-figures") {
                store.dispatch(ReduxActions.IMPORTER_GET_PORTFOLIOS());
            }

            store.dispatch(ReduxActions.IMPORTER_START_RESULT({ files }));

            next(action);
            break;

        case ReduxActions.IMPORTER_GET_MAPPINGS().type:
            url = `${base}${mappingUrl}/get/${action.payload.type}`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_GET_MAPPINGS_RESULT({ mappings: fromJS([...result]) }));
                });
            next(action);
            break;

        case ReduxActions.IMPORTER_GET_MAPPING_OPTIONS().type:
            url =
                action.payload.type === "transactions"
                    ? `${base}/api/v1/property-definitions/config/${action.payload.type}?transactionType=${action.payload.transactionsType}`
                    : `${base}/api/v1/property-definitions/config/${action.payload.type}?importerKeys=true`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    const mappingOptions = result.propertyDefinitions;
                    store.dispatch(IMPORTER_GET_MAPPING_OPTIONS_RESULT({ mappingOptions: mappingOptions }));
                });
            next(action);
            break;

        case ReduxActions.IMPORTER_SET_MAPPING().type:
            const mappingId = action.payload.mappingId;
            store.dispatch(ReduxActions.IMPORTER_SET_MAPPING_RESULT({ mappingId: mappingId }));

            next(action);
            break;

        case ReduxActions.IMPORTER_VALIDATE().type:
            const getType = (type) => {
                if (type === "valuation-figures") return "valuation-figures";

                if (type === "transactions") return "transactions";

                return "objects";
            };

            const type = getType(action.payload.type);
            const importEntryData = action.payload.importEntryData;
            url = `${base}/api/v1/imports/${type}/validate`;
            window.API.getClient()
                .post(url, importEntryData)
                .then(({ data: { data: result } }) => {
                    store.dispatch(
                        IMPORTER_VALIDATE_RESULT({
                            importEntryId: action.payload.importEntryId,
                            result,
                        })
                    );
                    store.dispatch(IMPORTER_REMOVE_FROM_QUEUE_PROCESS({ importEntryId: action.payload.importEntryId }));
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_CREATE_MAPPING().type:
            url = `${base}/api/v1/mapping/new`;
            window.API.getClient()
                .post(url, {
                    name: action.payload.mappingName,
                    mappingType: action.payload.mappingType,
                    mapping: action.payload.mapping,
                })
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_CREATE_MAPPING_RESULT(fromJS(result)));
                    store.dispatch(MODAL_CLEAR_REF());
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.error) {
                        toastr.error(error.response.data.error[0]);
                    } else {
                        toastr.error("An unknown error occurred, please contact the helpdesk.");
                    }
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_GET_MANDATORY_MAPPING().type:
            url = `${base}/api/v1/imports/mandatory/${action.payload.type}`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_GET_MANDATORY_MAPPING_RESULT({ mandatoryMappings: fromJS({ ...result }) }));
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_UPDATE_MAPPING().type:
            url = `${base}/api/v1/mapping/update/${action.payload.mappingId}`;
            window.API.getClient()
                .post(url, {
                    name: action.payload.mappingName,
                    mapping: action.payload.mapping,
                })
                .then(({ data: { data: result } }) => {
                    store.dispatch(MODAL_CLEAR_REF());
                    store.dispatch(IMPORTER_UPDATE_MAPPING_RESULT({ mapping: result }));
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_DELETE_MAPPING().type:
            url = `${base}/api/v1/mapping/delete/${action.payload.mappingId}`;

            window.API.getClient()
                .delete(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(MODAL_CLEAR_REF());
                    store.dispatch(IMPORTER_CLEAR_MAPPINGS());
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_CHECK_ADDRESS_CONFIG().type:
            url = `${base}/api/v1/imports/${action.payload.type}/validate-address-config`;

            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_CHECK_ADDRESS_CONFIG_RESULT({ config: result }));
                });
            next(action);
            break;

        case ReduxActions.IMPORTER_CHECK_ADDRESS().type:
            const { importEntryId } = action.payload;

            url = `${base}/api/v1/imports/${action.payload.type}/validate-address`;

            window.API.getClient()
                .post(url, action.payload.searchProperties)
                .then(({ data: { data: result, error: errors } }) => {
                    store.dispatch(IMPORTER_REMOVE_FROM_QUEUE_PROCESS({ importEntryId }));
                    store.dispatch(
                        IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION({
                            id: importEntryId,
                            importData: { result, resultState: getResultState(result), errors },
                        })
                    );
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_START_IMPORT().type:
            url = `${base}/api/v1/imports/${action.payload.importId}/activate?adminMode=${action.payload.adminMode}`;
            window.API.getClient()
                .post(url)
                .then(({ data }) => {
                    action.payload.callback();
                })
                .catch(({ data }) => {});
            next(action);
            break;

        case ReduxActions.IMPORTER_CANCEL_IMPORT().type:
            url = `${base}/api/v1/imports/${action.payload.importId}/decline?adminMode=${action.payload.adminMode}`;
            window.API.getClient()
                .post(url)
                .then(({ data }) => {
                    store.dispatch(IMPORTER_CANCEL_IMPORT_RESULT({ result: "declined" }));
                })
                .catch(({ data }) => {});
            next(action);
            break;

        case ReduxActions.IMPORTER_UNDECLINE_IMPORT().type:
            url = `${base}/api/v1/imports/${action.payload.importId}/activate?adminMode=${action.payload.adminMode}`;
            postData = {
                adminMode: action.payload.adminMode,
                importId: action.payload.importId,
            };

            window.API.getClient()
                .post(url, postData)
                .then(({ data }) => {
                    store.dispatch(IMPORTER_UNDECLINE_IMPORT_RESULT({ result: "not_confirmed" }));
                })
                .catch(({ data }) => {});
            next(action);
            break;

        case ReduxActions.IMPORTER_SAVE_IMPORT().type:
            const formatFileName = (fileNameToFormat) => fileNameToFormat.replace("&", "-and-").replace("+", "-and-");

            const fileName = formatFileName(action.payload.fileName);

            url = `${base}/api/v1/imports/${action.payload.importType}?mappingId=${
                action.payload.mappingId
            }&fileName=${fileName}${!!action.payload.portfolio ? `&portfolioId=${action.payload.portfolio}` : ""}`;

            window.API.getClient()
                .post(url, action.payload.importData)
                .then(({ data: { data: result } }) => {
                    action.payload.callback(result.importId);
                })
                .catch((error) => {
                    if (error && error.response && error.response.data && error.response.data.error) {
                        toastr.error(error.response.data.error[0]);
                    } else {
                        toastr.error("An unknown error occurred, please contact the helpdesk.");
                    }
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_GET_SAVED_IMPORTS().type:
            url = `${base}/api/v1/imports`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_GET_SAVED_IMPORTS_RESULT({ savedImports: result }));
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_FIND_VALUATION_CONFIG().type:
            url = `${base}/api/v1/imports/valuation-figures/find-config`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_FIND_VALUATION_CONFIG_RESULT({ config: result }));
                });
            next(action);
            break;

        case ReduxActions.IMPORTER_FIND_VALUATION().type:
            url = `${base}/api/v1/imports/valuation-figures/find`;
            const searchProperties = [...action.payload.searchProperties];
            searchProperties.push({
                internalValue: "portfolioId",
                value: action.payload.portfolioId,
            });

            window.API.getClient()
                .post(url, searchProperties)
                .then(({ data: { data: result } }) => {
                    const valuationsFound = !!result.length;
                    store.dispatch(IMPORTER_REMOVE_FROM_QUEUE_PROCESS({ importEntryId: action.payload.importEntryId }));

                    const currentProperties = [
                        ...store
                            .getState()
                            .importState.getIn(["currentImport", "request", action.payload.importEntryId, "properties"])
                            .toJS(),
                    ];

                    store.dispatch(
                        IMPORTER_UPDATE_SAVED_IMPORT_VALIDATION({
                            id: action.payload.importEntryId,
                            importData: {
                                result: valuationsFound ? result : null,
                                resultState: getResultState(result),
                            },
                        })
                    );
                    store.dispatch(
                        IMPORTER_VALIDATE_RESULT({
                            importEntryId: action.payload.importEntryId,
                            result: currentProperties,
                        })
                    );
                })
                .catch(() =>
                    store.dispatch(IMPORTER_REMOVE_FROM_QUEUE_PROCESS({ importEntryId: action.payload.importEntryId }))
                );
            next(action);
            break;

        case ReduxActions.IMPORTER_GET_SAVED_IMPORT().type:
            url = `${base}/api/v1/imports/${action.payload.id}?withRequest=true`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    const resultEntry = result[0];
                    let mappingType = result[0].mappingType;
                    let transactionType;
                    if (!["address", "valuation-figures", "transactions"].includes(mappingType)) {
                        transactionType = mappingType;
                        mappingType = "transactions";
                    }

                    store.dispatch(ReduxActions.IMPORTER_SET_TYPE(mappingType, transactionType || null));
                    store.dispatch(IMPORTER_GET_SAVED_IMPORT_RESULT({ savedImport: resultEntry }));
                });

            next(action);
            break;

        case ReduxActions.IMPORTER_GET_PORTFOLIOS().type:
            window.API.getClient()
                .get(`${getEnvValue("KATE_CORE_URL")}/api/v1/portfolios`, { params: { isRecentUpdated: true } })
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_GET_PORTFOLIOS_RESULT({ portfolios: result }));
                })
                .catch();
            break;

        case ReduxActions.IMPORTER_GET_CLIENTS().type:
            url = `${base}/api/v1/clients`;
            window.API.getClient()
                .get(url)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_GET_CLIENTS_RESULT({ clients: result }));
                });
            break;

        case ReduxActions.IMPORTER_UPDATE_REQUEST().type:
            url = `${base}/api/v1/imports/${action.payload.id}?mappingId=${action.payload.mappingId}`;
            window.API.getClient()
                .post(url, action.payload.importData)
                .then(({ data: { data: result } }) => {
                    store.dispatch(IMPORTER_UPDATE_CHANGES());
                });
            break;

        default:
            next(action);
            break;
    }
};

export default middlewareImport;

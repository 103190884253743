import produce, { original } from "immer";
import { CREATE_CELLS, RESET_TO_INITIAL_STATE, SET_AUTO_FOCUSED_CELL } from "../constants";

const initialState = {
    data: [],
    autoFocusedCell: undefined,
};

export default produce((draft = initialState, action) => {
    const { type, id, cells } = action;
    switch (type) {
        case CREATE_CELLS: {
            const newCells = [...original(draft.data), ...cells];
            draft.data = newCells;
            break;
        }

        case RESET_TO_INITIAL_STATE: {
            return initialState;
        }

        case SET_AUTO_FOCUSED_CELL: {
            draft.autoFocusedCell = id;
            break;
        }

        default:
            return draft;
    }
});
